import { render, staticRenderFns } from "./fancyHtml.vue?vue&type=template&id=bd2fe8e4"
import script from "./fancyHtml.vue?vue&type=script&lang=js"
export * from "./fancyHtml.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports