import Vue from "vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter);

let routes =[
    {
        path:'/test/test1_upload',   //测试页
        component: () => import('../pages/test/test1_upload'),
    },{
        path:'/room/all',   //room广场
        component: () => import('../pages/room/all'),
        meta:{
            title:"room广场",
            showHeader: true,
        }
    },{
        path:'/room/work',   //room审批流
        component: () => import('../pages/room/index'),
        meta:{
            title:"room审批流",
            showHeader: true,
            auth:['login','realName']
        }
    },{
        path:'/room/index',   //room最新动态
        component: () => import('../pages/room/navigation.vue'),
        meta:{
            title:"room最新消息",
            showHeader: true,
        }
    },{
        path:'/room/notice',   //room通知消息
        component: () => import('../pages/room/notice'),
        meta:{
            title:"room通知消息",
            showHeader: true,
        }
    },{
        path:'/room/info/:id',   //room详情
        component: () => import('../pages/room/info'),
        meta:{
            title:"room详情页",
            showHeader: true,
        }
    },{
        path:'/room/manage',   //room文件夹
        component: () => import('../pages/room/manage'),
        meta:{
            title:"room文件夹",
            showHeader: true,
            auth:['login']
        }
    },{
        path:'/room/myroom',   //我的room
        component: () => import('../pages/room/myroom'),
        meta:{
            title:"我的room",
            showHeader: true,
            auth:['login']
        }
    },{
        path:'/room/member/:id',   //room成员管理
        component: () => import('../pages/room/member'),
        meta:{
            title:"管理room成员",
            showHeader: true,
            auth:['login']
        }
    },{
        path:'/room/menu/:id',   //room菜单管理
        component: () => import('../pages/room/menu'),
        meta:{
            title:"管理room菜单",
            showHeader: true,
            auth:['login']
        }
    },
        {
            path:'/room/article',   //room文章
            name: 'Acticle',
            component: () => import('../pages/room/article'),
            meta:{
                title:"发布room文章",
            }
        },
    {
        path:'/room/kwArticle',   //room文章
        name: 'kwArticle',
        component: () => import('../pages/room/knowledge/KwArticle'),
        meta:{
            title:"发布room文章",
        }
    },{
        path:'/room/task',   //room推荐页
        component: () => import('../pages/room/task'),
        meta:{
            title:"我的任务序列",
            showHeader: true,
            auth:['login','realName']
        }
    },{
        path:'/room/event',   //room事件页
        name: 'Event',
        component: () => import('../pages/room/event'),
        meta:{
            title:"room事件",
            showHeader: true,
            auth:['login']
        }
    },{
        path:'/room/add',   // 新建room
        component: () => import('../pages/room/add'),
        meta:{
            title:"新建room",
            showHeader: true,
            auth:['login','realName']
        }
    },{
        path:'/room/edit/:id',   //room推荐页
        component: () => import('../pages/room/edit'),
        meta:{
            title:"编辑room",
            showHeader: true,
            auth:['login','realName']
        }
    },{
        path:'/expert/index',   //room推荐页
        component: () => import('../pages/expert/index'),
        meta:{
            title:"专家连线",
            showHeader: true
        }
    },{
        path:'/expert/info/:uuid',   //room推荐页
        component: () => import('../pages/expert/info'),
        meta:{
            title:"专家主页",
            showHeader: true,
            auth:['login']
        }
    },{
        path:'/expert/order/:orderNo',   //room推荐页
        component: () => import('../pages/expert/order'),
        meta:{
            title:"订单详情",
            showHeader: true,
        }
    },{
        path:'/expert/list',   //room推荐页
        component: () => import('../pages/expert/list'),
        meta:{
            title:"我的专家预约单",
            showHeader: true,
            auth:['login','realName']
        }
    },{
        path:'/test/cropperExample',   //测试页
        component: () => import('../pages/test/cropperExample'),
        meta:{
            title:"裁剪方法使用例子"
        }
    },
        {
            path:'/test/videoCallExample',   //测试页
            component: () => import('../pages/test/videoCallExample'),
            meta:{
                title:"视频通话方法使用例子"
            }
        },
        {
            path:'/test/registered',   //微信登录扫码
            component: () => import('../pages/test/wxcode/registered'),
            props: route => ({
                code: route.query.code,
            }),
            meta:{
                title:"注册页"
            }
        },{
        path:'/test/wx_qrcode',   //微信登录回调
        component: () => import('../pages/test/wxcode/wx_qrcode'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title:"注册页"
        }
    },{
        path:'/test/wxpay/index',   //微信支付
        component: () => import('../pages/test/wxpay/index'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title:"微信支付"
        }
    },{
        path:'/test/alipay/index',   //支付宝支付
        component: () => import('../pages/test/alipay/index'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title:"支付宝支付"
        }
    },
        {
            path:'/test/unionPay/bind_bank_car',   //绑定银行卡
            component: () => import('../pages/test/unionPay/bind_bank_car'),
            meta:{
                title:"绑定银行卡"
            }
        },
        {
            path:'/test/unionPay/adapay_pay',   //银行卡支付
            component: () => import('../pages/test/unionPay/adapay_pay'),
            meta:{
                title:"银行卡支付"
            }
        },
        {
            path:'/test/unionPay/settleaccount_detail',   //结算明细
            component: () => import('../pages/test/unionPay/settleaccount_detail'),
            meta:{
                title:"结算明细"
            }
        },
        {
            path:'/test/unionPay/adapay_div_members',   //转账
            component: () => import('../pages/test/unionPay/adapay_div_members'),
            meta:{
                title:"转账"
            }
        },
        {
            path:'/test/unionPay/bank_cash',   //个人支付、转账、分账、提现
            component: () => import('../pages/test/unionPay/bank_cash'),
            meta:{
                title:"充值、转账、分账、提现"
            }
        },
        {
            path:'/test/unionPay/corpMember/corp_auth',   //企业开户认证
            name:"corp_auth",
            component: () => import('../pages/test/unionPay/corpMember/corp_auth'),
            meta:{
                title:"企业开户认证"
            }
        },
        {
            path:'/test/unionPay/corpMember/corp_member_cash',   //企业支付、转账、分账、提现
            name:'corp_member_cash',
            component: () => import('../pages/test/unionPay/corpMember/corp_member_cash'),
            meta:{
                title:"充值、转账、分账、提现"
            }
        },
        {
            path:'/test/unionPay/corpMember/corp_e_banking',   //企业支付网银跳转
            name:"corp_e_banking",
            component: () => import('../pages/test/unionPay/corpMember/corp_e_banking'),
            meta:{
                title:"企业支付网银跳转"
            }
        },
        {
            path:'/test/unionPay/corpMember/corp_div_members',   //企业分账结算
            name:"corp_div_members",
            component: () => import('../pages/test/unionPay/corpMember/corp_div_members'),
            meta:{
                title:"企业分账结算"
            }
        },{
        path:'/templates/conference/:id',   // 通用模板-会议纪要
        component: () => import('../pages/room/templates/conference'),
        meta:{
            title:"会议纪要",
            showHeader: true,
        }
    },
    {
        path:'/templates/contactForm/:id',   // 通用模板-联系单
        component: () => import('../pages/room/templates/contactForm'),
        meta:{
            title:"甲方联系单",
            showHeader: true,
        }
    },
    {
        path:'/templates/businessTripApplication/:id',   // 通用模板-出差申请单
        component: () => import('../pages/room/templates/businessTripApplication'),
        meta:{
            title:"出差申请单",
            showHeader: true,
        }
    },
    {
        path:'/templates/contract/:id',   // 通用模板-合同登记表
        component: () => import('../pages/room/templates/contract'),
        meta:{
            title:"合同登记表",
            showHeader: true,
        }
    },
    {
        path:'/templates/askForLeave/:id',   // 通用模板-请假申请表
        component: () => import('../pages/room/templates/askForLeave'),
        meta:{
            title:"请假申请表",
            showHeader: true,
        }
    },
    {
        path:'/templates/bidRegistration/:id',   // 通用模板-投标登记表
        component: () => import('../pages/room/templates/bidRegistration'),
        meta:{
            title:"投标登记表",
            showHeader: true,
        }
    },
    {
        path:'/templates/purchaseOrder/:id',   // 通用模板-采购单
        component: () => import('../pages/room/templates/purchaseOrder'),
        meta:{
            title:"采购单",
            showHeader: true,
        }
    },
    {
        path:'/templates/recruit/:id',   // 通用模板-招聘信息
        component: () => import('../pages/room/templates/recruit'),
        meta:{
            title:"招聘信息",
            showHeader: true,
        }
    },
    {
        path:'/templates/truckingOrderAdmin/:id',   // 通用模板-派车单
        component: () => import('../pages/room/templates/truckingOrderAdmin.vue'),
        meta:{
            title:"派车单",
            showHeader: true,
        }
    },
    {
        path:'/templates/projectFiling/:id',   // 通用模板-项目备案
        component: () => import('../pages/room/templates/projectFiling'),
        meta:{
            title:"项目备案",
            showHeader: true,
        }
    },
    {
        path:'/templates/constructionLog/:id',   // 通用模板-施工日志
        component: () => import('../pages/room/templates/constructionLog'),
        meta:{
            title:"施工日志",
            showHeader: true,
        }
    },
        {
            path:'/form/diy',  //创建模板
            name: 'Diy',
            component: () => import('../pages/form/diy'),
            meta:{
                title:"创建模板",
                auth:['login']
            }
        },
        {
            path:'/form/view',  //模板
            name: 'View',
            component: () => import('../pages/form/view'),
            meta:{
                title:"模板",
            }
        },
        {
            path:'/form/preView',  //模板
            name: 'View',
            component: () => import('../pages/form/preView'),
            meta:{
                title:"自动填充数据预览"
            }
        },
        {
            path:'/room/imageInfo/:id',   //room图片详情
            component: () => import('../pages/room/imageInfo'),
            meta:{
                title:"room图片详情"
            }
        },
        {
            path:'/room/articleInfo/:id',   //room文章详情
            component: () => import('../pages/room/articleInfo'),
            meta:{
                title:"room文章详情"
            }
        },
        {
            path:'/room/editArticle/:id',   //room文章编辑
            component: () => import('../pages/room/editArticle'),
            meta:{
                title:"room编辑文章"
            }
        },
        {
            path:'/room/serviceInfo/:id',   //任务序列详情
            component: () => import('../pages/room/serviceInfo'),
            meta:{
                title:"任务委托详情",
                showHeader: true,
                auth:['login','realName']
            }
        },
        {
            path:'/room/template',  // 管理自定义模板
            name: 'Template',
            component: () => import('../pages/room/template'),
            meta:{
                title:"管理自定义模板",
                showHeader: true,
                auth:['login']
            }
        },
        {
            path: '/expert/team/:id',  // 专家团队详情
            name: 'Team',
            component: () => import('../pages/expert/team'),
            meta: {
                title: "专家团队详情",
                showHeader: true,
            }
        },{
        path:'/cityDemo',  //城市选择demo
        component: () => import('../pages/test/cityDemo'),
        meta:{
            title:"城市选择Demo"
        }
    },
        {
            path:'/wxOauth',   //微信登录扫码
            component: () => import('../pages/index/wxOauth'),
            props: route => ({
                code: route.query.code,
            }),
            meta:{
                title:"微信扫码登陆"
            }
        },
        {
            path: '/wxBind',   //微信登录扫码
            component: () => import('../pages/index/wxBind'),
            props: route => ({
                code: route.query.code,
            }),
            meta:{
                title: "微信扫码登陆"
            }
        },{
        path:'/qqOauth',   //qq登录扫码
        component: () => import('../pages/index/qqOauth'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title:"QQ扫码登陆"
        }
    },
        {
            path: '/qqBind',   //qq登录扫码
            component: () => import('../pages/index/qqBind'),
            props: route => ({
                code: route.query.code,
            }),
            meta:{
                title: "QQ扫码登陆"
            }
        },
        {
            path: '/room/docDetail/:id',  // 付费文档详情
            name: 'docDetail',
            component: () => import('../pages/room/docDetail'),
            meta: {
                title: "付费文档详情",
                showHeader: true,
                auth:['login']
            }
        },
        {
            path:'/room/productInfo/:id',   // 采购订单详情
            component: () => import('../pages/room/productInfo'),
            meta:{
                title:"采购订单详情",
                showHeader: true,
                auth:['login']
            }
        },
        {
            path:'/user/money/toPay/:id',  //企业支付
            component: () => import('../pages/expert/components/ToPay'),
            meta:{
                title:"企业支付"
            }
        },
        {
            path:'/search/index',   // 采购订单详情
            component: () => import('../pages/search/index'),
            meta:{
                title:"搜索页",
                showHeader: false
            }
        },
        {
            path:'/knowledge/KwArticle/:id',   //知识仓库文章
            component: () => import('../pages/room/knowledge/KwArticle'),
            name:"addKw",
            meta:{
                title:"上传资料",
            }
        },
        {
            path:'/expert/become/',   //成为专家
            component: () => import('../pages/expert/become'),
            meta:{
                title:"成为专家",
                auth:['login','realName','major']
            }
        },
        {
            path:'/expert/space/:uuid',   //专家空间
            component: () => import('../pages/expert/space'),
            meta:{
                title:"专家空间",
            }
        },
        {
            path:'/newAdaPay',   //新支付页面
            name: 'newAdaPay',
            component: () => import('../components/common/Pay/newAdaPay.vue'),
            meta:{
                title:"新支付页面",
            },
        },
    ]
export default routes
