<template>
    <div style="height: 470px;width: 100%;">
        <div style="height: 490px;width: 100%;background-color: #2B3154;">
            <div style="width: 1500px;margin: 0 auto;height: 470px;padding-top: 20px;">
                <div style="width: 100%;display: flex;justify-content: space-around;">
                    <div style="height: auto;width: 30%;padding: 20px 0 0 2.5%;">
                        <div style="color: white;height: 21px;font-size: 18px;">百工宝</div>
                        <div style="font-size: 14px;color: white;height: 255px;margin-top: 24px;">
                            百工宝由北京无界智谷数字科技有限公司开发，是一个多方项目协作和管理平台，实现跨端和实时的项目管理，是团队管理项目的良好工具和平台。借助于权限管理和控制，可以同时满足项目外部资源获取和内部安全管理要求；借助于独有的模板文档编辑和事件管理，几乎可以满足所有类型和机构的项目管理、内务管理诉求。
                            百工宝是一个业务数字化平台，适用于规划、建筑、市政、景观、装饰等工程服务领域，把过程管理、档案管理、项目展示、外部资源获取等不同需求进行紧密耦合，实现业务的全线上化，成为专业人员的得力助手。在业务数字化的同时，把项目管理所需的知识管理、专家咨询、需求发布、产品服务管理一并有机融合，赋能个体，赋能企业，打造平台型公司的数据底座。产品适用领域从工程建设扩展到所有专业性领域。使用百工宝，传递你的工作价值。协助专业人士运营线上业务，打造您的业务生态圈，开辟线上业务增长领域，提高转化率。
                            百工宝奉行约定俗成的规则体系，尊重行业规则和知识产权、人的创造性劳动，保护劳动者的价值。
                        </div>
                    </div>
                    <div style="width: 33%;padding: 20px 0 0 5%;">
                        <div style="color: #FFFFFF;height: 21px;font-size: 18px;">热门服务</div>
                        <div style="height: 120px;width: 100%;font-size: x-small;padding-right: 1.5%;margin-top: 24px;color: white">
                            <div style="height: 17px;width: 100%;">
                                <div class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;">
                                    品牌策划
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    运营策划
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    旅游策划
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    国土空间
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    旅游规划
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    村镇规划
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    城市规划
                                </div>
                            </div>
                            <div style="height: 17px;width: 100%;margin: 17px 0">
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;">
                                    城市设计
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    生态修复
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    园区规划
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    专项规划
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    建筑设计
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    景观园林
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    市政工程
                                </div>
                            </div>
                            <div style="height: 17px;width: 100%;margin: 17px 0">
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;">
                                    装饰设计
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    专业设计
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    工程设计
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    平面设计
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    工程造价
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    工程承包
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    工程招标
                                </div>
                            </div>
                            <div style="height: 17px;width: 100%;margin: 17px 0">
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;">
                                    景区运营
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    园区运营
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    农业运营
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    影视动漫
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    文化艺术
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    会议展览
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    管理咨询
                                </div>
                            </div>
                            <div style="height: 17px;width: 100%;margin: 17px 0">
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;">
                                    城市管理
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    教育培训
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    文体科技
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    软件工程
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    绿色科技
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    宏观管理
                                </div>
                                <div style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                    新闻出版
                                </div>
                            </div>
                        </div>
                        <div style="color: #FFFFFF;height: 21px;font-size: 18px;margin-top: 75px;">关于我们</div>
                        <div style="height: 17px;width: 100%;margin-top: 30px;color: white">
                            <div @click="b('/AboutUs/' + 1)" class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;">
                                公司资质
                            </div>
                            <div @click="b('/AboutUs/' + 2)" class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                版权声明
                            </div>
                            <div @click="b('/AboutUs/' + 3)" class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                支付方式
                            </div>
                            <div @click="b('/index/help/' + 0)" class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                帮助中心
                            </div>
                            <div @click="b('/AboutUs/' + 4)" class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                隐私政策
                            </div>
                            <div @click="b('/index/ruleCenter/' + 0)" class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                规则中心
                            </div>
                            <div @click="b('/AboutUs/' + 5)" class="cursor op sc hand" style="height: 17px;width: 14%;float: left;font-size: 14px;margin-left: 0.2%">
                                联系我们
                            </div>
                        </div>
                    </div>
                    <div style="width: 27%;padding: 24px 0 0 0;">
                        <div style="margin: 0 15%">
                            <div style="width: 120px;height: auto;float: left;">
                                <div style="background: #FFFFFF;width: 100px;height: 100px;margin: 0 auto">
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/ewm/az.jpg" style="width: 100px;height: 100px;">
                                </div>
                                <div style="width: 112px;font-size: 14px;color: white;padding-top: 10px;margin: 0 auto">百工宝微信公众号</div>
                            </div>
                            <div style="width: 120px;height: auto;float: right;">
                                <div style="background: #FFFFFF;width: 100px;height: 100px;margin: 0 auto;">
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/ewm/ios.jpg" style="width: 100px;height: 100px;">
                                </div>
                                <div style="font-size: 14px;color: white;padding-top: 10px;width: 74px;margin: 0 auto;">百工宝APP</div>
                            </div>
                        </div>
                        <div style="margin: 0 15%;">
                            <div style="width: 120px;height: auto;float: left;padding-top: 47px;">
                                <div style="background: #FFFFFF;width: 100px;height: 100px;margin: 0 auto">
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/ewm/xcx.jpg" style="width: 100px;height: 100px;">
                                </div>
                                <div style="width: 112px;font-size: 14px;color: white;padding-top: 10px;margin: 0 auto">百工宝微信小程序</div>
                            </div>
                            <div style="width: 120px;height: auto;float: right;padding-top: 47px;">
                                <div style="background: #FFFFFF;width: 100px;height: 100px;margin: 0 auto;">
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/ewm/xcx.jpg" style="width: 100px;height: 100px;">
                                </div>
                                <div style="width: 112px;color: white;padding-top: 10px;margin: 0 auto;font-size: 14px;">百工宝微信小程序</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;border-bottom: solid 1px gray;box-sizing: border-box;margin-top: 20px;"></div>
                <div  style="height: 17px;width: 100%;color: white;padding-top: 10px;font-size: 15px;font-weight: 600">
                    <div @click="b('https://www.mnr.gov.cn/')" class="cursor op sc hand" style="width: 6%;float: left;margin-left: 3%">
                        自然资源部
                    </div>
                    <div @click="b('https://www.mohurd.gov.cn/')" class="cursor op sc hand" style="width: 8%;float: left;margin-left: 0.2%">
                        住房城乡建设部
                    </div>
                    <div @click="b('https://www.gov.cn/')" class="cursor op sc hand" style="width: 6%;float: left;margin-left: 0.2%">
                        中国政府网
                    </div>
                    <div @click="b('https://www.ndrc.gov.cn/')" class="cursor op sc hand" style="width: 6%;float: left;margin-left: 0.2%">
                        国家发改委
                    </div>
                    <div @click="b('https://www.mee.gov.cn/')" class="cursor op sc hand" style="width: 6%;float: left;margin-left: 0.2%">
                        生态环境部
                    </div>
                    <div @click="b('https://www.forestry.gov.cn/')" class="cursor op sc hand" style="width: 7%;float: left;margin-left: 0.2%">
                        林业和草原局
                    </div>
                    <div @click="b('https://www.mct.gov.cn/')" class="cursor op sc hand" style="width: 7%;float: left;margin-left: 0.2%">
                        文化和旅游部
                    </div>
                    <div @click="b('https://www.forestry.gov.cn/')" class="cursor op sc hand" style="width: 7%;float: left;margin-left: 0.2%">
                        林业和草原局
                    </div>
                    <div @click="b('https://www.most.gov.cn/index.html')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 0.2%">
                        科技部
                    </div>
                    <div @click="b('https://www.planning.org.cn/')" class="cursor op sc hand" style="width: 9%;float: left;margin-left: 0.2%">
                        中国城市规划协会
                    </div>
                    <div @click="b('http://www.chsla.org.cn/home/')" class="cursor op sc hand" style="width: 9%;float: left;margin-left: 0.2%">
                        中国风景园林学会
                    </div>
                    <div @click="b('http://www.moa.gov.cn/')" class="cursor op sc hand" style="width: 6%;float: left;margin-left: 0.2%">
                        农业农村部
                    </div>
                    <div @click="b('https://www.mot.gov.cn/')" class="cursor op sc hand" style="width: 6%;float: left;margin-left: 0.2%">
                        交通运输部
                    </div>
                    <div @click="b('https://www.miit.gov.cn/')" class="cursor op sc hand" style="width: 7%;float: left;margin-left: 0.2%">
                        工业和信息化部
                    </div>
                    <!-- 第二行  -->
                    <div style="line-height: 30px;">
                        <div @click="b('https://www.mca.gov.cn/')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 3%;">
                            民政部
                        </div>
                        <div @click="b('http://www.mwr.gov.cn/')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 0.2%;">
                            水利部
                        </div>
                        <div @click="b('http://www.mofcom.gov.cn/')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 0.2%;">
                            商务部
                        </div>
                        <div @click="b('http://www.moe.gov.cn/')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 0.2%;">
                            教育部
                        </div>
                        <div @click="b('http://www.nhc.gov.cn/')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 0.2%;">
                            卫健委
                        </div>
                        <div @click="b('https://www.mof.gov.cn/index.htm')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 0.2%;">
                            财政部
                        </div>
                        <div @click="b('https://www.mem.gov.cn/')" class="cursor op sc hand" style="width: 6%;float: left;margin-left: 0.2%;">
                            应急管理部
                        </div>
                        <div @click="b('http://www.pbc.gov.cn/')" class="cursor op sc hand" style="width: 5%;float: left;margin-left: 0.2%;">
                            人民银行
                        </div>
                        <div @click="b('https://www.stats.gov.cn/')" class="cursor op sc hand" style="width: 4%;float: left;margin-left: 0.2%;">
                            统计局
                        </div>
                        <div @click="b('http://www.clss.org.cn/')" class="cursor op sc hand" style="width: 5%;float: left;margin-left: 0.2%;">
                            土地学会
                        </div>
                        <div @click="b('https://www.chinaasc.org.cn/')" class="cursor op sc hand" style="width: 5%;float: left;margin-left: 0.2%;">
                            建筑学会
                        </div>
                    </div>

                </div>

                <div style="color: white;font-size: 14px;clear: left;margin-top: 20px;margin-left: 3%;cursor: pointer">增值电信业务经营许可证：京B2-20202256</div>
                <div style="color: white;font-size: 14px;clear: left;margin-top: 5px;margin-left: 3%;cursor: pointer">© 2020~2028 北京无界智谷数字科技有限公司 | 京ICP备19049904号 | 京ICP备19049904号-1</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"Bottom",
    components: {

    },
    data () {
        return {
            interlinkagelist:[
                {
                    title:'自然资源部',
                    path:'https://www.mnr.gov.cn/'

                },
                {
                    title:'住房城乡建设部',
                    path:'https://www.mohurd.gov.cn/'

                },
                {
                    title:'中国政府网',
                    path:'https://www.mnr.gov.cn/'

                },
                {
                    title:'国家发改委',
                    path:'https://www.ndrc.gov.cn/'

                },
                {
                    title:'生态环境部',
                    path:'https://www.mee.gov.cn/'

                },
                {
                    title:'林业和草原局',
                    path:'https://www.forestry.gov.cn/'

                },
                {
                    title:'文化和旅游部',
                    path:'https://www.mct.gov.cn/'

                },
                {
                    title:'林业和草原局',
                    path:'https://www.forestry.gov.cn/'

                },
                {
                    title:'科技部',
                    path:'https://www.most.gov.cn/index.html'

                },
                {
                    title:'中国城市规划协会',
                    path:'https://www.planning.org.cn/'

                },
                {
                    title:'中国风景园林学会',
                    path:'http://www.chsla.org.cn/home/'

                },

            ]
        }
    },
    props:{

    },
    created:function(){

    },
    mounted: function () {

    },
    methods:{

    }
}
</script>
<style scoped>
.cursor {
    cursor: pointer;
}
</style>
