<template>
  <div class="to-top">
    <div v-show="topLong !== 0" @click="toTop" @mouseover="showTop = true" @mouseleave="showTop = false" class="hand op" style="position: fixed; bottom: 10%; right: 8%;width: 40px; height: 42px; text-align: center;">
      <div v-if="showTop === false" style="background-color: #D1D1D1">
        <img style="width: 26px; margin-top: 6px" src="@/assets/imgs/index/topIcon.png">
      </div>
      <div v-else style="background-color: #031F88; color: #FFFFFF">
          <div>
            返回顶部
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToTop",
  components: {},
  data() {
    return {
      topLong: 0,
      showTop: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  props: {
  },
  created: function () {

  },
  mounted: function () {
    let that = this;
    window.addEventListener('scroll', function () {
      that.topLong =  window.pageYOffset || document.documentElement.scrollTop;
    });
  },
  methods: {
    // 回到顶部
    toTop() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    }
  }
}
</script>

<style scoped>

</style>
