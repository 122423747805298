import Vue from "vue";
import VueRouter from 'vue-router'
import store from "@/store";
import lylRouter from "@/router/lylRouter";
import zwbRouter from "@/router/zwbRouter";
import cjfRouter from "@/router/cjfRouter";

Vue.use(VueRouter);   //在模块化工程中使用它（因为是一个插件，所以可以通过Vue.use()来安装路由功能）

const router = new VueRouter({
    mode:"history",
    routes:[
        {
            path:'/',   //默认首页
            name:"index",
            component: () => import('../pages/index/index'),
            meta:{
                showHeader:true,
                title:"首页",
                content:"搭上数字经济的末班车，开通您的服务专线要赶早。产业数字化的需要的所有工具，百工宝已为您备齐信息发布、认证系统、服务定制(业务展示与在线生成订单)、项目管理、订单管理(订单驱动)、合同管理(安全)、在线支付(实现资金托管，公平交易)、专家系统、知识管理" +
                    "国内最全，最先进的工程服务在线交易平台快(找服务，生成订单)、好(专业，优质)、省(省钱，省心)、智(人工智能时代)",//这个里面写当前页面的内容描述，用于搜索引擎优化
                keywords:"这是一些关键词的描述",//在这个里面写当前页面的关键词使用，分割   现在已经不在这个的重要度已经降低了，需要页面使用ssr才能更好的起到作用
                cache:true,//设置是否进行缓存，如果设置为true，则会缓存页面，如果设置为false，则不会缓存页面，内容经常更新的则不适合做缓存
            },
        },{
                    path:'/wxOauth',   //微信登录扫码
                    component: () => import('../pages/index/wxOauth'),
                    meta:{
                title:"微信扫码登陆"
                    }
                },{
                    path:'/qqOauth',   //qq登录扫码
                    component: () => import('../pages/index/qqOauth'),
                    meta:{
                        title:"QQ扫码登陆",
                    }
                },{
                    path: '/qqBind',   //qq扫码绑定
                    component: () => import('../pages/index/qqBind'),
                    meta:{
                        title: "QQ扫码登陆",
                        auth:['login']
            }
        },
        {
                    path:'/adaPay',   //新支付页面
                    name: 'adaPay',
                    component: () => import('../components/common/Pay/adaPay.vue'),
                    meta:{
                        title:"百工宝-收银台",
                        auth:['login'],
                    },
        },
        {
            path:'/index/reg',   //注册
            name: 'reg',
            component: () => import('../components/common/reg.vue'),
            meta:{
                title:"注册",
            },
        },{
            path:'/more/ask',  // 我的红包问答
            component: () => import('../components/common/moreNeedAndAsk/ask'),
            
            meta:{
                title:"我的红包问答",
            }
        },
        {
            path:'/more/need',  // 我的需求信息
            component: () => import('../components/common/moreNeedAndAsk/need'),
            meta:{
                title:"我的需求信息"
            }
        },{
            path:'/message/index',  // 通知消息
            component: () => import('../components/common/message/index'),
            
            meta:{
                title:"通知消息",
            }
        }, {
            path:'/index/help/:id',  //帮助中心
            name: 'help',
            component: () => import('../components/common/help'),
            meta:{
                title:"帮助中心",
            }
        },
        {
            path:'/index/ruleCenter/:id',  //规则中心
            name: 'help',
            component: () => import('../components/common/ruleCenter.vue'),
            meta:{
                title:"规则中心",
            },
        },
        {
            path: '/test/cropperExample',   //裁剪组件使用用例
            component: () => import('../pages/test/cropperExample.vue'),
            meta:{
                title: "裁剪",
                test:true//仅本地环境可用
            }
        },{
            path: '/test/calendarExample',   //日历组件使用用例
            component: () => import('../pages/test/CalendarExample.vue'),
            meta:{
                title: "日历",
                test:true//仅本地环境可用
            }
        },
        {
            path:'/blog/:uuid',  //创建模板
            name: 'Blog',
            component: () => import('../pages/blog/index'),
            meta:{
                title:"环境页"
            }
        },
        {
            path:'/form/diy',  //创建模板
            name: 'Diy',
            component: () => import('../pages/form/diy'),
            meta:{
                title:"创建模板"
            }
        },
        {
            path:'/form/view',  //模板
            name: 'View',
            component: () => import('../pages/form/view'),
            meta:{
                title:"模板"
            }
        },
        {
            path:'/cityDemo',  //城市选择demo
            component: () => import('../pages/test/cityDemo'),
            meta:{
                title:"城市选择Demo"
            }
        },
        {
            path: '/wxBind',   //微信登录绑定
            component: () => import('../pages/index/wxBind'),
            meta:{
                title: "微信扫码登陆"
            }
        },
        
        {
            path: '/test/index',   //使用用例大全
            component: () => import('../pages/test/index.vue'),
            meta:{
                title: "使用用例",
                test:true//仅本地环境可用
            }
        },
        
        
        {
            path: '/test/videoCallExample',   //视频通话使用用例
            component: () => import('../pages/test/videoCallExample.vue'),
            meta:{
                title: "视频通话",
                test:true//仅本地环境可用
            }
        },
        {
            path: '/test/liveExample',   //直播使用用例
            component: () => import('../pages/test/liveExample.vue'),
            meta:{
                title: "直播",
                test:true//仅本地环境可用
            }
        },
        {
            path: '/test/GuideExample',   //导游使用用例
            component: () => import('../pages/test/GuideExample.vue'),
            meta:{
                title: "导游",
                test:true//仅本地环境可用
            }
        },
        {
            path:'/AboutUs/:type',  //关于我们
            component: r => require.ensure([], () => r(require('../components/common/AboutUs.vue'))),
            meta:{
                title:"",
                showHeader:true
            }
        },
       
        ...lylRouter,
        ...zwbRouter,
        ...cjfRouter,
    ]


})

/**
 * 导航守卫的使用
 * 可以利用beforeEach来完成标题的修改
 * 1.首先，我们可以在钩子当中定义一些标题，可以利用mate来定义
 * 2.其次，利用导航守卫修改我们的标题
 * 导航钩子的三个参数解析：
 * 1.to：即将要进入的目标的路由对象
 * 2.from：当前导航即要离开的路由对象
 * 3.next：调用该方法后，才能进入下一个钩子
 * 如果是后置钩子，也就是afterEach，不需要主动调用next(）函数。
 * 下面我们使用的导航守卫，被称之为全局守卫
 * */

function loginAuth(to) {
    if (!store.state.is_login){
        store.commit('setShowLoginPopup', true);
        store.commit('setPendingRoute', to.fullPath); // 保存待导航的路径
        return false;
    }else {
        return true;
    }
}
function realNameAuth(to) {
    if (loginAuth(to)){
        if (store.state.user.realNameStatus!==2){
            store.commit('setShowAuthPopup', 'realName');
            return false;
        }else {
            return true;
        }
    }else {
        return false;
    }
}
function majorAuth(to,type) {
    if(store.state.user.userType!==1){
        alert("只有个人专业认证用户才能访问该页面");
        return false;
    }
    if (realNameAuth(to)){
        if (type==="major"){
            if (store.state.user.authenticationStatus!==2){
                store.commit('setShowAuthPopup', 'major');
                return false;
            }else {
                return true;
            }
        }else if (type==="edu"){
            if (store.state.user.schoolAuthStatus!==2){
                store.commit('setShowAuthPopup', 'edu');
                return false;
            }else {
                return true;
            }
        }else if (type==="career"){
            if (store.state.user.majorAuthStatus!==2){
                store.commit('setShowAuthPopup', 'career');
                return false;
            }else {
                return true;
            }
            
        }
        
    }else {
        return false;
    }
}

function expertAuth(to) {
    if (majorAuth(to,'edu') &&  majorAuth(to,'career')){
        if (store.state.user.expertAuthStatus!==2){
            store.commit('setShowAuthPopup', 'expert');
            return false;
        }else {
            return true;
        }
    }else {
        return false;
    }
}
router.beforeEach((to,from,next) =>{
    window.document.title = to.meta.title ? to.meta.title : '百工宝';
    const requiresAuth = to.matched.some(record => record.meta.auth);
    
    if (requiresAuth) {
        // 初始化为 true，逐级检查权限
        for (const record of to.matched) {
            console.log(record);
            if (record.meta.auth) {
                if (record.meta.auth.includes("login")) {
                    if (!loginAuth(to)){
                        return ;// 取消导航
                    }
                }
                if(record.meta.auth.includes("realName")){
                    if (!realNameAuth(to)){
                        return ;// 取消导航
                    }
                }
                if (record.meta.auth.includes("major")) {
                    if (!majorAuth(to,'major')){
                        return ;// 取消导航
                    }
                }
                if (record.meta.auth.includes("edu")) {
                    if (!majorAuth(to,'edu')){
                        return ;// 取消导航
                    }
                }
                if (record.meta.auth.includes("career")) {
                    if (!majorAuth(to,'career')){
                        return ;// 取消导航
                    }
                }
                if (record.meta.auth.includes("expert")) {
                    if (!expertAuth(to)){
                        return ;// 取消导航
                    }
                }
            }
    }
    }
    if(to.meta.showHeader){
        store.commit('setHeaderShow',true);
    }else{
        store.commit('setHeaderShow',false);
    }
        next(); // 允许导航
});


router.afterEach(()=>{

})

//3.将router对象传入到Vue实例 (需要这段代码作为输出，否则报错)
export default router
