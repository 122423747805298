import Vue from "vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter);


let routes = [
    {
        path:'/form/diy',  //创建模板
        name: 'Diy',
        component: () => import('../pages/form/diy'),
        meta:{
            title:"创建模板",
            auth:['login','realName']
        }
    },{
        path:'/form/view',  //模板
        name: 'View',
        component: () => import('../pages/form/view'),
        meta:{
            title:"模板",
        }
    },
    {
        path: '/circle/index',  //朋友圈
        component: () => import('../pages/circle/index'),
        meta: {
            title:"朋友圈",
            keepAlive: true,
            showHeader:true
        }
    },
    {
        path: '/circle/mySpace/:id',  //个人空间
        name:'mySpace',
        component: () => import('../pages/circle/mySpace'),
        meta: {
            title:"个人空间",
            auth:['login']
        }
    },
    {
        path: '/circle/organSpace/:id',  //机构空间
        name:'organSpace',
        component: () => import('../pages/circle/organSpace'),
        meta: {
            title:"机构空间",
            auth:['login']
        }
    },
    {
        path: '/circle/imageInfo/:id',  //图片动态详情
        name:'imageInfo',
        component: () => import('../pages/circle/imageInfo'),
        meta: {
            title:"图片详情"
        }
    },
    {
        path: '/circle/dynamicInfo/:id',  //动态详情
        name:'imageInfo',
        component: () => import('../pages/circle/dynamicInfo'),
        meta: {
            title:"动态详情"
        }
    },
    {
        path: '/circle/videoInfo/:id',  //视频详情
        name:'imageInfo',
        component: () => import('../pages/circle/videoInfo'),
        meta: {
            title:"视频详情"
        }
    },
    {
        path: '/circle/enclosureInfo/:id',  //附件详情
        name:'imageInfo',
        component: () => import('../pages/circle/enclosureInfo'),
        meta: {
            title:"附件详情"
        }
    },
    {
        path:'/topic/list/',  //话题列表
        component: () => import('../pages/topic/list'),
        meta:{
            title:"更多话题",
            showHeader:true
        }
    },
    {
        path:'/topic/add/',  //话题创建
        component: () => import('../pages/topic/add'),
        meta:{
            title:"更多话题",
            auth:['login','realName','major']
        }
    },
    {
        path:'/topic/info/:topic',  //详情
        component: () => import('../pages/topic/info'),
        meta:{
            title:"更多话题",
            showHeader: true,
        }
    },
    {
        path:'/circle/space/',  //朋友圈个人空间
        component: () => import('../pages/circle/space'),
        meta:{
            title:"朋友圈个人空间",
            auth:['login']
        }
    },
    {
        path:'/topic/edit/:topic',  //编辑话题
        component: () => import('../pages/topic/edit'),
        meta:{
            title:"编辑话题",
            auth:['login','realName','major']
        }
    },
    {
        path:'/cityDemo',  //城市选择demo
        component: () => import('../pages/test/cityDemo'),
        meta:{
            title:"城市选择Demo"
        }
    },
    {
        path:'/wxOauth',   //微信登录扫码
        component: () => import('../pages/index/wxOauth'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title:"微信扫码登陆"
        }
    },
    {
        path: '/wxBind',   //微信登录扫码
        component: () => import('../pages/index/wxBind'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title: "微信扫码登陆"
        }
    },{
        path:'/qqOauth',   //qq登录扫码
        component: () => import('../pages/index/qqOauth'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title:"QQ扫码登陆"
        }
    },
    {
        path: '/qqBind',   //qq登录扫码
        component: () => import('../pages/index/qqBind'),
        props: route => ({
            code: route.query.code,
        }),
        meta:{
            title: "QQ扫码登陆"
        }
    },
    {
        path:'/auth',  // 认证中心
        component: () => import('../pages/index/authentication.vue'),
        meta:{
            title:"认证中心",
            auth:['login']
        }
    },
    {
        path:'/archives',  // 个人档案
        component: () => import('../pages/index/archives/index.vue'),
        meta:{
            title:"个人档案",
            auth:['login','realName']
        }
    },
    {
        path:'/news',  // 私信
        name: 'News',
        component: () => import('../pages/index/news/index.vue'),
        meta:{
            title:"私信",
            auth:['login']
        }
    },
];

export default routes
