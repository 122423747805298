<template>
	<div id="Guide" v-if="GuideData.active" >
		<!-- 左-->
		<div class="shadows left" :style="{
			'top':0,
			'left':0,
			'width':x+'px',
			'height':'100%'
		}"></div>

		<!-- 右-->
		<div class="shadows right" :style="{
			'top':0,
			'left':(x+width)+'px',
			'width':' calc( 100% - '+x+'px - '+width+'px  )',
			'height':'100%'
		}"></div>

		<!-- 上-->
		<div class="shadows top" :style="{
			'top':0,
			'left':(x)+'px',
			'width':width+'px',
			'height': y+'px',
		}"></div>

		<!-- 下-->
		<div class="shadows bottom" :style="{
			'top':(height+y)+'px',
			'left':(x)+'px',
			'width':width+'px',
			'height':' calc( 100% - '+y+'px - '+height+'px  )',
		}"></div>




		<div class="box" :style="{
			'width':width+'px',
			'height':height+'px',
			'top':y+'px',
			'left':x+'px'
		}"  v-if="type == '1'"></div>

		<div class="arrow" :style="{
			'top':(y+height+10)+'px',
			'left':(x+width/2-15)+'px'
		}" v-if="type == '1'">
			<i class="el-icon-top"></i>
		</div>


		<div class="infomation" :style="{
			'top':(y+height+50)+'px',
			'left':(x+width/2-15)+'px'
		}">
			{{ infomation }}
			<div class="nextStep op sc hand" @click="nextStep">({{ stepIndex+1 }}/{{ GuideData.stepList.length }}) 下一步</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "Guide",
	computed:{
		user() {
			return this.$store.state.user;
		},
		GuideData() {
			return this.$store.state.GuideData;
		},
	},
	watch:{
		'GuideData.active'(value) {
			if (value){
				//显示
				this.stepIndex = 0;
				this.readStep();
			}
		}
	},
	mounted() {
		this.setFocus('bbc');
		const that = this;
		window.addEventListener('resize', function() {
			that.setFocus('bbc');
		});
	},
	methods:{
		nextStep(){
			if (this.stepIndex == this.GuideData.stepList.length-1){
				this.$store.commit("setGuideData",{
					active:false,
					stepList:[]
				})
			}else{
				this.stepIndex++;
				this.readStep();
			}

		},
		readStep(){
			this.type = this.GuideData.stepList[this.stepIndex].type;
			if (this.GuideData.stepList[this.stepIndex].type == "2"){
				//无焦点
				this.width = 100;
				this.height = 0;
				this.x = 0;
				this.y = 0;

			}else{
				this.setFocus(this.GuideData.stepList[this.stepIndex].elementId)
			}
			this.infomation = this.GuideData.stepList[this.stepIndex].infomation;
		},
		setFocus(elementId){
			const element = document.getElementById(elementId);
			if (element){
				const rect = element.getBoundingClientRect();
				this.x = rect.left + window.scrollX;
				this.y = rect.top + window.scrollY;
				this.width = rect.width;
				this.height = rect.height;
			}
			
		}
	},
	data(){
		return{
			type:"2",
			stepIndex:0,
			width:100,
			height:0,
			x:0,
			y:0,
			infomation:"科技部国外人才研究中心2022年公开招聘高校应届毕业生面试公告 日期： 2022年04月29日 09:12 【字号：大 中 小】 --> 科学技术部国外人才研究中心2022年公开招聘高校应届毕业生面试具体安排如下： 一、面试人员名单 根据笔试成绩，人员名单见附件。 二、面试时间 2022年5月6日 09:00 编辑岗、研究B岗 2022年5月6日 14:00 研究A岗 三、面试平台 腾讯视频系统 四、合格分数线 笔试、面试综合成绩合格分数线为60分，低于综合成绩合格分数线的考生不得确定为考察人选。 五、面试注意事项 1.考生参加面试前，须提供本人身份证、学生证、学籍证明、政治面貌证明、户籍证明、报考职位所要求的外语等级证书等。 "
		}
	}
}
</script>

<style scoped lang="scss">
#Guide {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0);
	z-index: 100;

	.shadows{
		background: rgba(0,0,0,.7);
		position: absolute;
		z-index: 101;
	}
	.box {
		background: rgba(0,0,0,0);
		position: absolute;
		//background: red;
		z-index: 102;
		border: 3px solid red;
	}
	.arrow{
		color: #FFF; font-size: 30px;
		z-index: 102;
		position: absolute;
	}
	.infomation{
		color: #FFF; font-size: 20px;
		z-index: 102;
		position: absolute;
		max-width: 50%;
	}
	.nextStep{
		color: #FFF; font-size: 20px;
		z-index: 102;
		width: fit-content; margin-top: 30px;
		border: 2px solid #FFF;
		padding: 20px;
		border-radius: 10000px;
	}
}
</style>
