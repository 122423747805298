var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.GuideData.active)?_c('div',{attrs:{"id":"Guide"}},[_c('div',{staticClass:"shadows left",style:({
		'top':0,
		'left':0,
		'width':_vm.x+'px',
		'height':'100%'
	})}),_c('div',{staticClass:"shadows right",style:({
		'top':0,
		'left':(_vm.x+_vm.width)+'px',
		'width':' calc( 100% - '+_vm.x+'px - '+_vm.width+'px  )',
		'height':'100%'
	})}),_c('div',{staticClass:"shadows top",style:({
		'top':0,
		'left':(_vm.x)+'px',
		'width':_vm.width+'px',
		'height': _vm.y+'px',
	})}),_c('div',{staticClass:"shadows bottom",style:({
		'top':(_vm.height+_vm.y)+'px',
		'left':(_vm.x)+'px',
		'width':_vm.width+'px',
		'height':' calc( 100% - '+_vm.y+'px - '+_vm.height+'px  )',
	})}),(_vm.type == '1')?_c('div',{staticClass:"box",style:({
		'width':_vm.width+'px',
		'height':_vm.height+'px',
		'top':_vm.y+'px',
		'left':_vm.x+'px'
	})}):_vm._e(),(_vm.type == '1')?_c('div',{staticClass:"arrow",style:({
		'top':(_vm.y+_vm.height+10)+'px',
		'left':(_vm.x+_vm.width/2-15)+'px'
	})},[_c('i',{staticClass:"el-icon-top"})]):_vm._e(),_c('div',{staticClass:"infomation",style:({
		'top':(_vm.y+_vm.height+50)+'px',
		'left':(_vm.x+_vm.width/2-15)+'px'
	})},[_vm._v(" "+_vm._s(_vm.infomation)+" "),_c('div',{staticClass:"nextStep op sc hand",on:{"click":_vm.nextStep}},[_vm._v("("+_vm._s(_vm.stepIndex+1)+"/"+_vm._s(_vm.GuideData.stepList.length)+") 下一步")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }