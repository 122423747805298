<template>
  <div :style="{textAlign:textAlign}">
    <img :src="url" :style="{width:widthPx+'px',height:heightPx+'px'}"/>
  </div>
</template>

<script>
export default {
  name: "simpleImage",
  props: {
    url: {
      type: String,
      default: ''
    },
    widthPx: {
      type: Number,
      default: 300
    },
    heightPx: {
      type: Number,
      default: 300
    },
    textAlign: {
      type: String,
      default: "center"
    }
  }
}
</script>

<style scoped>

</style>