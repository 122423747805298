<template>
  <div class="date-page">
    <div class="date-header">
      <div class="hand op" @click="toShowTips()" style="position: absolute; left: 12px; color: #2970FF; margin-top: 6px; font-size: 16px; padding: 2px 10px; border-radius: 5px;border: 1px #2970FF solid"><i class="el-icon-circle-plus-outline" style="margin-right: 2px;"></i>重要提醒</div>
      <div class="year">
        <span class="btn op sc hand"  @click="preMonthClick()"><</span>
        <span >{{year}} 年 {{month}} 月</span>
        <span  class="btn op sc hand" @click="nextMonthClick()">></span>
      </div>
      <div v-if="month == new Date().getMonth() + 1" style="position: absolute; right: 33%; color: #FF9800; margin: 8px 0 0 0; font-size: 16px; font-weight: 600; padding: 1px 10px; border-radius: 5px;border: 1px #FF9800 solid">本月</div>
      <div @click="getToday" v-else style="position: absolute; right: 33%; background-color: #F6F6F6; margin: 8px 0 0 0; font-size: 16px; font-weight: 600; padding: 1px 10px; border-radius: 5px;border: 1px #D2D2D2 solid">今天</div>
    </div>
    <div class="date-content">

      <div class="weekList">
        <div class="week" v-for="item in weekList" :key="item">{{ item }}</div>
      </div>
      <div class="dataList">
        <div class="date"
             v-for="(item, index) in dayList"
             :class="{
						 'today':today.year == item.year && today.month == item.month && today.day == item.day,
						 'activeDay':activeDay.year == item.year && activeDay.month == item.month && activeDay.day == item.day
					 }"
             :key="index"
             @click="detailClick(item,index,$event)">

          <div v-if="activeDay.year == item.year && activeDay.month == item.month && activeDay.day == item.day && new Date().getTime() <= new Date(item.year + '-' + item.month.toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0') + ' 23:59:59').getTime()" style="position: absolute; right: 6px; top: 8px">
            <div v-if="item.contentList.length > 0">
              <el-dropdown trigger="click" @command="toShowAdd">
                <i style="font-size: 20px; font-weight:600;color: #FFFFFF;" class="el-icon-circle-plus-outline hand op"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1">添加日程</el-dropdown-item>
                  <el-dropdown-item :command="2">重要提醒</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>

          <div class="name" :class="{
						 'date-name':today.year == item.year && today.month == item.month && today.day == item.day}">{{ item.day }}</div>
            <div v-if="item.contentList">
              <div v-if="item.contentList.length <= 0 & activeDay.year == item.year && activeDay.month == item.month && activeDay.day == item.day" style="margin-left: 10px; text-align: center; color: #FFFFFF">
                <div @click="toShowAdd(1)" class="hand op" style="border: 1px #FFFFFF solid; border-radius: 5px; padding: 2px 0; width: 100px; margin-bottom: 10px"><i class="el-icon-circle-plus-outline" style="font-size: 18px; vertical-align: middle; margin-right: 2px;"></i><span style="vertical-align: middle">添加日程</span></div>
                <div @click="toShowAdd(2)" class="hand op" style="border: 1px #FFFFFF solid; border-radius: 5px; padding: 2px 0; width: 100px;"><i class="el-icon-circle-plus-outline" style="font-size: 18px; vertical-align: middle; margin-right: 2px"></i><span style="vertical-align: middle">重要提醒</span></div>
              </div>
            </div>
          <div class="contentList" style="padding: 0 4px">
            <div class="content" v-for="content in item.contentList" :key="content.id">
              <div class="hand op" @click="deleteDate(content.id)" v-if="activeDay.year == item.year && activeDay.month == item.month && activeDay.day == item.day" style="position: absolute; font-size: 8px; font-weight: 600; right: 1px; top:1px; width: 10px; height: 10px; color: #FFFFFF; border-radius: 50%; background-color: #FF444C">X</div>
              <div class="text" v-if="content.typeTwo === 1" :style="{'backgroundColor': (new Date(content.date).getTime() < new Date().getTime() ? '#F8F8F8':'')}">{{content.content}}</div>
              <div class="text" style="display: flex; justify-content: center" :style="{color: tipsType.find(option => {return option.value === content.warnType}).color}" v-if="content.typeTwo === 2"><img style="width: 14px; height: 14px; margin: 3px 2px 0 2px" :style="{background: tipsType.find(option => {return option.value === content.warnType}).color}" :src="require('@/assets/imgs/index/' + tipsType.find(option => {return option.value === content.warnType}).icon)"><div>{{content.content}}</div></div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--添加日程-->
    <el-dialog :modal-append-to-body="false" :destroy-on-close="true" :visible.sync="dateShow" width="430px" :show-close="false" class="event-form" :close-on-click-modal="false">
      <div class="top" style="text-align: center;">
        添加日程
        <i class="el-icon-close hand op" style="font-size: 24px;position: absolute;right: 20px;top: 14px;cursor: pointer;" @click="dateShow = false"></i>
      </div>
      <div style="padding: 20px 30px; overflow: hidden">
        <div>
          <div class="title-form">
            选择提醒日期
          </div>
          <div>
            <el-date-picker
                v-model="selectDate"
                @change="changeOne($event, 1)"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div style="margin-top: 30px">
          <div class="title-form">
            添加日程
          </div>
          <div>
            <el-input
                type="textarea"
                :rows="5"
                :maxlength="50"
                placeholder="请输入日程详情"
                v-model="selectContent">
            </el-input>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin: 26px 0 6px 0">
          <div class="btns hand op" @click="addDate">添加日程提醒</div>
        </div>

      </div>
    </el-dialog>

    <!--添加提醒-->
    <el-dialog :modal-append-to-body="false" :destroy-on-close="true" :visible.sync="tipsShow" width="430px" :show-close="false" class="event-form" :close-on-click-modal="false">
      <div class="top" style="text-align: center;">
        添加重要提醒
        <i class="el-icon-close hand op" style="font-size: 24px;position: absolute;right: 20px;top: 14px;cursor: pointer;" @click="tipsShow = false"></i>
      </div>
      <div style="padding: 20px 30px; overflow: hidden">
        <div>
          <div class="title-form">
            选择提醒图标
          </div>
          <div style="display: flex">
            <div style="width: 34px; text-align: center; margin-right: calc((100% - 170px) / 4)" v-for="(item, index) in tipsType" :key="index">
              <img class="title-icon" :src="require('@/assets/imgs/index/' + item.path)">
              <div class="">{{item.name}}</div>
              <el-radio v-model="tipsData.type" :label="item.value"></el-radio>
            </div>
          </div>
        </div>
        <div style="margin-top: 26px">
          <div class="title-form">
            选择提醒日期
          </div>
          <div>
            <el-date-picker
                v-model="tipsData.date"
                @change="changeOne($event, 2)"
                type="date"
                :picker-options="pickerOptions"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div style="margin-top: 30px">
          <div class="title-form">
            重要提醒详情
          </div>
          <div>
            <el-input
                type="textarea"
                :rows="5"
                :maxlength="50"
                placeholder="请输入日程详情"
                v-model="tipsData.content">
            </el-input>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin: 26px 0 6px 0">
          <div class="btns hand op" @click="addTips">添加重要提醒</div>
        </div>

      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'Calendats',
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      dayList: [],
      today: {},
      activeDay:{},
      show: false,
      weekList: ['日','一', '二', '三', '四', '五', '六'],
      formLeft:0,
      formTop:0,
      formShow:'hide',
      addContent:"",
      editIndex:0,
      calendarEventList:[],

      dateList: [],
      tipsList: [],
      tipsShow: false,
      dateShow: false,

      // 日程
      selectDate: '',
      selectContent: '',

      // 提醒
      tipsType: [
        {path: 'importance.png', icon: 'importanceIcon.png', name: '默认', value: 1, color: '#DC151F', background: 'linear-gradient(229deg, #FF444C 0%, #D60D17 100%)'},
        {path: 'record.png', icon: 'recordIcon.png', name: '考试', value: 2, color: '#2970FF', background: 'linear-gradient(229deg, #2970FF 0%, #074BD4 100%)'},
        {path: 'evection.png', icon: 'eventionIcon.png', name: '出差', value: 3, color: '#38B781', background: 'linear-gradient(229deg, #38B781 0%, #048B52 100%)'},
        {path: 'festival.png', icon: 'festivalIcon.png', name: '生日', value: 4, color: '#FF9800', background: 'linear-gradient(229deg, #FF9800 0%, #FE6D1A 100%)'},
        {path: 'project.png', icon: 'projectIcon.png', name: '开会', value: 5, color: '#FFD82A', background: 'linear-gradient(229deg, #FFD82A 0%, #FFAF2D 51%, #FC8F3A 100%)'},
      ],
      tipsData: {
        type: 1,
        date: '',
        content: ''
      },

      type: 0,


      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
    }
  },
  computed: {
    me() {
      return this.$store.state.user
    }
  },
  props:{
    dates:{
      type:String,
      default: ''
    }
  },
  watch:{
  },
  mounted() {
    if (this.dates) {
      this.year = Number(this.dates.substr(0, 4));
      this.month = Number(this.dates.substr(5, 1) === 0 ? this.dates.substr(6, 1) : this.dates.substr(5, 2));
      this.day = Number(this.dates.substr(8, 1) === 0 ? this.dates.substr(9, 1) : this.dates.substr(8, 2));
      this.activeDay = {
        day : this.day,
        year: this.year,
        month: this.month
      }
      this.today = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
      }
    } else {
      this.getToday();
    }
    this.getAllList();
  },
  methods: {
    // 删除日程
    deleteDate(id) {
      let that = this;
      this.utils.confirm('确定删除此日历事件？', function () {
        that.newApi.deleteCalendarEvent({id: id}).then(res => {
          if (res.isSuccess === 1) {
            that.utils.sus(res.data);
            that.getAllList()
            that.$emit('childEvent');
          }
        })
      })
    },

    // 修改日期
    changeOne(date, type) {
      if (type === 1) {
        this.selectDate = this.changeDates(date)
      }
      if (type === 2) {
        this.tipsData.date = this.changeDates(date)
      }
    },

    // 获取所有列表
    getAllList() {
      this.newApi.getCalendarEventMoreList({type: 1, date: this.year+'-'+this.month+'-'+ '01'+ ' 00:00:00'}).then(res => {
        this.calendarEventList = res.data;
        this.getMonthDays(this.year, this.month - 1);
      })
    },

    toShowTips() {
      this.tipsShow = true
      this.tipsData.date = ''
    },

    toShowAdd(type) {
      this.type = type;
      let month = this.activeDay.month.toString().padStart(2, '0');
      let day = this.activeDay.day.toString().padStart(2, '0');
      let defaultDate = `${this.activeDay.year}-${month}-${day}`;
      if (type === 1) {
        this.dateShow = true;
        this.selectDate = defaultDate
      }
      if (type === 2) {
        this.tipsShow = true
        this.tipsData.date = defaultDate
      }
    },
    // 添加日程
    addDate() {
      if (!this.selectDate) {
        this.utils.msg('请选择提醒日期')
        return
      }
      if (!this.selectContent) {
        this.utils.msg('请输入日程详情')
        return
      }
      let params = {
        type: 1,
        content: this.selectContent,
        typeTwo: 1,
        date: this.selectDate + ' 00:00:00'
      }
      this.newApi.addCalendarEvent(params).then(res =>{
        if (res.isSuccess === 1) {
          this.utils.sus('添加成功');
          this.dateShow = false;
          this.getAllList()
          this.$emit('childEvent');
        }
      })
    },

    // 转换
    changeDates(date) {
      let getYear = date.getFullYear();
      let getMonth =  (date.getMonth() + 1).toString().padStart(2, '0');
      let getDay = (date.getDate()).toString().padStart(2, '0');
      return `${getYear}-${getMonth}-${getDay}`
    },

    // 添加提醒
    addTips() {
      if (!this.tipsData.date) {
        this.utils.msg('请选择提醒日期')
        return
      }
      if (!this.tipsData.content) {
        this.utils.msg('请输入重要提醒详情')
        return
      }
      let params = {
        type: 1,
        content: this.tipsData.content,
        typeTwo: 2,
        warnType: this.tipsData.type,
        date: this.tipsData.date + ' 00:00:00'
      }
      this.newApi.addCalendarEvent(params).then(res =>{
        if (res.isSuccess === 1) {
          this.utils.sus('添加成功');
          this.tipsShow = false;
          this.getAllList()
          this.$emit('childEvent');
        }
      })
    },

    // 获取当前日期
    edit(content,index){
      if (content.user.uuid != this.me.uuid){
        this.utils.err("不可编辑别人写的");return;
      }
      this.formShow='edit';
      this.editIndex=index;
      this.addContent=content.content;
    },
    getRoomCalendar(){
      const that = this;
      this.newApi.getCalendarEventList({
        roomId:this.roomId
      }).then(res =>{
        that.calendarEventList = res.data;
        that.getToday();
      })
    },

    getToday() {
      let date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.day = date.getDate();//获取这是周几
      //初始化数据
      this.getAllList()
      //得到当月今天的位置index
      this.today = {
        day : this.day,
        year: this.year,
        month: this.month
      }
      // this.activeDay = {
      //   day : this.day,
      //   year: this.year,
      //   month: this.month
      // }
    },
    // 上一月
    preMonthClick() {
      this.month--;
      if (this.month <= 0) {
        this.month = 12;
        this.year--;
      }
      this.getAllList();
      // this.getMonthDays(this.year, this.month - 1);
    },
    // 下一月
    nextMonthClick() {
      this.month++;
      if (this.month >= 13){
        this.month = 1;
        this.year++;
      }
      this.getAllList();
      // this.getMonthDays(this.year, this.month - 1);
    },
    // 获取日期数组
    getMonthDays(year, month) {
      // 定义每个月的天数，如果是闰年第二月改为29天
      let daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        daysInMonth[1] = 29;
      }
      // 当月第一天为周几
      let targetDay = new Date(year, month, 1).getDay();
      let calendarDateList = [];
      let preNum = targetDay;
      let nextNum = 0;
      if (targetDay > 0) {
        // 当前月份1号前的自然周剩余日期，置空
        for (let i = 0; i < preNum; i++) {
          calendarDateList.push({day: "", year: this.year, month: this.month});
        }
      }
      for (let i = 0; i < daysInMonth[month]; i++) {
        // 正常显示的日期
        const contentList = [];
        for (let j = 0; j < this.calendarEventList.length; j++) {
          const y = parseInt(this.calendarEventList[j].date.substring(0, 4));
          const m = parseInt(this.calendarEventList[j].date.substring(5, 7));
          const d = parseInt(this.calendarEventList[j].date.substring(8, 10));
          // console.log(this.calendarEventList[j].date,y,m,d)
          if (y === this.year && m === this.month && d === i + 1){
            contentList.push(this.calendarEventList[j]);
          }
        }
        calendarDateList.push({
          day: i + 1,
          year: this.year,
          month: this.month,
          contentList:contentList
        })
      }
      // 当前月份最后一天的自然周剩余日期，置空
      nextNum = 6 - new Date(year, month + 1, 0).getDay();
      for (let i = 0; i < nextNum; i++) {
        calendarDateList.push({day: "", year: this.year, month: this.month});
      }
      this.dayList = calendarDateList;
    },
    // 当前点击日期
    detailClick (day,index, e) {
      const dateString = day.year + "-" + day.month + "-" + day.day + " 00:00:00";
      const timestamp = Math.round(new Date(dateString).getTime()/1000);

      const now = new Date(); // 获取当前时间
      const year = now.getFullYear(); // 获取当前年份
      const month = now.getMonth(); // 获取当前月份
      const days = now.getDate(); // 获取当前日期
      const zeroTime = new Date(year, month, days); // 当天零点的时间对象
      const zeroTimeTimestamp = Math.round(zeroTime.getTime()/1000); // 当天零点的时间戳
      if(timestamp < zeroTimeTimestamp){
        this.utils.err("不能小于当天时间");
        return false;
      }

      this.addContent = "";
      if (day.day) {
        if (this.dayList[index].day == ""){
          this.formShow = "hide";
        }else {
          if (this.dayList[index].contentList && this.dayList[index].contentList.length>0){
            this.formShow = "list";
          }else{
            this.formShow = "add";
          }
        }

        this.formLeft = e.x;
        this.formTop = e.y;
        this.activeDay = day;

        var width = 500;
        var height = 300;
        if (e.x + width > window.innerWidth){
          //右侧越过边界
          this.formLeft = window.innerWidth-width;
        }
        if (e.y + height > window.innerHeight){
          //右侧越过边界
          this.formTop = window.innerHeight-height;
        }
      }
    },
    del(){
      this.addContent = "";
      this.formShow = "hide";
      this.newApi.deleteCalendarEvent({
        id:this.activeDay.contentList[this.editIndex].id
      })
      this.activeDay.contentList.splice(this.editIndex,1);

    },

    changeDate(data) {
        this.calendarEventList.push(data);
        this.getAllList();
        // this.year = Number(data.date.substr(0, 4));
        // this.month = Number(data.date.substr(5, 1) === 0 ? data.date.substr(6, 1) : data.date.substr(5, 2));
        // this.getMonthDays(this.year, this.month - 1)
    },

    save(){
      if (this.addContent == ""){
        return;
      }
      if(this.formShow == "add"){
        const params = {
          type:1,  // 1个人日历 2Room日历
          content:this.addContent,
          date:this.activeDay.year+"-"+(this.activeDay.month < 10 ? '0'+this.activeDay.month:this.activeDay.month)+"-"+(this.activeDay.day < 10 ? '0'+this.activeDay.day:this.activeDay.day)+" 00:00:00"
        };
        this.activeDay.contentList.push({
          //先把写好的数据放进去，看起来流畅
          user:this.me,
          content:this.addContent
        })

        const that = this;
        this.newApi.addCalendarEvent(params).then(res =>{
          that.calendarEventList.push(res.data)
          that.activeDay.contentList[that.activeDay.contentList.length-1].id = res.data.id; //更新数据
        })
      }else{
        this.activeDay.contentList[this.editIndex].content = this.addContent;
        this.newApi.saveCalendarEvent({
          id:this.activeDay.contentList[this.editIndex].id,
          content: this.activeDay.contentList[this.editIndex].content
        })
      }

      this.addContent = "";
      this.formShow = "hide";
    }
  }
}
</script>
<style scoped>
.date-page .btns {
  width: 130px;
  text-align: center;
  padding: 4px 0;
  background-color: #2970FF;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
}

.event-form .top {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #444444;
  border: 1px solid #CECFD0;
  height: 50px;
  box-sizing: border-box;
  line-height: 50px;
}

.event-form .title-form {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

/deep/ .event-form .el-dialog__header {
  padding: 0;
}

/deep/ .event-form .el-dialog__body {
  padding: 0;
}

/deep/ .event-form .el-radio__label {
  display: none;
}
</style>

<style lang="scss" scoped>

.date-page{
  width: 100%; user-select:none; position: relative;
  .date-header {
    display: flex;
    position: relative;
    justify-content: center;
    border: 1px solid #CECFD0;
  }
  .year {
    font-weight: 600;
    margin: 6px 0;
    font-size: 18px;  line-height: 30px;height: 30px;
    span{
      display: block; float: left;
    }
    .btn{
      width: 30px; margin: 0 10px; font-weight: 600;
      text-align: center;border-radius: 10px;
    }
  }

  .weekList {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    .week {
      width: 14.285%;
      background-color: #FFFFFF;
      padding: 8px 0;
      text-align: center;
    }
  }
  .dataList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    .today{
      background-color: #E3E3E3;
    }
    .date {
      height: 112px;
      width: calc( 100% / 7 - 2px) ;
      border: 1px solid #CECFD0;
      position: relative;
      .name{
        font-size: 18px; margin: 6px 10px;
      }
      .date-name{
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        display: inline-block;
        background-color: #FF444C;
        color: #FFFFFF;
        border-radius: 50%;
      }
      .contentList{
        max-height: 80px;
        font-size: 12px;
        overflow: hidden;
        .content{
          text-align: center;
          border: 1px solid #CECFD0;
          background-color: #FFFFFF;
          border-radius: 2px;
          margin-bottom:5px;
          .text{
            line-height: 20px;
            //white-space: nowrap;overflow: hidden;text-overflow: ellipsis
          }
        }
      }
    }
    .activeDay {
      transform: scale(1.1);

      border-radius: 10px;
      background: #2970FF;
      z-index: 1000;
      .name{
        color: #FFFFFF;
        border-radius: 50%;
      }
      .contentList{
        max-height: 70px;
        overflow: auto;
      }
      .contentList::-webkit-scrollbar{
        display: none;
      }
      .content{
        text-align: center;
        border: 1px solid #CECFD0;
        background-color: #FFFFFF;
        border-radius: 2px;
        position: relative;
        margin-bottom:5px;
        .text{
          margin-right: 8px;
          z-index: 1000;
        }
      }
    }
  }


  #addForm,#contentListView{
    width: 500px;height: 300px; background: #FFF;position: fixed; box-shadow: 1px 1px 3px 1px #6d737b;
    .title{
      padding:0 15px; color: #000; background: #b0d4ff; height:40px;line-height: 40px;
      position: absolute;top: 0;left: 0;width: calc( 100% - 30px ); display: flex;
      justify-content: space-between;
      span{
        float: right; color: #2f2f2f; font-size: 15px;
      }
    }

    .input{
      padding: 0 !important;
      margin-top: 40px;
      textarea{
        height: 220px; border-radius: 0;
      }
    }

    .contentList{
      margin: 40px 0; overflow-y: scroll; height: 220px;
      .content{
        padding: 5px;cursor:pointer;
        img{
          width: 20px; height: 20px; border-radius: 50%;
          float: left;
        }
        .nickName{
          float: left;height: 20px;line-height: 20px;
          color: #1C7EFF; margin: 0 5px;
        }
        .text{
          line-height: 20px;
        }

      }
      .content:hover{
        background: #f3f3f3;
      }
    }

    .bottom{
      padding:0 15px; color: #000; background: #b0d4ff; height:40px;line-height: 40px;
      position: absolute;bottom: 0;left: 0;width: calc( 100% - 30px );
    }
  }
}

</style>
