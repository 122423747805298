<template>
	<div style="overflow:hidden;">
		<div @dragover.prevent @drop="handleDrap($event,data)" @click="change_show_children(data.id)" style="height: 40px;overflow: hidden;font: 14px Microsoft YaHei;line-height: 40px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-left: 8px;">
			<div v-if="show_children" ><img src="../../../../src/assets/imgs/room/folder_open.png" style="width: 30PX;height: 30px;float:left;padding: 5px 10px 5px 0;"></div>
			<div v-if="!show_children" ><img src="../../../../src/assets/imgs/room/folder_close.png" style="width: 30PX;height: 30px;float:left;padding:5px 10px 5px 0;"></div>
			<div style="height:40px;line-height:40px;float:left;">{{data.name}}</div>
		</div>

		<label v-if="show_children">
			<div  v-for="(item,index) in subList" v-bind:key="index" style="padding-left: 16px;overflow: hidden;" :style="item.roomId == id ? 'background-color: #E8E8E8': ''" >
				<label v-if="item.roomId==0">
					<Tree :data = "item" :id="item.id"></Tree>
				</label>
				<label v-if="item.roomId!=0" @click="utils.a('/room/info/'+ item.roomInfo.id)"   style="overflow:hidden" class="hand op sc" :draggable="true" @dragstart="handleDrapStart($event, item.roomInfo)"  @dragend="dragend">
					<div style="width: 30px;float:left;height: 30px;line-height: 30px;margin-top: 5px;margin-right:  10px;" >
						<img :src="item.roomInfo.cover+'-80x80'" style="width: 100%;height: 100%;border-radius: 20px;"/>
					</div>

					<div class="room_name" style="width: calc(100% - 45px);float:left;height: 40px;overflow: hidden;font: 14px Microsoft YaHei;line-height: 40px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" >{{item.roomInfo.name}}</div>
				</label>

			</div>
		</label>

	</div>
</template>

<script>

	export default {
		name:"Tree",
		components: {

		},
		data () {
			return {
				show_children:false,
				subList: []
			}
		},
		props:{
			data:{
				type:Object,
				default() {
                    return {
						label:"",
                    }
                }
			},

			id: {
				type: Number,
				default() {
				}
			}

		},
		computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
		created:function(){
			this.$EventBus.$on('my_room_list_remove'+this.data.id, (id) => {
				this.subList = this.subList.filter(item => item.roomId !== id);
			})
			this.$EventBus.$on('my_room_list_add'+this.data.id, (roomInfo) => {
				if (this.subList.some(item => item.id === roomInfo.id)) {
					return false;
				}
				const obj = {
					fid: this.data.id,
					id: "",
					name: "",
					roomId: roomInfo.id,
					uuid: localStorage.getItem("uuid"),
					roomInfo: roomInfo
				};
				this.subList.push(obj);
			})
		},
		mounted: function () {


		},
		methods:{
			// 开始进行拖拽
			handleDrapStart(event, value) {
				value.folderId = this.data.id;
				event.dataTransfer.setData("roomInfo", JSON.stringify(value));

			},
			handleDrap(event,data) {
				let that = this;
				let roomInfo = JSON.parse(event.dataTransfer.getData("roomInfo"));
				if (roomInfo){
					if (that.subList.some(item => item.roomId=== roomInfo.id)) {
						// that.utils.err("当前列表已有此room");
						return false;
					}
					that.newApi.addRoomsInFolder({roomId:roomInfo.id,fid:data.id}).then((res)=>{
						if (res.isSuccess==1){
							var obj={
								fid:data.fid,
								id:"",
								name:"",
								roomId:roomInfo.id,
								uuid:localStorage.getItem("uuid"),
								roomInfo:roomInfo
							}
							that.subList.push(obj);
							that.$EventBus.$emit("my_room_list_remove"+roomInfo.folderId, roomInfo.id);
							that.$EventBus.$emit("my_folder_list_remove", roomInfo.id);
							
						}
					})
				}
			},
			// 拖拽完成把数据清除一下
			dragend(){
				// this.closeMask();
				event.dataTransfer.clearData();
				// this.getFolderList(this.fid);
			},
			reloadAllRoomList(){
				// this.getFolderList(this.fid);
			},
			closeMask(){
				this.$parent.closeMask();
			},
			// 获取文件夹列表
			getFolderList(id){
				var that = this;
				this.newApi.getRoomFolderList({fid: id}).then(ret =>{
					that.subList = ret.data;
					that.show_children=true
				})
			},

			change_show_children:function(id){
				this.fid= id;
				if (this.show_children) {
					this.show_children = false;
				}else{
					this.getFolderList(id);
					this.show_children = true;
				}
			}

		}
	}

</script>

<style scoped>


</style>
