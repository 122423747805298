<template>
  <div v-show="props.compType === 'textarea'">
    <!-- <el-form-item label="字段名">
      <el-input class="input" v-model="props"></el-input>
    </el-form-item> -->
    <el-form-item label="ID">
      <el-tooltip class="item" effect="dark" content="请注意,ID的修改可能会导致该组件相关事件失效！" placement="left">
        <el-input class="input" v-model="props.id" @change="handlerChangeId"></el-input>
      </el-tooltip>
    </el-form-item>
    <el-form-item label="标题">
      <el-input class="input" v-model="props.label"></el-input>
    </el-form-item>
    <el-form-item label="提示符">
      <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符"/>
    </el-form-item>
    <!-- <el-form-item label="表单栅格">
      <el-slider class="input" v-model="props.span" :max="24" :min="1" :marks="{12:''}"></el-slider>
    </el-form-item> -->
    <el-form-item label="栅格间隔">
      <el-input-number v-model="props.gutter"  :min="0"></el-input-number>
    </el-form-item>
    <el-form-item label="标签宽度">
      <el-input-number v-model="props.labelWidth"  :min="1" :max="1000"></el-input-number>
    </el-form-item>
    <el-form-item label="显示标签">
      <el-switch v-model="props.showLabel" @change="handlerChangeLabel"></el-switch>
    </el-form-item>
    <el-form-item label="必填">
      <el-switch v-model="props.required"></el-switch>
    </el-form-item>
    <el-form-item label="输入框行数">
      <el-input-number v-model="props.rows"  :min="0"></el-input-number>
    </el-form-item>
    <el-form-item label="长度限制">
      <el-input-number v-model="props.maxlength" placeholder="字符长度"/>
    </el-form-item>
    <el-form-item label="输入统计">
      <el-switch v-model="props['show-word-limit']"></el-switch>
    </el-form-item>
    <el-form-item label="禁用">
      <el-switch v-model="props.disabled" @change="handlerChangeDisStatus"></el-switch>
    </el-form-item>
    <el-form-item label="只读">
      <el-switch v-model="props.readonly" @change="handlerChangeReadStatus"></el-switch>
    </el-form-item>
    <el-form-item label="默认值">
      <el-input class="input" v-model="props.value"></el-input>
    </el-form-item>


	  <el-divider>发布后显示的选项</el-divider>
	  <el-form-item label="字体大小">
		  <el-input-number v-model="props.fontSize"  :min="10" :max="50"></el-input-number>
	  </el-form-item>
	  <el-form-item label="文字行高">
		  <el-input-number v-model="props.lineHeight"  :min="10" :max="100"></el-input-number>
	  </el-form-item>
	  <el-form-item label="文字颜色">
		  <el-color-picker v-model="props.color" />
	  </el-form-item>
	  <el-form-item label="位置">
		  <el-radio-group v-model="props.textAlign">
			  <el-radio-button label="left">居左</el-radio-button>
			  <el-radio-button label="center">居中</el-radio-button>
			  <el-radio-button label="right">居右</el-radio-button>
		  </el-radio-group>
	  </el-form-item>

	  <el-divider>发布后 标签 显示的选项</el-divider>
	  <el-form-item label="标签大小">
		  <el-input-number v-model="props.labelFontSize"  :min="10" :max="50"></el-input-number>
	  </el-form-item>
	  <el-form-item label="标签行高">
		  <el-input-number v-model="props.labelLineHeight"  :min="10" :max="100"></el-input-number>
	  </el-form-item>
	  <el-form-item label="标签颜色">
		  <el-color-picker v-model="props.labelColor" />
	  </el-form-item>
  </div>
</template>
<script>
import {changeId} from '../mixin'
export default {
  name:"inputConfig",
  props:['props','getFormId'],
  components: {
  },
  mixins:[changeId],
  data(){
    return {
    }
  },
  methods:{
    handlerChangeLabel(val){
      this.props.labelWidth = val?'80':'1'
    },
    handlerChangeDisStatus(val){
      this.props.readOnly = !val
    },
    handlerChangeReadStatus(val){
      this.props.disabled = !val
    }
  },
  mounted(){
  }
}
</script>
<style scoped>
.input{
  width:75%
}
</style>