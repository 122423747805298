export function getSimpleId(){
  sleep(10); //主要为了延时工作 有用 这是设计表单里生成表格id的js id取时间戳，为了防止表格id重复，加了sleep 去掉，id就有可能重复了 表格一下生成8个格子，id一样 勿删
  return new Date().getTime();
}

const sleep = function(time) {
  const startTime = new Date().getTime() + parseInt(time, 10);
  while(new Date().getTime() < startTime) {
    console.log()
  }
};

export function setTableId(table){
  table.layoutArray.map((tr)=>{
    tr[0].id=getSimpleId();
    tr[1].id=getSimpleId();
  })
  return table;
}
