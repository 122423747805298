<template>
    <div style="width:100%;height:100%;">
        <p style="font-size: 20px;font-weight: bold;text-align: center">找回密码</p>
        <div style="text-align: center">
            <div style="width:400px;margin-top: 30px;text-align: center;">
                <el-form :model="ruleForm"  status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                  <div v-if="nextStep == 1">
                    <el-form-item  prop="phone">
                      <el-input style="width: 350px" v-model="ruleForm.phone" autocomplete="new-password" type="text" placeholder="请输入手机号" :maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item  prop="code">
                      <el-input  placeholder="输入6位短信验证码"  type="text" style="width: 350px" v-model="ruleForm.code" autocomplete="new-password" :maxlength="6" >
                        <template slot="append">
                          <el-button
                              style="border-radius: 0 4px 4px 0;width: 120px; line-height: 2px;"
                              :style="{ background: countdown>0 ? '#F5F5F5' : '#1C7EFF', color: countdown>0 ? '#666' : '#fff',height: countdown>0 ? '40px' : '40px', }"
                              :disabled="countdown>0"
                              @click="sendVerificationCode">
                            {{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
                          </el-button>
                        </template>
                      </el-input>
                    </el-form-item>
                    <div style="text-align: right;margin-top: 30px;margin-right: -50px;">
                      <el-button type="primary" style="height: 36px;" @click="clickNextStep(2)">下一步</el-button>
                    </div>
                  </div>
                  <div v-if="nextStep == 2">
                    <div style="font-size: 18px; font-weight: 600; width: 100%; text-align: center;margin-left: 60px;">该手机号下绑定了多个账号，请手动选择</div>
                    <div style="margin-top: 30px;width: 520px;">
                      <div :class="{'select-border': selectLogin === index}" v-for="(item, index) in userOptions" :key="index" class="card hand" @click="selectLogin = index">
                        <div style="display: flex; justify-content: space-between; width: 100%; font-size: 16px; font-weight: 600; line-height: 30px">
                          <div style="display: flex; width: 70%">
                            <div style="width: 45%">
                              <el-radio v-model="selectLogin" :label="index" style="">{{item.accoun}}</el-radio>
                            </div>
                            <div style="display: flex; width: 55%">
                              <img :src="item.headIco" style="width: 30px; height: 30px; margin-right: 10px; border-radius: 50%">
                              <div>{{item.nickName}}</div>
                            </div>
                          </div>
                          <div style="display: flex;">
                            <div class="btn" style="color: #4CAF50; border: 2px #4CAF50 solid" v-if="item.userType ===1"><i class="el-icon-user-solid"></i>个人账号</div>
                            <div class="btn" style="color: #2E57EE; border: 2px #2E57EE solid" v-if="item.userType ===2"><i class="el-icon-s-shop"></i>企业账号</div>
                            <div class="btn" style="color: #409EFF; border: 2px #409EFF solid" v-if="item.userType ===3">机构子账号</div>
                          </div>
                        </div>
                        <div class="triangle" v-if="selectLogin === index">
                          <i class="el-icon-check tr-icon"></i>
                        </div>
                      </div>
                    </div>
                    <el-form-item  prop="pwd">
                      <el-input type="text" style="width: 350px" v-model="ruleForm.pwd"  placeholder="请输入新密码" :maxlength="12" autocomplete="new-password" show-password></el-input>
                      <span class="el-icon-warning-outline"  :style="{color:pwdLength?'#5BC130':'#999999'}">  包含8-12个字符  </span>
                      <span class="el-icon-warning-outline" style="margin-left: 10px;" :style="{color:pwdNumber?'#5BC130':'#999999'}">  包含数字  </span>
                      <span class="el-icon-warning-outline" style="margin-left: 10px;" :style="{color:pwdString?'#5BC130':'#999999'}">  包含字母  </span>
                    </el-form-item>
                    <el-form-item  prop="confirmPwd">
                      <el-input type="text" style="width: 350px" v-model="ruleForm.confirmPwd" placeholder="请再次输入新密码"  :maxlength="12" autocomplete="new-password" show-password></el-input>
                    </el-form-item>
                    <div style="text-align: right;margin-top: 30px;margin-right: -50px;">
                      <el-button type="primary" style="height: 36px;" @click="commitChanges">确认修改</el-button>
                    </div>
                  </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },

    computed: {

    },
    data () {
        return {
            pwdLength:false,
            pwdNumber:false,
            pwdString:false,
            code:'',
            countdown: 0,
            cipher:'',
            checkPass:'',
            ruleForm: {
                phone:'',
                code:'',
                pwd:'',
                confirmPwd:'',
            },
            rules: {
                phone:[
                    { pattern: /^1[3|5|6|7|8|9][0-9]\d{8}$/, required: true, message: '请输入手机号', trigger: 'blur',}
                ],
                code:[
                    {pattern: /^[0-9]*$/, required: true, message: '请输入验证码', trigger: 'blur', }
                ],
                pwd: [
                    { required: true,trigger: 'blur',validator: this.validatePwd}
                ],
                confirmPwd:[
                    { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    { trigger: 'blur',validator: this.validateConfirmPwd  }
                ],
            },
            nextStep:1,
            selectLogin:0,
            userOptions:[]
        }
    },
    mounted:function(){

    },
    methods:{
      //校验密码
      validatePwd (rule, value, callback) {
          if (value.length < 8 || value.length > 12) {
              this.pwdLength = false;
              callback(new Error('请输入8-12位密码'));
          }else{
              this.pwdLength = true;
          }
          if (!/^(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(value)) {
              this.pwdString = false;
              this.pwdNumber = false;
              callback(new Error('密码必须包含数字和字母'));
          }else{
              this.pwdString = true;
              this.pwdNumber = true;
          }
          if (this.pwdString && this.pwdNumber && this.pwdLength) {
              //去检查确认密码
              this.$refs.ruleForm.validateField('confirmPwd');
          }
          callback();
      },
      validateConfirmPwd (rule, value, callback){
          if (value === '') {
              callback(new Error('请再次输入密码'));
          } else if (value !== this.ruleForm.pwd) {
              callback(new Error('两次输入密码不一致!'));
          } else {
              callback();
          }
      },

      sendVerificationCode() {
          const that = this;
          if (this.countdown > 0) return;
          this.$refs.ruleForm.validateField('phone', (errorMsg) => {
              if (!errorMsg) {
                  this.newApi.sendSms({"mobile":that.ruleForm.phone,smsType:6}).then((ret)=>{
                      that.utils.sus(ret.data);
                      this.startCountdown();
                  }).catch((err)=>{
                      that.utils.err(err);
                  });
              }
          })
      },
      startCountdown() {
          this.countdown = 60;
          const timer = setInterval(() => {
              this.countdown -= 1;
              if (this.countdown <= 0) {
                  clearInterval(timer);
              }
          }, 1000);
      },

      //下一步
      clickNextStep(step){
        var that = this;
        if(that.ruleForm.phone == "" || that.ruleForm.code == ""){
          that.utils.err("请填写手机号和验证码");
          return false;
        }
        that.newApi.checkCodePwd({mobile:that.ruleForm.phone,code:that.ruleForm.code,smsType:6}).then((ret)=>{
            if(ret.isSuccess == 1){
                that.userOptions = ret.data;
                if(ret.data.length > 0){
                  that.nextStep = step;
                }
            }
        })
      },

      // 提交修改
      commitChanges(){
          let that = this;
          this.$refs.ruleForm.validate((valid) => {
              if (valid) {
                that.newApi.forgetPwd({uuid:that.userOptions[that.selectLogin].uuid,pwd:that.ruleForm.pwd}).then((ret)=>{
                      that.utils.sus(ret.data);
                      that.$parent.$parent.cloneTologin();
                      that.nextStep = 1;
                      that.ruleForm = {}
                  }).catch((err)=>{
                      that.utils.err(err);
                  });
              }
          });

      }

    }
}
</script>

<style scoped>
.center_login{
  background:#fff;
  border-radius:10px;
  width:800px;
  height: 520px;
  position:absolute;
  margin:10% auto;
}
.card {
  width: 94%;
  height: 30px;
  display: flex;
  padding: 10px 20px;
  margin-bottom: 10px;
  position: relative;
  background-color: #F6F6F6;
}
.card .triangle{
  position:absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 14px solid #fff;
  border-bottom-color: #2970FF;
  border-right-color: #2970FF;
  border-top-color:transparent ;
  border-left-color: transparent;
}

.card .tr-icon {
  color: #FFFFFF;
  position:absolute;
  font-weight: 600;
}

.card .btn {
  padding: 2px 10px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 20px;
}

/deep/.el-form-item__content{
  margin-left: 0px;
}
</style>