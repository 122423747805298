<template>
  <transition name="el-fade-in-linear">
    <div id="addImageFileBaseFormBg" class="fade" v-if="isShow" v-loading="loading">
      <div id="form">
        <div id="head">上传图片 <div id="close" class="po sc hand" @click="onClose">X</div> </div>
        <div id="center">

<!--            <div class="el-upload__text">拖拽图片到这里，或<em>点此添加</em>-->
<!--                <el-upload class="upload op sc" drag multiple :auto-upload="false" action=" " :show-file-list="false" :on-change="uploadImg" accept="image/*">-->
<!--                </el-upload>-->
<!--            </div>-->
<!--              <div @click="isCodeFile = true"  class="el-upload__text">也可手机扫码上传文件</div>-->
<!--            <p>请您确保上传的内容符合法律法规，符合公共道德</p>-->
            <div style="width: 100%;height: 120px;text-align: center;">
                <el-upload class="upload op sc" drag multiple :auto-upload="false" action=" " :show-file-list="false" :on-change="uploadImg" accept="image/*">
                    <div>拖拽图片到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                </el-upload>
            </div>
            <div v-show="imgList.length == 0" style="width: 100%;line-height: 50px;text-align: center;margin-top: 20px;">
                <div>扫描二维码手机上传文件</div>
                <div  style="margin: 0 auto;">
                    <vue-qr
                        :text="qr_imgUrl + 'uploadImages?uuid=' + user.uuid + '&type=7' + '&sign=' + sign"
                        :size="250"
                        :logoSrc="qr_logo"
                        :logoScale="0.2">
                    </vue-qr>
                </div>
            </div>
            <div v-show="imgList.length > 0" style="width: 100%;line-height: 30px;text-align: center;margin-top: 10px;">
                <div>扫描二维码手机上传文件<em @mouseenter="isCodeFile = true"  style="color: #aa7700;">点此添加</em> </div>
                <el-dialog :close-on-click-modal="false" :visible.sync="isCodeFile" width="300px">
                    <vue-qr
                        :text="qr_imgUrl + 'uploadImages?uuid=' + user.uuid + '&type=7' + '&sign=' + sign"
                        :size="250"
                        :logoSrc="qr_logo"
                        :logoScale="0.2">
                    </vue-qr>
                </el-dialog>
            </div>

          <div id="panel" v-show="imgList.length > 0">
            <div id="panelLeft">

              <div class="formItem">
                <div class="label"><em>*</em> 图片标题</div>
                <el-input class="input" v-model="title" placeholder="请输入图片标题"></el-input>
              </div>

              <div id="imgList">
                <div class="imgFile" v-for="(item,index) in imgList" :class="{'active':item === activeImg}">
                  <img v-if="item.url !== ''" :src="item.url" class="op sc hand" @click="activeImg = item" >
                  <div class="progress" v-if="item.url === ''">
                    <el-progress :percentage="item.progress" :show-text="false"></el-progress>
                    <p>{{item.progress}}%</p>
                  </div>
                  <div class="isCoverImage" v-if="item.isCoverImage">封面</div>
                  <div class="describe op sc hand" @click="activeImg = item" v-if="item.describe">{{item.describe}}</div>
                  <div class="remove op sc hand" @click="imgList.splice(index,1)">x</div>
                </div>

              </div>
            </div>
            <div id="panelRight">
              <img id="preViewImg" :src="activeImg.url">
              <el-form>
                <el-form-item label="图片描述">
                  <el-input type="textarea" :rows="5" v-model="activeImg.describe"></el-input>
                </el-form-item>

                <el-form-item label="设为封面">
                  <el-switch v-model="activeImg.isCoverImage" @change="coverImageChange"></el-switch>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>


        <div id="bottom">
          <div class="formItem">
            <div class="label"><em>*</em> 分类</div>
            <el-cascader v-model="types" :options="type_list" placeholder="请选择图片分类"></el-cascader>
          </div>
          <div class="formItem">
            <div class="label"><em>*</em> 权限</div>
            <el-select v-model="auth" placeholder="请选择" @change="autheid">
              <el-option
                  v-for="item in authList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="formItem">
              <div class="label"><em>*</em> 频道</div>
              <el-select v-model="channel_id" :disabled="auth !== 4" placeholder="请选择">
                  <el-option v-for="item in imgTopic" :key="item.id+''" :label="item.name" :value="item.id" ></el-option>
              </el-select>
          </div>

          <div class="formItem">
            <div style="margin: 20px 10px;">
              <el-checkbox v-model="checkedMoney" :disabled="auth != 4">有偿</el-checkbox>
            </div>
            <div style="width: 200px;display: flex;">
              <div style="float: left;width: 90px;margin-top: 6px;">
                <el-input  placeholder="金额"  prefix-icon="el-icon-coin" v-model="payAmt"  :disabled="checkedMoney == false" @input="UpNumber"></el-input>
              </div>
              <div style="float: left;width: 55px;padding-left: 5px;font-size: 12px;color: #C1C1C1;margin-top: 15px;">
                (单位:元)
              </div>
            </div>
          </div>

          <label style="width: 225px;">
            <el-button type="primary" icon="el-icon-position" class="button op sc hand" @click="submit">{{ editId == 0 ? '发布' : '保存'  }}</el-button>
          </label>

        </div>
      </div>

    </div>
  </transition>
</template>

<script>


    export default {
      name: "addImageFileBaseForm",
      props:{
        editId:{
          type:Number,
          default(){
            return 0;
          }
        },
        isShow:{
          type:Boolean,
          default(){
            return false;
          }
        },
        onClose:{
          type:Function,
          default(){

          }
	      }
      },
      data(){
        return{
          title:"",
          content:"",
          activeImg:{},
          type_list:[],
          types:[],
          auth:4,
          loading:false,
          //  频道推荐
          channel_id:'',
          imgTopic:[],
          authList: [{
            value: 1,
            label: '仅自己可见'
          }, {
            value: 2,
            label: '仅好友可见'
          }, {
            value: 3,
            label: '仅粉丝可见'
          }, {
            value: 4,
            label: '所有人可见'
          }],
          checkedMoney:false,
          payAmt:'',
          imgList:[],
          cover:'',
            //扫码上传文件
            isCodeFile:false,
            qr_imgUrl:this.$store.state.mobileUrl,
            qr_logo: require('@/assets/imgs/other/bgb.png'),
            sign:this.utils.random_string(6),
        }

      },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
      mounted() {
          var that = this;
              this.getTypeList();
              // 频道推荐
              this.getChannelList();
          // 图片详情
          if(this.$route.params.id > 0 && typeof this.$route.params.id != 'undefined') {
            this.getKnowledgeInfo();
          }

          //监听扫码上传
          this.$EventBus.$EventBus.$off("callbackNotice");
          this.$EventBus.$on("callbackNotice", (data) => {
              this.$nextTick(() => {
                  if (data.code == 100) {
                        let obj = JSON.parse(data.data);
                        if(obj.type == 1001){
                            that.getCodeFiles(obj);
                        }else if(obj.type == 1002){
                            that.delCodeFiles(obj);
                        }
                  }
              })
          })

      },
      methods: {
        UpNumber() {
          this.payAmt = this.payAmt.replace(/[^0-9]/g, "");
          if(this.payAmt <= 0){
            this.utils.msg("最少1元");
            this.payAmt = 1;
          }
        },
        getChannelList:function(){
          var that = this;
          that.newApi.getChannelList({}).then((ret)=>{
            if(ret.isSuccess == 1){
              var data = [];
              for (let i = 0; i < ret.data.length; i++) {
                if(ret.data[i].classid == 2){
                  this.imgTopic.push(ret.data[i]);
                }
              }
              that.channel_list = data;
            }
          }).catch((err)=>{
            console.log(err)
          })
        },
        getKnowledgeInfo: function () {
          var that = this;
          this.newApi.getKnowledgeInfo({
            id: that.$route.params.id
          }).then((res) => {
            that.data = res.data;
            that.types = JSON.parse(res.data.kwTypeArr);
            that.title = res.data.title;
            that.channel_id = res.data.channelId;
            that.auth = res.data.auth;
            that.imgList = res.data.images;
            for (let i = 0;i < that.imgList.length; i ++){
              if(that.imgList[i].isCoverImage == true){
                that.activeImg = {
                  progress:0,
                  isCoverImage:true,
                  url:that.imgList[i].url,
                  describe:that.imgList[i].describe
                };
              }
            }
          }).catch((err) => {
            console.log(err)
          })
        },
        autheid(){
          if (this.auth != 4){
            this.channel_id = "";
            this.checkedMoney = false;
            this.payAmt = "";
          }
        },
        getTypeList(){
          const that = this;
          that.newApi.getTypeList({
            lv: 3,
          }).then((res)=>{
            that.type_list = res.data;
          }).catch((err)=>{
            console.log(err)
          })
        },

        getCodeFiles(obj){
            var that = this;
            const id = that.utils.random_string();
            that.activeImg = {
                id:id,
                progress:0,
                url:"",
                isCoverImage:this.imgList.length === 0,
                describe:"",
            }
            that.imgList.push(this.activeImg);
            that.cover = that.imgList[0].url;
            //预载一下图片防止加载不丝滑
            const img = new Image();
            img.src = obj.url;
            img.onload = function (){
                that.setUrl(id,obj.url,obj.hash);
            }
        },


          delCodeFiles(obj){
              var that = this;
              for (let i = 0; i < that.imgList.length; i++) {
                  if(that.imgList[i].hash == obj.hash){
                      that.imgList.splice(i,1);
                      that.utils.sus("删除成功");
                      break;
                  }
              }
          },

        uploadImg(file){
          const that = this;
          const id = this.utils.random_string();
          this.activeImg = {
            id:id,
            progress:0,
            url:"",
            isCoverImage:this.imgList.length === 0,
            describe:"",

          }
          this.imgList.push(this.activeImg)
          this.utils.upload(file.raw,function (ret){

            if (!ret){
              return false;
            }
            //预载一下图片防止加载不丝滑
            const img = new Image();
            img.src = ret.url;
            img.onload = function (){
              that.setUrl(id,ret.url,ret.hash);
            }

          },function (p){
            that.setProgress(id,p)
          })
        },
        setUrl(id,url,hash){
                for (let i = 0; i < this.imgList.length; i++) {
                    if (this.imgList[i].id === id){
                        this.imgList[i].url = url;
                        this.imgList[i].hash = hash;
                }
                }
        },
        setProgress(id,progress){
          for (let i = 0; i < this.imgList.length; i++) {
            if (this.imgList[i].id === id){
              this.imgList[i].progress = progress;
            }
          }
        },
        coverImageChange(){
          for (let i = 0; i < this.imgList.length; i++) {
            this.imgList[i].isCoverImage = false;
          }
          this.activeImg.isCoverImage  =true;
        },
        submit(){
          const that = this;
          if (this.imgList.length == 0){
            this.utils.err("请上传至少一张图片");return;
          }

          if (this.title == ""){
            this.utils.err("请填写标题");return;
          }
          if (this.types.length == 0){
            this.utils.err("请选择分类");return;
          }
          if ( this.auth == 4 ){
            if (this.channel_id.length == 0 ){
              this.utils.err("请选择频道推荐");return;
            }
          }

          let coverImageUrl = "";
          for (let i = 0; i < this.imgList.length; i++) {
            if(this.imgList[i].url  === ''){
              this.utils.err("请等待图片上传完成！");return;
            }
            if (this.imgList[i].isCoverImage){
              coverImageUrl = this.imgList[i].url;
            }
          }

          if (coverImageUrl === ""){
            this.utils.err("请选择一张封面图！");return;
          }
          const params = {};
          // 1原创 2转载
          params.form = 1;
          // 标题
          params.title = this.title;
          // 1朋友圈 2暂定
          params.formType = 2;
          // 内容
          params.content = this.title;
          params.enclosure = "[]";
          params.videos = "[]";
          //  封面
          params.cover = coverImageUrl;

          this.imgList.forEach((item,index)=>{
            this.imgList[index] = {
              url:item.url,
              hash:item.hash,
              describe:item.describe,
              name:item.id,
            }
          })
          params.images = JSON.stringify(this.imgList);
          // 是否开启评论
          params.isComment = 1;
          // 是否开启转发
          params.isReprinted = 1;
          // 转载地址
          params.ReprintedAddress = ""
          // 话题
          // params.topicId = this.topicid;
          // 频道
          params.channelId = that.channel_id;
          //所属分类数组
          params.kwTypeArr = JSON.stringify(this.types);
          //类型
          params.type =  0;
          params.auth = this.auth;
          params.bgbType = 7;
          params.spaceSize = 0;
          if (this.editId == 0){
            // 付费图片
            if(that.checkedMoney == true){
              if(that.payAmt == 0 || that.payAmt == ""){
                that.utils.msg("请输入金额");
                return false;
              }else{
                params.payAmt = that.payAmt;
                params.paymentType = 3;
              }
            }
            //发布、
            this.newApi.addknowledge(params).then((ret)=>{
              if(ret.isSuccess == 1){
                that.utils.sus(ret.data);
                that.imgList = [];
                that.title = "";
                that.types = "";
                that.channel_id = "";
                that.onClose();
              }
            })
          }else{
            params.id = this.$route.params.id;
            this.newApi.editKnowledge(params).then((ret)=>{
              if(ret.isSuccess == 1){
                that.utils.sus(ret.data);
                  that.$emit('changeResh');
                that.onClose();
              }
            }).catch((err)=>{
              console.log(err)
            })
          }
        },
      },
}
</script>

<style scoped>
/deep/ .el-checkbox:last-of-type{
  margin-right:0px;
}
</style>

<style lang="scss">
.v-modal{
    display: none;;
}
#addImageFileBaseFormBg{
  position: fixed;left: 0; top: 0;width: 100%;height: 100%;z-index: 50;background: rgba(0,0,0,.6);display: flex;align-items: center;
  #form{
    width: 1220px; height: 858px; background: #FFF; margin: auto; border-radius: 5px;
    #head{
      height: 60px; line-height: 60px; text-align: center;position: relative; color: #444; font-size: 20px;
      #close{
        width: 34px; height: 34px; border-radius: 50%; background-color: #E4E6EB; position: absolute; right: 30px;top: 14px;
        line-height: 34px; text-align: center; color: #707070;
      }
      border-bottom: 1px solid #CECFD0;
    }


    #center{
      height: 652px;
      display: flex;flex-direction:column;
      padding: 20px 30px;

      -webkit-user-drag: none;
      user-select: none;

      #panel{
        overflow: hidden;
        position: relative;
        margin-top: 26px;
        height: 515px;

        #panelLeft{

          height: 100%;
          float: left; width: 728px; overflow-y: scroll;

          .formItem{
            margin-bottom: 10px;
            .input{
              width: 586px;
            }
          }

          #imgList{

            .progress{
              padding: 20px;
              width: 119px; height: 131px;
              background: #F1F1F1;
              display: flex; flex-direction: column; justify-content: center;
              p{
                text-align: center;color: #999; font-size: 12px; margin-top: 10px;
              }
            }
            .imgFile.active{
              border:2px solid #2970FF;
            }
            .imgFile{
              width: 159px; height: 171px;
              float: left; margin: 7px; border-radius: 5px;
              border: 2px solid #CECFD0;position: relative;
              .remove{
                position: absolute;
                top: -5px;right: -5px; text-align: center; border-radius: 50%;
                background: #2970FF;
                width: 26px; height: 26px; line-height: 26px;
                color: #FFF;
                z-index: 4;
              }
              .isCoverImage{
                position: absolute; background: #031F88; text-align: center;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 5px; font-size: 14px;
                top: 0;left: 0; width: 56px; height: 24px; line-height: 24px; color: #FFF;
                z-index: 3;
              }
              .describe{
                position: absolute;left: 0;top: 0;
                width: 100%; height: 100%; line-height: 25px;
                background: rgba(0,0,0,.3); color: #FFF; text-align: center;
                z-index: 2; word-break: break-all; overflow: hidden;
              }
              img{
                width: 100%;border-radius: 5px;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        #panelRight{
          padding: 0 10px;
          position: absolute; right: 0;
          width: 404px; height: 100%;
          #preViewImg{
            width: 100%;height: 200px;border-radius: 5px;
            object-fit: cover;
          }
        }
      }

      .upload{
        flex: 1;
        width: 100%;
        height:calc( 100% - 2px ) ;
        .el-upload {
          width: 100%;
          height: 100%;
          .el-upload-dragger{
            background: #F7F9FA !important;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .el-upload__text{
              color:#333;font-size: 18px;
              em{
                color:#F98B15
              }
            }
            p{
              color: #999; font-size: 16px; margin-top: 16px;
            }
          }

        }
      }
    }


    #bottom{
      height: 104px;  border-top: 1px solid #CECFD0!important;
      display: flex; align-items: center;
      .formItem{
        margin-left: 30px;
      }
      .button{
        width: 130px; margin-left: 20px; background: #2970FF; font-size: 16px; height: 40px; line-height: 10px;
      }
    }
  }



  .formItem{
    overflow: hidden; display: flex;align-items: center;height: 50px;
    .label{
      width: 60px;height: 40px;line-height: 40px;
      font-size: 14px;
      em{
        color:#FF0000;
      }
    }
    .input{
      width: 50px;
    }
  }

}


</style>
