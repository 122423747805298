<template>
    <div  style="width: 350px;height: 300px;padding: 5px;">
        <div v-show="user.realNameStatus == 2 && options.length > 0">
            <div v-if="user.bgbPayPwd == 1">
              <div style="width: 100%;margin: 0 auto;line-height: 30px;margin-bottom: 15px;text-align: center;font-size: 16px;color: red;">支付金额:{{payAmt}}(元)</div>
                <div style="margin-bottom: 10px;">
                    <div style="width: 60px;float: left;margin-top: 10px;font-size: 15px;"><span style="color: red">*</span>银行卡</div>
                    <template>
                        <el-select style="width: 285px;margin-left: 5px" v-model="tokenNo" placeholder="请选择银行卡">
                            <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.bankName"
                                    :value="item.tokenNo">
                            </el-option>
                        </el-select>
                    </template>
                </div>

                <div style="width: 350px;height: 40px;">
                    <div style="width: 60px;float: left;margin-top: 10px;font-size: 15px;"><span style="color: red">*</span>验证码</div>
                    <div style="width: 185px;float: left;height: 40px;margin-left: 5px">
                        <el-input name="code" v-model="code"  placeholder="短信验证码" > </el-input>
                    </div>
                    <div style="width: 100px;float: left;font-size: 10px;line-height: 25px;text-align: center;">
                        <el-button  style="color: #FFFFFF;border: 1px solid #409EFF;background-color: #409EFF;width: 100px;padding: 12px 15px;" @click="getCode" :disabled="disabled">{{ codeString }}</el-button>
                    </div>
                </div>

                <div style="width: 350px;height: 40px;margin-top: 10px;">
                    <div style="width: 70px;float: left;margin-top: 10px;font-size: 15px;"><span style="color: red">*</span>填密码</div>
                    <div style="float: left; width: 285px;height: 40px;margin-left: -5px;">
                        <el-input show-password v-model="payPwd" placeholder="请输入支付密码"></el-input>
                    </div>
                </div>
                <div style="width: 350px;height: 25px;font-size: 12px;color: #26C6FD;margin-top: 5px;">
                    <div style="width: 50%;line-height: 30px;float: left;text-align: left;">
                        <el-link type="primary">什么是支付密码</el-link>
                    </div>
                    <div style="width: 50%;line-height: 25px;float: left;text-align: right;">
                        <el-link type="danger" @click="forgotPassword">忘记密码？</el-link>
                    </div>
                </div>

                <div v-if="payType > 4 || type == 1" style="width: 180px;height: 52px;background: #EE3F4D;float: left;margin:20px 25%" @click="toPay">
                    <div style="width: 80px;height: 24px;font-size: 20px;color: #FFFFFF;margin:14px 0 0 50px;cursor: pointer">立即支付</div>
                </div>
            </div>
            <div v-if="user.bgbPayPwd == 0">
                <div v-show="setupnow == false" class="authentication-reminder">
                    <div class="reminder-content">
                        <p>暂无设置支付密码，请前往设置</p>
                        <p>点击下面按钮进行设置支付密码：</p>
                        <button style="margin-top: 10px;" @click="setupnow = true">点击设置</button>
                    </div>
                </div>
                <setPwd v-show="setupnow == true"  @change="closeSetPwd($event)"></setPwd>
            </div>
        </div>
        <div v-show="user.realNameStatus == 2 && options.length == 0">
            <div class="authentication-reminder">
                <div class="reminder-content">
                    <p>暂未绑定银行卡</p>
                    <p>点击下面按钮进行绑卡操作：</p>
                    <button style="margin-top: 10px;" @click="bindCard">前往绑定</button>
                </div>
            </div>
        </div>
        <div v-if="user.realNameStatus == 0">
            <div class="authentication-reminder">
                <div class="reminder-content">
                    <p>暂无实名认证，请前往认证</p>
                    <p>点击下面按钮进行实名认证：</p>
                    <button style="margin-top: 10px;" @click="redirectToAuthentication">前往认证</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import setPwd from "./setPwd"
    export default {
        name: "AaDaPayCode",
        components:{
            setPwd
        },
        computed:{
            user() {
                return this.$store.state.user;
            },
        },
        // 在 Vue 组件中使用 $watch 监听 Vuex 数据的变化
        watch: {
            user() {}
        },
        data(){
            return{
                codeString: '获取验证码',
                code:"",
                time: 60,
                disabled:false,
                options: [1],
                tokenNo: '',
                payPwd:'',
                show:false,
                bgbPayPwd:0,
                setupnow:false
            }
        },
        props:{
            //类型参数
            type:{
                type:String,   //type = 1支付 2实时转账 3延时分账  必填
                default() {
                    return ""
                }
            },

            memberId:{
                type: String,  //商户下的用户id 唯一 必填
                default() {
                    return ""
                }
            },
            payType:{
                payType:String,   //支付类型1红包问答2悬赏资料3悬赏讨论4必选竞标5转账 type = 2 || 3时必填
                default() {
                    return ""
                }
            },
            OrderType:{
                OrderType:String,   //订单类型1购买会员2续费会员3购买空间4续费空间 type = 1 时必填
                default() {
                    return ""
                }
            },
            //支付参数
            payAmt:{
                payAmt:String,   //交易金额，必须大于0 必填
                default() {
                    return ""
                }
            },
            orderNo:{
                orderNo:String,   //订单号 type = 2 || type = 3 时(payType = 5除外) 必填
                default() {
                    return ""
                }
            },
            collectionUuid:{
                collectionUuid:String,   //收款方的uuid type = 3 时 必填
                default() {
                    return ""
                }
            },

        },
        mounted() {
            var that = this;
            this.$EventBus.$on("payMsg", () => {
                this.$nextTick(() => {
                    that.code = "";
                    that.time = 0;
                    that.payPwd = '';
                });
            });

            if(that.user.bgbPayPwd == 0){
                that.show = true;
            }

            if(that.type == ""){
                that.utils.err("类型错误");
                return false;
            }

            if(that.payAmt == "" || that.payAmt == 0){
                that.utils.err("金额错误");
                return false;
            }
            //支付
            if(parseInt(that.type) == 1){
                if(that.OrderType == ""){
                    that.utils.err("支付类型参数错误");
                    return false;
                }
            }
            //实时转账
            if(parseInt(that.type) == 2){
                if(that.memberId == null || that.memberId == ""){
                    that.utils.err("转账类型参数错误");
                    return false;
                }
            }
            //延时分账
            if(parseInt(that.type) == 3){
                let arr = [1, 2, 3, 4, 5, 10,12,13];
                if (arr.includes(parseInt(that.payType))) {
                    if( that.orderNo == ""){
                        that.utils.err("分账类型参数错误");
                        return false;
                    }
                } else {
                    if( that.orderNo == "" || that.collectionUuid == ""){
                        that.utils.err("分账类型参数错误");
                        return false;
                    }
                }
            }

            that.getAdaPayBanCardList();
        },
        methods:{
            //绑卡跳转
            bindCard(){
                let relativePath = this.$route.path;
                if(relativePath.includes("/user/money")){
                    // 发送钱包广播事件
                    this.$EventBus.$emit('moneyEvent',{name:1,link:4});
                }else{
                    this.b("/user/money/6")
                }
            },

            //忘记密码
            forgotPassword(){
                let relativePath = this.$route.path;
                if(relativePath.includes("/user/money")){
                    // 发送钱包广播事件
                    this.$EventBus.$emit('moneyEvent',{name:1,link:2});
                }else{
                    this.b("/user/money/2")
                }
            },

            //实名热着呢跳转
            redirectToAuthentication() {
                // 模拟跳转链接，你可以替换成实际的认证页面链接
                window.location.href = '/circle/mySpace/'+this.user.uuid
            },

            //查询快捷卡对象列表
            getAdaPayBanCardList(){
                var that = this;
                var params = {};
                that.newApi.getAdaPayBanCardList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.options = res.data;
                        console.log(that.options);
                        if(that.options.length==0){
                            if(that.user.realNameStatus == 2){
                                that.utils.msg("未绑定银行卡");
                            }
                        }
                        for (var i=0; i < that.options.length; i++){
                            if(that.options[i].settleAccountId != ""){
                                that.tokenNo = that.options[i].tokenNo;
                            }else{
                                that.tokenNo = that.options[0].tokenNo;
                            }
                        }
                    }
                })
            },

            //点击获取验证码
            getCode(){
                var that = this;
                var obj = {};
                obj.payAmt = that.utils.keepTwoDecimalFull(that.payAmt);
                obj.payType = that.payType;
                obj.tokenNo = that.tokenNo;
                if( obj.tokenNo == ''){
                    this.$message.error("请绑定银行卡")
                    return;
                }
                if(parseInt(that.type) == 1){
                    obj.payType = that.OrderType;
                    that.alpplyBankCardPay(obj);
                }else if(parseInt(that.type) == 2){
                    obj.memberId = that.memberId;
                    obj.orderNo = that.orderNo;
                    that.settleAccountTransfer(obj);
                }else if(parseInt(that.type) == 3){
                    obj.orderNo = that.orderNo;
                    obj.collectionUuid = that.collectionUuid;
                    that.settleAccountPenny(obj);
                }

            },

            //获取验证码倒计时
            countdown(){
                var that = this;
                if(that.time == 0) {
                    that.disabled = false;
                    that.codeString ='获取验证码';
                    that.time  = 60;
                    return;
                } else {
                    that.disabled = true;
                    that.codeString = that.time + '秒重新发送';
                    that.time --;
                }
                setTimeout(function(){
                    that.countdown();
                }, 1000)
            },

            //创建支付对象申请 返回订单
            alpplyBankCardPay(params) {
                var that = this;
                that.newApi.alpplyBankCardPay(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("请注意查收验证码");
                        // 触发父组件的 change 事件绑定的方法
                        that.$emit('change', res.data);
                        //倒计时
                        that.countdown();
                    }
                })
            },
            //创建实时转账对象申请 返回订单
            settleAccountTransfer(params) {
                var that = this;
                that.newApi.settleAccountTransfer(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("请注意查收验证码");
                        this.$emit('change', res.data);
                        that.countdown();
                    }
                })
            },
            //创建分账转账对象申请 返回订单
            settleAccountPenny(params) {
                var that = this;
                that.newApi.settleAccountPenny(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("请注意查收验证码");
                        this.$emit('change', res.data);
                        that.countdown();
                    }
                })
            },

            //父组件调用此方法获取验证码
            childMethods() {
                var obj = {};
                obj.code = this.code;
                obj.payPwd = this.payPwd;
                return obj;
            },

            //子组件传过来的密码 此方法在父页面必写
            closeSetPwd(bl){
                var that = this;
                that.setupnow = bl;
                this.$store.commit("get_user_info");
            },

            //立即支付
            toPay(){
                var data = {code:100,payType:this.payType};
                this.$EventBus.$emit('toPay',data);
            }

        }
    }

</script>

<style scoped>
    .authentication-reminder {
        background-color: #ffe58f;
        padding: 15px;
        border: 1px solid #ffd666;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        max-width: 400px;
        margin: 0 auto;
    }

    .reminder-content {
        text-align: center;
    }

    button {
        padding: 10px 20px;
        background-color: #409eff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #66b1ff;
    }
</style>