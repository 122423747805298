import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
import axios from 'axios'
import Vue from 'vue';
import store from "@/store";
//默认为false，后端每次请求的时候session都会变化，所以需要设置为true，ajax请求的时候会带上cookie
// axios.defaults.withCredentials=true;
/**
     axios.create(config) 对axios请求进行二次封装
     根据指定配置创建一个新的 axios ,也就是每个axios 都有自己的配置
     新的 axios 只是没有 取消请求 和 批量请求 的方法，其它所有语法都是一致的
     为什么要这种语法？
     需求，项目中有部分接口需要的配置与另一部分接口的配置不太一样
     解决：创建2个新的 axios ，每个都有自己的配置，分别对应不同要求的接口请求中
* */

const instance = axios.create({
    timeout:20000,
    withCredentials:false,//不要在修改了，要不然websocket一直会进行重新连接
    headers: {
        'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8',
        'Token-Type':1
    },
});

/**
 * axios的处理链流程 拦截器
*/
// Add a request interceptor 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = localStorage.token;

    if(token){
        config.headers.Authorization = "Bearer "+token;
    }
    return config;
}, function (error) {
    // Do something with request error 请求失败拦截
    return Promise.reject(error);
});

// Add a response interceptor 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if(response.data.isSuccess == 0){
        Vue.prototype.msg(response.data.data);
    }
    setTimeout(function (){Vue.prototype.closeLoading()},1000);
    return response.data;
}, function (error) {
    setTimeout(function (){Vue.prototype.closeLoading()},1000);
    // 提取错误状态码
    const status = error.response ? error.response.status : 0;
    // 获取请求的接口名称
    const requestUrl  = error.config.url;

    //判断是否请求超时
    if (error.code === 'ECONNABORTED') {
        // 特定环境的超时处理
        console.log("请求超时，请稍后再试" + requestUrl);
    }else{
        //接口请求错误提示
        if (status === 401) {
            store.state.user = {};
            store.state.is_login = 0;
            Vue.prototype.msg("账号信息已失效,请重新登陆");
        }else if (status === 500) {
            console.log("服务器繁忙，请稍后再试" + requestUrl);
        }else if (status === 404) {
            console.log("接口不存在" + requestUrl);
        }else{
            Vue.prototype.msg("未知错误");
            console.log('Error', error);
            }
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
});

export default instance
