<template>
	<div id="room_left" style="height:100%;width:284px;float: left;position: fixed; top:108px;padding-left: calc((100% - 1500px)/ 2);">
    <div>
      <div class="shadow hand op" @click="createRoom" style="height:auto;background-color: #FFFFFF;position: relative;">
        <div style="position:absolute;top: 0;left: 0;width: 0px;height: 0px;border: 14px solid #fff;border-top-color: #031F88;border-left-color: #031F88;border-bottom-color:transparent ;border-right-color: transparent;"></div>
        <div style="display: flex;padding: 5px 0; justify-content: center">
          <div>
            <img src="../imgs/addIcon.png" style="width: 26px;height: 26px;"/>
          </div>
          <div style="line-height: 25px; margin:2px 10px;font-weight: bold;font-size: 20px;box-sizing:border-box;color: #031F88;" >
            创建ROOM
          </div>
          <i style="font-size: 20px; margin-top: 6px; color: #031F88" class="el-icon-caret-right"></i>
        </div>
      </div>

      <div class="shadow" style="height:auto;background-color: #FFFFFF;overflow:hidden; margin-top:10px;">
        <div style="display: flex; flex-direction:column;justify-content: center;align-items: center;">
          <div class="hand op sc nav-title" style="margin-top: 10px" :style="{'background' : type == 'new' ?  '#E8E8E8': '#ffffff'}">
            <div class="icon" style="width: 18px;float:left;height: 18px;line-height: 18px;margin: 0 18px 0 40px;">
              <img src="../imgs/img_1.png" style="width: 100%;height: 100%;"/>
            </div>
            <div style="flex: 1; font-weight: bold;float:left;height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;" @click="toChange('new')">
              最新动态
            </div>
          </div>

          <div class="hand op sc nav-title" :style="{'background' : type == 'work' ?  '#E8E8E8': '#ffffff'}">
            <div style="width: 18px;float:left;height: 18px;line-height: 18px;margin: 0 18px 0 40px;">
              <img src="../imgs/gzl.png" style="width: 100%;height: 100%;"/>
            </div>
            <div style="flex: 1; font-weight: bold;float:left;height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;" @click="toChange('work')">
              审批流
              <el-badge :value="messageNum.myExamineStartNum + messageNum.joinExamineStartNum" style="float: right; margin: 5px 20px 0 0" v-if="messageNum.myExamineStartNum + messageNum.joinExamineStartNum > 0"></el-badge>
            </div>
          </div>

          <div class="hand op sc nav-title"  :style="{'background' : type == 'all' ?  '#E8E8E8': '#ffffff'}">
            <div style="width: 18px;float:left;height: 18px;line-height: 18px;margin: 0 18px 0 40px;">
              <img src="../imgs/room.png" style="width: 100%;height: 100%;"/>
            </div>
            <div style="flex: 1; font-weight: bold;float:left;height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;" @click="toChange('all')">
              ROOM广场
            </div>
          </div>
          <div class="hand op sc nav-title" :style="{'background' : type == 'task' ?  '#E8E8E8': '#ffffff'}">
            <div style="width: 18px;float:left;height: 18px;line-height: 18px;margin: 0 18px 0 40px;">
              <img src="../imgs/task.png" style="width: 100%;height: 100%;"/>
            </div>
            <div style="flex: 1; font-weight: bold;float:left;height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;" @click="toChange('task')">
              任务序列
            </div>
          </div>
          <div class="hand op sc nav-title" :style="{'background' : type == 'notice' ?  '#E8E8E8': '#ffffff'}">
            <div style="width: 18px;float:left;height: 18px;line-height: 18px;margin: 0 18px 0 40px;">
              <img src="../imgs/notices.png" style="width: 100%;height: 100%;"/>
            </div>
            <div style="flex: 1; font-weight: bold;float:left;height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;" @click="toChange('notice')">
              通知消息
              <el-badge :value="messageNum.applyJoinNoticeNum + messageNum.roomInviteNoticeNum + messageNum.roomNoticeNum" style="float: right; margin: 5px 20px 0 0" v-if="messageNum.applyJoinNoticeNum + messageNum.roomInviteNoticeNum + messageNum.roomNoticeNum > 0"></el-badge>
            </div>
          </div>
          <div class="hand op sc nav-title" :style="{'background' : type == 'myRoom' ?  '#E8E8E8': '#ffffff'}">
            <div style="width: 18px;float:left;height: 18px;line-height: 18px;margin: 0 18px 0 40px;">
              <img src="../imgs/myroom.png" style="width: 100%;height: 100%;"/>
            </div>
            <div style="flex: 1; font-weight: bold;float:left;height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;" @click="toChange('myRoom')">
              我的ROOM
            </div>
          </div>
          <div class="hand op sc nav-title" style="margin-bottom: 10px" :style="{'background' : type == 'folder' ?  '#E8E8E8': '#ffffff'}">
            <div style="width: 18px;float:left;height: 18px;line-height: 18px;margin: 0 18px 0 40px;">
              <img src="../imgs/folder.png" style="width: 100%;height: 100%;"/>
            </div>
            <div style="flex: 1; font-weight: bold;float:left;height: 40px;line-height: 40px;overflow: hidden;font-size: 16px;" @click="toChange('folder')">
              ROOM文件夹
            </div>
          </div>
          <!-- 		        <div class="hand op sc" style="width:76%;margin: 12px 0 18px 0; height: 39px;border-radius: 50px;border: 2px solid #FF9800;text-align: center;line-height: 39px;color: #FF9800;font-size: 16px;font-weight: 600;" @click="utils.a('/room/add')">
                    +&nbsp;&nbsp;&nbsp;&nbsp;新建room
                      </div> -->
        </div>
      </div>

      <div class="shadow" style="width:100%;overflow:hidden; height:auto;background-color: #FFFFFF; margin: 4px 0" v-if="is_login != 0">
        <div style="width:100%; height: 42px;line-height:40px;font-weight: bold;overflow: hidden;" >
          <div style="margin-left:2px;text-align: center;width: calc(100% /5 - 6px);margin-right: 4px;float: left;box-sizing: border-box;letter-spacing: 2px;cursor: pointer;" :style="roomType===0?'color:#031F88;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);transform: scale(1.1)':''" @click = screenRoomList(0)>全部</div>
          <div style="border-bottom:2px solid #2970FF; text-align: center;width: calc(100%/5 - 4px);margin-right: 4px;float: left;box-sizing: border-box;letter-spacing: 2px;padding-left: 2px;cursor: pointer;" :style="roomType===1?'color:#031F88;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);transform: scale(1.1)':''" @click = screenRoomList(1)>项目</div>
          <div style="border-bottom:2px solid #FF9800;text-align: center;width: calc(100%/5 - 4px);margin-right: 4px;float: left;box-sizing: border-box;letter-spacing: 2px;padding-left: 2px;cursor: pointer;" :style="roomType===2?'color:#031F88;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);transform: scale(1.1)':''" @click = screenRoomList(2)>兴趣</div>
          <div style="border-bottom:2px solid #69BF29;text-align: center;width: calc(100%/5 - 4px);margin-right: 4px;float: left;box-sizing: border-box;letter-spacing: 2px;padding-left: 2px;cursor: pointer;" :style="roomType===4?'color:#031F88;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);transform: scale(1.1)':''" @click = screenRoomList(4)>经营</div>
          <div style="border-bottom:2px solid #031F88;margin-left:2px;text-align: center;width: calc(100%/5 - 2px);float: left;box-sizing: border-box;letter-spacing: 2px;padding-right: 5px;cursor: pointer;" :style="roomType===3?'color:#031F88;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);transform: scale(1.1)':''" @click = screenRoomList(3)>机构</div>
        </div>
      </div>

      <div class="shadow" style="width:100%;overflow:hidden;height:auto;background-color: #FFFFFF;" v-if="is_login != 0">
        <div style="margin: 10px">
          <el-autocomplete
              style="display:inline"
              value-key="name"
              v-model="searchContent"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入room名称"
              @select="SelectRoom"
              :highlight-first-item="true"
              :trigger-on-focus="false"
              :clearable = "true"
          ></el-autocomplete>
        </div>
        <div style="width:calc( 100% + 12px);height:400px; overflow-y: scroll;overflow-x: hidden; margin-top: 10px">
          <div v-if="roomType===0">
            <div style="overflow:hidden;padding:0 16px;" v-for="(item,index) in folderList" v-bind:key="index" class="hand op">
              <Tree :data = "item" :id="id"></Tree>
            </div>
          </div>
          <div style="width: 100%;height: 330px;" @dragover.prevent @drop="handleDrap()">
            <div v-for="(item,index) in my_room_list"  v-bind:key="index" @click="click_room(item.id)" :draggable=drag @dragstart="handleDrapStart($event, item)"  @dragend="dragend">
              <div class="hand op" :class="item.id == id ? 'click-change' : 'no-change'">
                <div style="width: 30px;float:left;height: 30px;line-height: 30px;margin-top: 5px;margin-right:  10px;">
                  <img :src="item.cover+'-80x80'" style="width: 100%;height: 100%;border-radius: 20px;"/>
                </div>
                <el-tooltip  effect="light" :content=" item.name "  placement="right">
                  <div class="room_name" :style="{color: typeList.find(e => e.value == item.roomType).color}" style="width: calc(100% - 40px);float:left;height: 40px;overflow: hidden;font: 14px Microsoft YaHei;line-height: 40px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" >{{item.name}}</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

	</div>
</template>

<script>
	import Tree from './Tree'
	export default {
		name:"RoomNav",
		components: {
			Tree
		},
		data () {
			return {
        type: 'new',
        typeList: [{
          value: 1,
          color: '#2970FF'
        },
          {
            value: 2,
            color: '#FF9800'
          },
          {
            value: 3,
            color: '#031F88'
          },
          {
            value: 4,
            color: '#69BF29'}
        ],
        id: 0,
				roomType:0,
				my_room_list:[],
				folderList:[],
				folderId: 0,
				messageNum: {},

                searchContent: '',  // 搜索
			}
		},
		props: {
			drag: {
				type: Boolean,
				default() {
					return true;
				}
			},
		},
		computed: {
            is_login(){
                return this.$store.state.is_login
            },
            user(){
                return this.$store.state.user
            }
        },
    watch: {
      '$route': {
        handler(newData) {
          this.id = Number(newData.params.id);
        }
      }
    },
		created:function(){
      if (this.is_login) {
        var that = this;
        this.screenRoomList(this.roomType);
        this.$EventBus.$on('my_room_list_remove0', (id) => {
          this.my_room_list = this.my_room_list.filter(item => item.id !== id);
        })
        this.$EventBus.$on('my_room_list_add0', (roomInfo) => {
          if (that.my_room_list.some(item => item.id === roomInfo.id)) {
            return false;
          }
          that.my_room_list.push(roomInfo);
        })
        if (this.$route.params.type) {
          this.type = this.$route.params.type
        }
      } else {
        this.type = 'all'
        this.$emit('changeType', 'all')
      }

		},
		mounted: function () {
			// this.getFolderList();
			// this.getUnReadMessage();
      if (this.is_login) {
        this.$nextTick(() => {
          this.$EventBus.$on("changeMessage", () => {
            this.getUnReadMessage()
          })
        });
        // this.get_folder_list();
        this.getUnReadMessage();
        if (this.$route.params.id && this.$route.path.includes('/room/info/')) {
          this.id = Number(this.$route.params.id);
          this.type = ''
        }
      } else {
        this.type = 'all'
        this.$emit('changeType', 'all')
      }

		},
		methods:{
      // 导航跳转
      toChange(e) {
        if (e !== 'all') {
          if (!this.utils.toAuth(0)) {
            return false
          }
        }
        if (this.$route.path == '/room/index') {
          this.type = e;
          if (e === 'task') {
            this.utils.a('/room/task')
          } else {
            this.$emit('changeType', e)
          }
        } else {
          this.$router.push({path: '/room/index/', query: {type: e}})
          this.type = e;
          if (e === 'task') {
            this.utils.a('/room/task')
          } else {
            this.$emit('changeType', e)
          }
        }
      },

			//搜索room
			querySearchAsync(searchContent, cb) {
				this.newApi.getMyDutyRoomList({name:searchContent,roomType:this.roomType}).then(res=>{
					cb(res.data);
				})

			},
			SelectRoom(item){
				this.$router.push({ path: '/room/info/'+item.id})
			},
            // 创建Room
            createRoom() {
              if (!this.utils.toAuth(1)) {
                return false;
              }
                    this.utils.a('/room/add/')
            },

			// 开始进行拖拽
			handleDrapStart(event, value) {
				value.folderId = 0;
				event.dataTransfer.setData("roomInfo", JSON.stringify(value));

			},
			handleDrap() {
				let that = this;
				let roomInfo = JSON.parse(event.dataTransfer.getData("roomInfo"));
				if (roomInfo){
					if (that.my_room_list.some(item => item.id === roomInfo.id)) {
						// that.utils.err("当前列表已有此room");
						return false;
					}
					that.newApi.delFolderRoom({id:roomInfo.id}).then((res)=>{
						if (res.isSuccess==1){
							that.my_room_list.push(roomInfo);
							that.$EventBus.$emit("my_room_list_remove"+roomInfo.folderId, roomInfo.id);
							that.$EventBus.$emit("my_folder_room_list_add", roomInfo);
						}
					})
				}
			},
			// 拖拽完成把数据清除一下
			dragend(){
				event.dataTransfer.clearData();
			},
			reloadAllRoomList(fid){
				var that = this;
				that.newApi.getFolderAndRoomList({"fid":fid}).then(ret =>{
					that.my_room_list = ret.data.roomList;
					that.folderList = ret.data.folderList
				})
			},
			closeMask(){
				this.$parent.closeMask();
			},
			// 获取未读消息
			getUnReadMessage() {
				this.newApi.getRoomNotice().then(res => {
					this.messageNum = res.data
				})
			},
			// 获取room列表
			get_my_room_list:function(){
	            var that = this;
				that.newApi.getMyDutyRoomList({type: 0, roomType: this.roomType}).then(ret =>{
					that.my_room_list = ret.data;
	            })
	        },
			screenRoomList(roomType) {
				this.roomType = roomType;
				this.my_room_list = [];
				if (roomType==0){
					this.reloadAllRoomList(0);
				}else {
					this.get_my_room_list()
				}
			},
		    click_room:function (id){
			    this.$router.push({ path: '/room/info/'+id})

		    },
	        Workflow:function(){
				if(this.is_login == 0){
					this.$EventBus.$emit("need_login", {need_login: true});
					return;
				}
		        this.$router.push({ path: '/room/work'})
			},

			// get_folder_list:function(){
			// 	var that = this;
			// 	this.api.getFolderList().then(ret =>{
			// 		that.folder_list = ret.data;
			// 	})
			// },
		}
	}

</script>

<style scoped>
	#room_left .shadow{
		box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
	}

    #room_left .nav-title {
        border-radius: 5px;
        display: flex;
        align-items: center;
        width:94%;
        height: 40px;
    }

	.click-change {
		width: calc(100% - 32px);
		height: 40px;
		margin:0 12px;
		padding: 0 0 0 8px;
		background-color: #E8E8E8;
	}

	.no-change {
		width: calc(100% - 32px);
		height: 40px;
		margin:0 12px;
		padding: 0 0 0 8px;
	}

  /deep/ .el-input__inner {
    border-radius: 20px !important;
    padding: 0 25px;
    height: 35px;
    line-height: 35px;
  }
</style>
