import { render, staticRenderFns } from "./fancyEditTable.vue?vue&type=template&id=53a17874&scoped=true"
import script from "./fancyEditTable.vue?vue&type=script&lang=js"
export * from "./fancyEditTable.vue?vue&type=script&lang=js"
import style0 from "./fancyEditTable.vue?vue&type=style&index=0&id=53a17874&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a17874",
  null
  
)

export default component.exports