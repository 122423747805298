<template>
	<div :style="{height:heightPx+'px'}" style="width: 100%">
	</div>
</template>

<script>
export default {
	name: "space",
	props: {
		heightPx: {
			type: Number,
			default: 300
		}
	}
}
</script>

<style scoped>

</style>