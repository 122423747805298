/**
 * 警告
 */
export let space = {
    id: '',
    _id: '',
    url: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png',
    compType: 'space',
    ele: 'space',
    compName: '空白占位',
    compIcon: 'space',
    //展示表单的模式
    viewType: 'component',
    config: true,

    form: true, //是否表单组件
    // 控件左侧label内容
    showLabel: false,
    labelWidth: '0',
    label: '空白占位',
    span: 24,
    value: 'space',
    lineColor: '#000',
    background: '#fff',
    width: 5,
    height: 30,
    fontSize: 12,
    displayValue: true
}
