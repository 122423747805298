import axiosUtils from './axiosUtils.js'
import store from '../store/index'

const newApi = {
    data(data){
        const params = new URLSearchParams();
        for (const sProp in data) {
            params.append(sProp,data[sProp]);
        }
        return params;
    },
    /**
     * 发送短信验证码
     * @param param
     */
    sendSms(param){
        return axiosUtils.post(store.state.devApiUrl + "/sendSms", this.data(param));
    },
    /**
     * 用户注册
     * @param param
     */
    userRegister(param){
        return axiosUtils.post(store.state.devApiUrl + "/userRegister", this.data(param));
    },
    /**
     * 获取微信openID
     * @param param
     */
    // getWxOpenid(code){
    //     let wxUrl = "sns/oauth2/access_token?appid="+AppId+"&secret="+AppSecret+"&code="+code+"&grant_type=authorization_code"
    //     return axiosUtils.get("/api/" + wxUrl, this.data({}));
    // },
    /**
     * 获取微信用户头像、昵称
     * @param param
     */
    getWxUserinfo(token,openid){
        let wxUrl = "sns/userinfo?access_token="+token+"&openid="+openid;
        return axiosUtils.get("/api/" + wxUrl, this.data({}));
    },
    /**
     * 账号密码登录
     * @param param
     */
    getLoginAccount(param){
        return axiosUtils.post(store.state.devApiUrl + "/getLoginAccount", this.data(param));
    },
    /**
     * 微信扫码登录
     * @param param
     */
    getLoginWxCode(param){
        return axiosUtils.post(store.state.devApiUrl + "/getLoginWxCode", this.data(param));
    },
    /**
     * 短信验证码登录
     * @param param
     */
    getLoginCode(param){
        return axiosUtils.post(store.state.devApiUrl + "/getLoginCode", this.data(param));
    },
    /**
     * 点击绑定微信
     * @param param
     */
    getClickBindWxOpenid(param){
        return axiosUtils.post(store.state.devApiUrl + "/getClickBindWxOpenid", this.data(param));
    },
    /**
     * 查询是否有未支付的微信订单
     * @param param
     */
    checkWxPayCode(param){
        return axiosUtils.post(store.state.devApiUrl + "/checkWxPayCode", this.data(param));
    },
    /**
     * 微信扫码支付
     * @param param
     */
    getWxPayCode(param){
        return axiosUtils.post(store.state.devApiUrl + "/getWxPayCode", this.data(param));
    },
    /**
     * 微信订单关闭
     * @param param
     */
    closeWxOrder(param){
        return axiosUtils.post(store.state.devApiUrl + "/closeWxOrder", this.data(param));
    },
    /**
     * 支付宝订单未支付订单
     * @param param
     */
    checkAliPayOrderNotpaidInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/checkAliPayOrderNotpaidInfo", this.data(param));
    },
    /**
     * 支付宝订单列表查询
     * @param param
     */
    getAliPayOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getAliPayOrderList", this.data(param));
    },
    /**
     * 支付宝扫码支付
     * @param param
     */
    getAliPayCode(param){
        return axiosUtils.post(store.state.devApiUrl + "/getAliPayCode", this.data(param));
    },
    /**
     * 支付宝订单取消
     * @param param
     */
    closeAliPayCode(param){
        return axiosUtils.post(store.state.devApiUrl + "/closeAliPayCode", this.data(param));
    },
    userReadNameAuth(param) {
        return axiosUtils.post(store.state.devApiUrl + "/userReadNameAuth", this.data(param));
    },
    userMajorAuth(param){
        return axiosUtils.post(store.state.devApiUrl + "/userMajorAuth", this.data(param));
    },
    userExpertAuth(param){
        return axiosUtils.post(store.state.devApiUrl + "/userExpertAuth", this.data(param));
    },
    getUserMajorAuth(param){
        return axiosUtils.post(store.state.devApiUrl + "/getUserMajorAuth", this.data(param));
    },
    getUserExpertAuth(param){
        return axiosUtils.post(store.state.devApiUrl + "/getUserExpertAuth", this.data(param));
    },
    /**
     * 更新用户昵称
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveUserNickName(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveUserNickName", this.data(param));
    },
    /**
     * 获取用户个人简介
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserBasicInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserBasicInfo", this.data(param));
    },
    /**
     * 更新用户签名
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    userExplainInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/userExplainInfo", this.data(param));
    },
    /**
     * 更新行业信息
     * @param param
     */
    userIndustryType(param) {
        return axiosUtils.post(store.state.devApiUrl + "/userIndustryType", this.data(param));
    },
    /**
     * 更新业务技能
     * @param param
     */
    userBusinessSkills(param) {
        return axiosUtils.post(store.state.devApiUrl + "/userBusinessSkills", this.data(param));
    },
    /**
     * 更新用户标签
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveUserLabel(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveUserLabel", this.data(param));
    },
    /**
     * 获取自己的标签
     * @param param
     */
    getUserLabel(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserLabel", this.data(param));
    },
    /**
     * 添加/修改 动态
     * @param param
     */
    inspirationAddOrSave(param) {
        return axiosUtils.post(store.state.devApiUrl + "/inspirationAddOrSave", this.data(param));
    },
    /**
     * 获取动态内容
     * @param param
     */
    getInspirationList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getInspirationList", this.data(param));
    },
    /**
     * 获取文章内容
     * @param param
     */
    getArticleList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getArticleList", this.data(param));
    },
    /**
     * 获取用户的名片信息
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getVisitingCard(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getVisitingCard", this.data(param));
    },
    /**
     * 设置名片
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    setVisitingCard(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setVisitingCard", this.data(param));
    },

    /**
     * 修改工作单位
     * @param param
     */
    savaVisitingCardCompany(param) {
        return axiosUtils.post(store.state.devApiUrl + "/savaVisitingCardCompany", this.data(param));
    },
    /**
     * 获取自己名片的标签
     * @param param
     */
    savaVisitingCardLabel(param) {
        return axiosUtils.post(store.state.devApiUrl + "/savaVisitingCardLabel", this.data(param));
    },
    /**
     * 更新网络社交名片
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    savaVisitingCardContact(param) {
        return axiosUtils.post(store.state.devApiUrl + "/savaVisitingCardContact", this.data(param));
    },
    /**
     * 更新网络社交通信地址
     * @param param
     */
    getHotExpertList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getHotExpertList", this.data(param));
    },
    /**
     * 通过id查询动态信息
     * @param param
     */
    getInspirationInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getInspirationInfo", this.data(param));
    },
    /**
     * 修改用户头像
     * @param param
     */
    saveUserHeadIco(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveUserHeadIco", this.data(param));
    },
    /**
     * 修改用户背景图片
     * @param param
     */
    saveUserBgImage(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveUserBgImage", this.data(param));
    },
    /**
     * 获取推荐好友列表
     * @param param
     */
    getRecommendFriends(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRecommendFriends", this.data(param));
    },
    /**
     * 获取我的空间大小
     * @param param
     */
    getMySpaceSize(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMySpaceSize", this.data(param));
    },
    /**
     * 添加好友
     * @param param
     */
    addFriends(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addFriends", this.data(param));
    },
    /**
     * 获取申请加我为好友的列表
     * @param param
     */
    getAddFriendList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAddFriendList", this.data(param));
    },
    /**
     * 处理好友申请
     * @param param
     */
    processFriendRequest(param) {
        return axiosUtils.post(store.state.devApiUrl + "/processFriendRequest", this.data(param));
    },
    /**
     * 删除好友分组
     * @param param
     */
    delFriendGroup(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delFriendGroup", this.data(param));
    },
    /**
     * 添加好友分组
     * @param param
     */
    addFriendGroup(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addFriendGroup", this.data(param));
    },
    /**
     * 获取个人空间留言及回复
     * @param param
     */
    getLeaveMessageAndReplyList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getLeaveMessageAndReplyList", this.data(param));
    },
    /**
     * 对用户个人空间进行留言
     * @param param
     */
    addLeaveMessage(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addLeaveMessage", this.data(param));
    },
    /**
     * 删除个人空间留言
     * @param param
     */
    delLeaveMessage(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delLeaveMessage", this.data(param));
    },
    /**
     * 自己删除个人空间留言的评论
     * @param param
     */
    delLeaveMessageReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delLeaveMessageReply", this.data(param));
    },
    /**
     * 添加个人空间留言的评论
     * @param param
     */
    addLeaveMessageReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addLeaveMessageReply", this.data(param));
    },
    /**
     * 删除好友
     * @param param
     */
    delFriends(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delFriends", this.data(param));
    },
    /**
     * 重新对好友进行分组
     * @param param
     */
    friendsAgainGroup(param) {
        return axiosUtils.post(store.state.devApiUrl + "/friendsAgainGroup", this.data(param));
    },
    /**
     * 修改好友分组名称
     * @param param
     */
    saveFriendGroupName(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveFriendGroupName", this.data(param));
    },
    /**
     * 更新空间寄语
     * @param param
     */
    saveMyBlessingWords(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveMyBlessingWords", this.data(param));
    },
    /**
     * 查询空间寄语
     * @param param
     */
    getMyBlessingWords(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyBlessingWords", this.data(param));
    },
    /**
     * 添加专家话题
     * @param param
     */
    addExpertTopic(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addExpertTopic", this.data(param));
    },
    /**
     * 获取专家话题
     * @param param
     */
    getExpertTopic(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertTopic", this.data(param));
    },
    /**
     * 删除专家话题
     * @param param
     */
    delExpertTopic(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delExpertTopic", this.data(param));
    },
    /**
     * 修改专家话题
     * @param param
     */
    editExpertTopic(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editExpertTopic", this.data(param));
    },
    /**
     * 获取朋友圈推荐热点话题
     * @param param
     */
    getHotTopicList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getHotTopicList", this.data(param));
    },
    /**
     * 获取朋友圈推荐热点话题
     * @param param
     */
    getMyHotTopicList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyHotTopicList", this.data(param));
    },
    /**
     * 创建话题
     * @param param
     */
    addTopic(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addTopic", this.data(param));
    },
    /**
     * 获取话题详情
     * @param param
     */
    getTopicInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getTopicInfo", this.data(param));
    },
    /**
     * 获取私信列表
     * @param param
     */
    getMsgList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMsgList", this.data(param));
    },
    /**
     * 获取通知未读数量
     * @param param
     */
    getNoticeNoReadNum(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getNoticeNoReadNum", this.data(param));
    },
    /**
     * 获取私信未读数量
     * @param param
     */
    getMsgNoReadNum(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMsgNoReadNum", this.data(param));
    },
    /**
     * 获取专家裂变
     * @param param
     */
    getExpertList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getExpertList", this.data(param));
    },

    /**
     * 获取分类名字
     * @param param
     */
    getTypeName(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTypeName", this.data(param));
    },

    /**
     * 生成专家咨询订单
     * @param param
     */
    makeConsult(param){
        return axiosUtils.post(store.state.devApiUrl + "/makeConsult", this.data(param));
    },

    /**
     * 获取专家咨询订单
     * @param param
     */
    getMyExpretOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyExpretOrderList", this.data(param));
    },
    /**
     * 获取专家咨询订单详情
     * @param param
     */
    getExpertOrderInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getExpertOrderInfo", this.data(param));
    },
    /**
     * 专家接单
     * @param param
     */
    expertOrderReceiving(param){
        return axiosUtils.post(store.state.devApiUrl + "/expertOrderReceiving", this.data(param));
    },
    /**
     * 上传咨询订单函复
     * @param param
     */
    replyExpertOrder(param){
        return axiosUtils.post(store.state.devApiUrl + "/replyExpertOrder", this.data(param));
    },
    /**
     * 确认咨询订单函复
     * @param param
     */
    confirmExpertOrder(param){
        return axiosUtils.post(store.state.devApiUrl + "/confirmExpertOrder", this.data(param));
    },
    /**
     * 创建Room
     * @param param
     */
    addRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/addRoom", this.data(param));
    },
    /**
     * 获取开放room列表
     * @param param
     */
    getOpenRoomList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getOpenRoomList", this.data(param));
    },
    /**
     * 获取我是房主或内部成员的room
     * @param param
     */
    getMyDutyRoomList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyDutyRoomList", this.data(param));
    },
    /**
     * 申请成为内部成员
     * @param param
     */
    applyToRoomUser(param){
        return axiosUtils.post(store.state.devApiUrl + "/applyToRoomUser", this.data(param));
    },
    /**
     * 同意或拒绝成为内部成员
     * @param param
     */
    agreeOrRefuseToRoomUser(param){
        return axiosUtils.post(store.state.devApiUrl + "/agreeOrRefuseToRoomUser", this.data(param));
    },
    /**
     * 获取room成员列表
     * @param param
     */
    getRoomUserList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomUserList", this.data(param));
    },
    /**
     * 获取ROOM详情
     * @param param
     */
    getRoomInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomInfo", this.data(param));
    },
    /**
     * room标准发布
     * @param param
     */
    roomStandardRelease(param){
        return axiosUtils.post(store.state.devApiUrl + "/roomStandardRelease", this.data(param));
    },
    /**
     * 获取room菜单内容
     * @param param
     */
    getRoomMenuContent(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomMenuContent", this.data(param));
    },

    /**
     * 获取room申请加入通知
     * @param param
     */
    getRoomUsertApplyList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomUsertApplyList", this.data(param));
    },
    /**
     * 获取我的room邀请通知
     * @param param
     */
    getRoomtInviteList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomtInviteList", this.data(param));
    },
    /**
     * 获取我的咨询话题订单列表
     * @param param
     */
    getMyTopicOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyTopicOrderList", this.data(param));
    },
    /**
     * 关注/取消关注room
     * @param param
     */
    collectRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/collectRoom", this.data(param));
    },
    /**
     * 获取我创建的room
     * @param param
     */
    getMyCreatRoomList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyCreatRoomList", this.data(param));
    },
    /**
     * 获取我关注的room
     * @param param
     */
    getMyCollectRoomList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyCollectRoomList", this.data(param));
    },
    /**
     * 获取我加入的room
     * @param param
     */
    getMyJoinRoomList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyJoinRoomList", this.data(param));
    },
    /**
     * 获取我管理的room
     * @param param
     */
    getMyManageRoomList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyManageRoomList", this.data(param));
    },
    /**
     * 创建任务序列
     * @param param
     */
    addTaskSequence(param){
        return axiosUtils.post(store.state.devApiUrl + "/addTaskSequence", this.data(param));
    },
    /**
     * 创建任务
     * @param param
     */
    addTask(param){
        return axiosUtils.post(store.state.devApiUrl + "/addTask", this.data(param));
    },
    /**
     * 新建Room菜单
     * @param param
     */
    addRoomMenu(param){
        return axiosUtils.post(store.state.devApiUrl + "/addRoomMenu", this.data(param));
    },
    /**
     * 编辑Room菜单
     * @param param
     */
    editRoomMenu(param){
        return axiosUtils.post(store.state.devApiUrl + "/editRoomMenu", this.data(param));
    },
    /**
     * 删除Room菜单
     * @param param
     */
    delRoomMenu(param){
        return axiosUtils.post(store.state.devApiUrl + "/delRoomMenu", this.data(param));
    },
    /**
     * 编辑Room
     * @param param
     */
    editRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/editRoom", this.data(param));
    },
    /**
     * 沉睡Room
     * @param param
     */
    sleepRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/sleepRoom", this.data(param));
    },
    /**
     >>>>>>> liuyalei
     * 找回支付密码
     * @param param
     */
    forgetBgbPayPwd(param) {
        return axiosUtils.post(store.state.devApiUrl + "/forgetBgbPayPwd", this.data(param));
    },
    /**
     * 购买会员
     * @param param
     */
    recoveryRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/recoveryRoom", this.data(param));
    },
    /**
     * 获取工作流
     * @param param
     */
    getWorkFlow(param){
        return axiosUtils.post(store.state.devApiUrl + "/getWorkFlow", this.data(param));
    },
    /**
     * 获取room可读菜单
     * @param param
     */
    getRoomReadableMenus(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomReadableMenus", this.data(param));
    },
    /**
     * 获取room可发菜单
     * @param param
     */
    getRoomWritableMenus(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomWritableMenus", this.data(param));
    },
    /**
     * 获取room菜单列表
     * @param param
     */
    getRoomMenuList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomMenuList", this.data(param));
    },
    /**
     * 修改room菜单指定字段
     * @param param
     */
    editRoomMenuAuth(param){
        return axiosUtils.post(store.state.devApiUrl + "/editRoomMenuAuth", this.data(param));
    },
    /**
     * 模板中的附件上传
     * @param param
     */
    uploadTemplateFile(param){
        return axiosUtils.post(store.state.devApiUrl + "/uploadTemplateFile", this.data(param));
    },

    /**
     * 获取通用的模板文档
     * @param param
     */
    getHotDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/getHotDiyTemplate", this.data(param));
    },
    /**
     * 通过id获取模板的信息
     * @param param
     */
    getDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/getDiyTemplate", this.data(param));
    },
    /**
     * 获取热门专家话题
     * @param param
     */
    getHotExpertTopic(param){
        return axiosUtils.post(store.state.devApiUrl + "/getHotExpertTopic", this.data(param));
    },
    /**
     * 获取room专家团队列表
     * @param param
     */
    getExpreTeam(param){
        return axiosUtils.post(store.state.devApiUrl + "/getExpreTeam", this.data(param));
    },
    /**
     * 获取room专家列表
     * @param param
     */
    getRoomExpretList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomExpretList", this.data(param));
    },
    /**
     * 查询一个用户的所有收藏标签
     * @param param
     */
    getUserCollectionLabelList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserCollectionLabelList", this.data(param));
    },
    /**
     * 添加一个收藏
     * @param param
     */
    addCollection(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addCollection", this.data(param));
    },
    /**
     * 获取用户收藏夹的内容
     * @param param
     */
    getUserCollectionList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserCollectionList", this.data(param));
    },
    /**
     * 获取我的空间大小
     * @param param
     */
    getMyEnclosuresAndArticle(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyEnclosuresAndArticle", this.data(param));
    },
    /**
     * 朋友圈点击视频播放增加点击量
     * @param param
     */
    setVideoClickCount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setVideoClickCount", this.data(param));
    },
    /**
     * 朋友圈点击超长纯文字动态增加点击量
     * @param param
     */
    setDynamicClickCount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setDynamicClickCount", this.data(param));
    },
    /**
     * 编辑话题
     * @param param
     */
    editTopic(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editTopic", this.data(param));
    },
    /**
     * 根据标签id获取收藏内容
     * @param param
     */
    getCollectionContent(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCollectionContent", this.data(param));
    },
    /**
     * 获取跟帖的所有回复
     * @param param
     */
    getCommentSub(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCommentSub", this.data(param));
    },
    /**
     * 获取一篇文章的跟帖及字类
     * @param param
     */
    getComment(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getComment", this.data(param));
    },
    /**
     * 添加一条跟帖
     * @param param
     */
    addComment(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addComment", this.data(param));
    },
    /**
     * 通过type已读
     * @param param
     */
    isReadType(param) {
        return axiosUtils.post(store.state.devApiUrl + "/isReadType", this.data(param));
    },

    /**
     * 热门文章
     * @param param
     */
    circleRecommend(param) {
        return axiosUtils.post(store.state.devApiUrl + "/circleRecommend", this.data(param));
    },
    /**
     * 获取专家的预约单
     * @param param
     */
    getExpretOrderList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpretOrderList", this.data(param));
    },
    /**
     * 获取专家话题
     * @param param
     */
    getExpertInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertInfo", this.data(param));
    },
    /**
     * 获取人脸认证初始化url
     * @param param
     */
    getInitFaceVerify(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getInitFaceVerify", this.data(param));
    },
    /**
     * 机构认证
     * @param param
     */
    agencyCertification(param) {
        return axiosUtils.post(store.state.devApiUrl + "/agencyCertification", this.data(param));
    },

    /**
     * 分配子账号
     * @param param
     */
    allocationAccount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/allocationAccount", this.data(param));
    },

    /**
     * 设置子账号状态
     * @param param
     */
    accountStatus(param) {
        return axiosUtils.post(store.state.devApiUrl + "/accountStatus", this.data(param));
    },
    /**
     * 获取机构账户自己的登陆账户信息
     * @param param
     */
    getMyAccount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyAccount", this.data(param));
    },
    /**
     * 重置子账户（机构账户或者管理员）
     * @param param
     */
    resetSubAccount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/resetSubAccount", this.data(param));
    },
    /**
     * 删除提问订单
     * @param param
     */
    delNoPayQa(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delNoPayQa", this.data(param));
    },

    /**
     * 获取adapay绑定的银行卡列表
     * @param param
     */
    getAdaPayBanCardList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getAdaPayBanCardList", this.data(param));
    },
    /**
     * 银行卡绑定申请
     * @param param
     */
    alpplyBankCard(param){
        return axiosUtils.post(store.state.devApiUrl + "/alpplyBankCard", this.data(param));
    },
    /**
     * 银行卡绑定确定
     * @param param
     */
    alpplyBankCardDetermine(param){
        return axiosUtils.post(store.state.devApiUrl + "/alpplyBankCardDetermine", this.data(param));
    },
    /**
     * 解除银行卡绑定
     * @param param
     */
    rescindBankCardObj(param){
        return axiosUtils.post(store.state.devApiUrl + "/rescindBankCardObj", this.data(param));
    },
    /**
     * 快捷支付订单列表
     * @param param
     */
    getAdaPayBanCardPayList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getAdaPayBanCardPayList", this.data(param));
    },
    /**
     * 发起快捷支付
     * @param param
     */
    alpplyBankCardPay(param){
        return axiosUtils.post(store.state.devApiUrl + "/alpplyBankCardPay", this.data(param));
    },
    /**
     * 快捷支付确认
     * @param param
     */
    alpplyBankCardPayDetermine(param){
        return axiosUtils.post(store.state.devApiUrl + "/alpplyBankCardPayDetermine", this.data(param));
    },
    /**
     * 查询结算账户对象
     * @param param
     */
    querySettleAccount(param){
        return axiosUtils.post(store.state.devApiUrl + "/querySettleAccount", this.data(param));
    },
    /**
     * 创建结算账户对象
     * @param param
     */
    settleAccountUser(param){
        return axiosUtils.post(store.state.devApiUrl + "/settleAccountUser", this.data(param));
    },
    /**
     * 删除结算账户对象
     * @param param
     */
    deleteSettleAccount(param){
        return axiosUtils.post(store.state.devApiUrl + "/deleteSettleAccount", this.data(param));
    },
    /**
     * 查询结算账户余额对象
     * @param param
     */
    queryAccountBalance(param){
        return axiosUtils.post(store.state.devApiUrl + "/queryAccountBalance", this.data(param));
    },
    /**
     * 用户结算账户列表
     * @param param
     */
    querySettleAccountUserList(param){
        return axiosUtils.post(store.state.devApiUrl + "/querySettleAccountUserList", this.data(param));
    },
    /**
     * 用户分账申请
     * @param param
     */
    settleAccountPenny(param){
        return axiosUtils.post(store.state.devApiUrl + "/settleAccountPenny", this.data(param));
    },
    /**
     * 用户分账确认
     * @param param
     */
    settleAccountPennyDetermine(param){
        return axiosUtils.post(store.state.devApiUrl + "/settleAccountPennyDetermine", this.data(param));
    },
    /**
     * 延时分账信息列表
     * @param param
     */
    queryPennyList(param){
        return axiosUtils.post(store.state.devApiUrl + "/queryPennyList", this.data(param));
    },
    /**
     * 创建支付确认对象
     * @param param
     */
    paymentConfirmCreate(param){
        return axiosUtils.post(store.state.devApiUrl + "/paymentConfirmCreate", this.data(param));
    },
    /**
     * 创建取现对象
     * @param param
     */
    alpplyBankCarCash(param){
        return axiosUtils.post(store.state.devApiUrl + "/alpplyBankCarCash", this.data(param));
    },
    /**
     * 手机号搜索用户
     * @param param
     */
    checkedMoile(param){
        return axiosUtils.post(store.state.devApiUrl + "/checkedMoile", this.data(param));
    },
    /**
     * 创建转账快捷支付对象
     * @param param
     */
    settleAccountTransfer(param){
        return axiosUtils.post(store.state.devApiUrl + "/settleAccountTransfer", this.data(param));
    },
    /**
     * 创建转账快捷支付确认
     * @param param
     */
    settleAccountTransferDetermine(param){
        return axiosUtils.post(store.state.devApiUrl + "/settleAccountTransferDetermine", this.data(param));
    },
    /**
     * 创建支付撤销对象(个人)
     * */
    paymentReverseIndividual(param){
        return axiosUtils.post(store.state.devApiUrl + "/paymentReverseIndividual", this.data(param));
    },
    /**
     * 获取银行编码列表(企业)
     * */
    getBankList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getBankList", this.data(param));
    },
    /**
     * 创建企业用户(企业)
     * */
    corpMemberCreate(param){
        return axiosUtils.post(store.state.devApiUrl + "/corpMemberCreate", this.data(param));
    },
    /**
     * 获取企业结算账户详情(企业)
     * */
    queryCorpAccount(param){
        return axiosUtils.post(store.state.devApiUrl + "/queryCorpAccount", this.data(param));
    },
    /**
     * 创建企业支付(企业)
     * */
    corpPaymentCreatePay(param){
        return axiosUtils.post(store.state.devApiUrl + "/corpPaymentCreatePay", this.data(param));
    },
    /**
     * 获取企业订单列表(企业)
     * */
    getCorpOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getCorpOrderList", this.data(param));
    },
    /**
     * 查询支付对象(企业)
     * */
    queryCorpPayment(param){
        return axiosUtils.post(store.state.devApiUrl + "/queryCorpPayment", this.data(param));
    },
    /**
     * 支付关单(企业)
     * */
    closeCorpOrder(param){
        return axiosUtils.post(store.state.devApiUrl + "/closeCorpOrder", this.data(param));
    },
    /**
     * 发起转账(企业)
     * */
    corpTransferMoneyDetermine(param){
        return axiosUtils.post(store.state.devApiUrl + "/corpTransferMoneyDetermine", this.data(param));
    },
    /**
     * 获取企业转账订单列表(企业)
     * */
    getCorpTransferOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getCorpTransferOrderList", this.data(param));
    },
    /**
     * 获取企业转账订单详情(企业)
     * */
    queryCorpTransPayment(param){
        return axiosUtils.post(store.state.devApiUrl + "/queryCorpTransPayment", this.data(param));
    },
    /**
     * 转账关单(企业)
     * */
    closeCorpTransOrder(param){
        return axiosUtils.post(store.state.devApiUrl + "/closeCorpTransOrder", this.data(param));
    },
    /**
     * 创建企业分账对象(企业)
     * */
    divisionAccountsDetermine(param){
        return axiosUtils.post(store.state.devApiUrl + "/divisionAccountsDetermine", this.data(param));
    },
    /**
     * 获取企业分账订单列表(企业)
     * */
    getCorpPointsOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getCorpPointsOrderList", this.data(param));
    },
    /**
     * 获取结算订单详情(企业)
     * */
    getCorpAettlementInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getCorpAettlementInfo", this.data(param));
    },
    /**
     * 企业延时分账对象确认(企业)
     * */
    corpPaymentConfirmCreate(param){
        return axiosUtils.post(store.state.devApiUrl + "/corpPaymentConfirmCreate", this.data(param));
    },
    /**
     * 获取企业提现列表(企业)
     * */
    getCorpCashList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getCorpCashList", this.data(param));
    },
    /**
     * 生成文章封面
     * @param param
     */
    uploadThumbImg(param){
        return axiosUtils.post(store.state.devApiUrl + "/uploadThumbImg", this.data(param));
    },
    /**
     * 获取推荐频道
     * @param param
     */
    getChannelList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getChannelList", this.data(param));
    },
    /**
     * 获取内容分类
     * @param param
     */
    getTypeList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTypeList", this.data(param));
    },
    /**
     * 获取获取列表
     * @param param
     */
    getTopTopicList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTopTopicList", this.data(param));
    },
    /**
     * 添加文章或者修改文章
     * @param param
     */
    articleAddOrSave(param){
        return axiosUtils.post(store.state.devApiUrl + "/articleAddOrSave", this.data(param));
    },
    /**
     * 获取文章详情
     * @param param
     */
    getArticleInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getArticleInfo", this.data(param));
    },
    /**
     * 获取草稿箱列表
     * @param param
     */
    articleDraftList(param){
        return axiosUtils.post(store.state.devApiUrl + "/articleDraftList", this.data(param));
    },
    /**
     * 添加文章或者修改草稿箱文章
     * @param param
     */
    articleDraftAddOrSave(param){
        return axiosUtils.post(store.state.devApiUrl + "/articleDraftAddOrSave", this.data(param));
    },
    /**
     * 添加上传资料
     * @param param
     */
    addknowledge(param){
        return axiosUtils.post(store.state.devApiUrl + "/addKnowledge", this.data(param));
    },
    /**
     * 修改上传资料
     * @param param
     */
    editKnowledge(param){
        return axiosUtils.post(store.state.devApiUrl + "/editKnowledge", this.data(param));
    },
    /**
     * 知识仓库文章
     * @param param
     */
    getFilebaseList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getFilebaseList", this.data(param));
    },
    /**
     * 知识仓库
     * @param param
     */
    getContentList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getContentList", this.data(param));
    },
    /**
     * 删除动态内容
     * @param param
     */
    delArticle(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delArticle", this.data(param));
    },
    /**
     * 首页获取文章推荐的频道及文字
     * @param param
     */
    getRecommendedChannelArticles(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRecommendedChannelArticles", this.data(param));
    },
    /**
     * 获取首页的今日头条接口
     * @param param
     */
    getTodayHeadlines(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getTodayHeadlines", this.data(param));
    },
    /**
     * 获取只是仓库
     * @param param
     */
    getKnowledgeInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getKnowledgeInfo", this.data(param));
    },
    /**
     * del只是仓库
     * @param param
     */
    delKnowledge(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delKnowledge", this.data(param));
    },
    /**
     * 获取头条文章详情
     * @param param
     */
    getHeadLineInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getHeadLineInfo", this.data(param));
    },
    /**
     * 获取知识仓库图片详情
     * @param param
     */
    getImagesInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getImagesInfo", this.data(param));
    },

    /**
     * 点赞
     * @param param
     */
    thumbsUp(param) {
        return axiosUtils.post(store.state.devApiUrl + "/thumbsUp", this.data(param));
    },

    /**
     * 文章删除
     * @param param
     */
    InspirationDelete(param) {
        return axiosUtils.post(store.state.devApiUrl + "/InspirationDelete", this.data(param));
    },
    /**
     * 获取用户信息
     * @param param
     */
    getUserInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserInfo", this.data(param));
    },
    /**
     * 获取我的好友列表
     * @param param
     */
    getFriendList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getFriendList", this.data(param));
    },
    /**
     * 查询实名认证信息
     * @param param
     */
    getAuthInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAuthInfo", this.data(param));
    },
    /**
     * 获取红包详情页
     * @param param
     */
    getRedEnvelopesQuestionInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRedEnvelopesQuestionInfo", this.data(param));
    },
    /**
     * 获取设备分类
     * @param param
     */
    getEquipmentTypeList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getEquipmentTypeList", this.data(param));
    },
    /**
     * 添加回复
     * @param param
     */
    addQuestionReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addQuestionReply", this.data(param));
    },
    /**
     * 获取回复列表
     * @param param
     */
    getQuestionReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getQuestionReply", this.data(param));
    },
    /**
     * 发布商务信息
     * @param param
     */
    addBusinessInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addBusinessInfo", this.data(param));
    },

    /**
     * 查询商务信息的列表
     * @param param
     */
    getBusinessInfoList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getBusinessInfoList", this.data(param));
    },
    /**
     * 获取商务信息详情
     * @param param
     */
    getBusinessInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getBusinessInfo", this.data(param));
    },
    /**
     * 红包问答 采纳回复
     * @param param
     */
    adoptQuestionReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/adoptQuestionReply", this.data(param));
    },
    /**
     * 查询支付对象
     * @param param
     */
    queryPayPayment(param) {
        return axiosUtils.post(store.state.devApiUrl + "/queryPayPayment", this.data(param));
    },
    /**
     * 通过手机号码搜索用户
     * @param param
     */
    adapayCheckedMoile(param) {
        return axiosUtils.post(store.state.devApiUrl + "/adapayCheckedMoile", this.data(param));
    },
    /**
     * 发布悬赏问答
     * @param param
     */
    introductionAdd(param) {
        return axiosUtils.post(store.state.devApiUrl + "/introductionAdd", this.data(param));
    },
    /**
     * 悬赏问答 采纳回复
     * @param param
     */
    introductionAdopt(param) {
        return axiosUtils.post(store.state.devApiUrl + "/introductionAdopt", this.data(param));
    },
    getSaveVideoImg(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getSaveVideoImg", this.data(param));
    },
    /**
     * 商务信息报名
     * @param param
     */
    addBusinessSignup(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addBusinessSignup", this.data(param));
    },
    /**
     * 设置百工宝支付密码
     * @param param
     */
    setBgbPayPwd(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setBgbPayPwd", this.data(param));
    },
    /**
     * 修改百工宝支付密码
     * @param param
     */
    saveBgbPayPwd(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveBgbPayPwd", this.data(param));
    },
    /**
     * 购买会员
     * @param param
     */
    buyVip(param) {
        return axiosUtils.post(store.state.devApiUrl + "/buyVip", this.data(param));
    },
    /**
     * 获取会员套餐列表
     * @param param
     */
    getVipList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getVipList", this.data(param));
    },
    /**
     * 获取空间套餐列表
     * @param param
     */
    getSpaceList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getSpaceList", this.data(param));
    },
    /**
     * 获取空间套餐列表
     * @param param
     */
    buySpace(param) {
        return axiosUtils.post(store.state.devApiUrl + "/buySpace", this.data(param));
    },
    /**
     * 获取会员价格列表
     * @param param
     */
    packageVipList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/packageVipList", this.data(param));
    },
    /**
     * 阿里支付新页面二维码
     * @param param
     */
    getAliFormStrInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAliFormStrInfo", this.data(param));
    },
    /**
     * 获取空间价格列表
     * @param param
     */
    packageSpaceList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/packageSpaceList", this.data(param));
    },
    /**
     * 个人收购
     * @param param
     */
    vipAndSpaceAndSubPay(param) {
        return axiosUtils.post(store.state.devApiUrl + "/vipAndSpaceAndSubPay", this.data(param));
    },
    /**
     * 获取套餐详情
     * @param param
     */
    packageInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/packageInfo", this.data(param));
    },
    /**
     * 获取子账号价格列表
     * @param param
     */
    packageSubList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/packageSubList", this.data(param));
    },
    /**
     * 空间续费确定
     * @param param
     */
    renewalSpaceDetermine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/renewalSpaceDetermine", this.data(param));
    },
    /**
     * 会员续费确定
     * @param param
     */
    renewalVipDetermine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/renewalVipDetermine", this.data(param));
    },
    /**
     * 空间扩容确定
     * @param param
     */
    enlargeSpaceDetermine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/enlargeSpaceDetermine", this.data(param));
    },
    /**
     * 子账号扩容确定
     * @param param
     */
    enlargeSubDetermine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/enlargeSubDetermine", this.data(param));
    },
    /**
     * 子账号续费确定
     * @param param
     */
    renewalSubDetermine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/renewalSubDetermine", this.data(param));
    },
    /**
     * 检查有未支付的提问
     * @param param
     */
    getEnlargeSpacePrice(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getEnlargeSpacePrice", this.data(param));
    },
    /**
     * 点击好友头像
     * @param param
     */
    checkHeadIco(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkHeadIco", this.data(param));
    },
    /**
     * 添加一个规格属性
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addSpecificationsGroup(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addSpecificationsGroup", this.data(param));
    },
    /**
     * 获取分类的规格列表
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTypeSpecificationsGroup(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getTypeSpecificationsGroup", this.data(param));
    },
    /**
     * 添加店铺
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addShop(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addShop", this.data(param));
    },
    /**
     * 通过公司名称搜索机构用户
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    checkCorpName(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkCorpName", this.data(param));
    },

    /**
     * 获取子账号扩容差价
     * @param param
     */
    getEnlargeSubPrice(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getEnlargeSubPrice", this.data(param));
    },
    /**
     * 获取机构认证信息
     * @param param
     */
    getAgencyCertification(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAgencyCertification", this.data(param));
    },
    /**
     * 添加店铺产品
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addShopGoods(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addShopGoods", this.data(param));
    },
    /**
     * 获取商品列表
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getShopGoodsList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopGoodsList", this.data(param));
    },
    /**
     * 添加一个店铺服务
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addShopService(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addShopService", this.data(param));
    },
    /**
     * 查询商品服务列表
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getShopServiceList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopServiceList", this.data(param));
    },
    /**
     * 创建企业快捷卡申请
     * @param param
     */
    corpBankCard(param) {
        return axiosUtils.post(store.state.devApiUrl + "/corpBankCard", this.data(param));
    },
    /**
     * 查询企业用户对象
     * @param param
     */
    queryCorpMember(param) {
        return axiosUtils.post(store.state.devApiUrl + "/queryCorpMember", this.data(param));
    },
    /**
     * 创建企业个人结算账户
     * @param param
     */
    settlementCorpInIndivid(param) {
        return axiosUtils.post(store.state.devApiUrl + "/settlementCorpInIndivid", this.data(param));
    },
    /**
     * 编辑店铺
     * @param param
     */
    editShop(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editShop", this.data(param));
    },
    /**
     * 获取商城的服务的详情
     * @param param
     */
    getShopServiceInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopServiceInfo", this.data(param));
    },
    /**
     * 店铺服务需求完成后插入订单
     * @param param
     */
    addShopServiceOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addShopServiceOrder", this.data(param));
    },
    /**
     * 确认服务收货
     * @param param
     */
    verifyServiceReceive(param) {
        return axiosUtils.post(store.state.devApiUrl + "/verifyServiceReceive", this.data(param));
    },
    /**
     * 采购服务填写需求服务信息
     * @param param
     */
    procurementServices(param) {
        return axiosUtils.post(store.state.devApiUrl + "/procurementServices", this.data(param));
    },
    /**
     * 处理填写的采购需求
     * @param param
     */
    refusalOrConsentProcurement(param) {
        return axiosUtils.post(store.state.devApiUrl + "/refusalOrConsentProcurement", this.data(param));
    },
    /**
     * 服务商填写价格及支付信息
     * @param param
     */
    fillPriceAndPaymentInformation(param) {
        return axiosUtils.post(store.state.devApiUrl + "/fillPriceAndPaymentInformation", this.data(param));
    },
    /**
     * 获取所有的服务
     * @param param
     */
    getAllShopServices(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAllShopServices", this.data(param));
    },

    /**
     * 获取所有的商品订单
     * @param param
     */
    getServiceOrderList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getServiceOrderList", this.data(param));
    },
    /**
     * 通过id获取需求的信息
     * @param param
     */
    getShopDemandInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopDemandInfo", this.data(param));
    },
    /**
     * 购买方对于支付信息是否同意
     * @param param
     */
    agreeOrRefusePayInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/agreeOrRefusePayInfo", this.data(param));
    },
    /**
     * 获取服务订单详情
     * @param param
     */
    getServiceOrderInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getServiceOrderInfo", this.data(param));
    },
    /**
     * 获取保证金缴纳金额
     * @param param
     */
    getDepositAmount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getDepositAmount", this.data(param));
    },
    /**
     * 查询自己保证金缴纳是否缴纳
     * @param param
     */
    getMyDepositOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyDepositOrder", this.data(param));
    },
    /**
     * 添加保证金
     * @param param
     */
    addDepositOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addDepositOrder", this.data(param));
    },
    /**
     * 上传服务成果
     * @param param
     */
    addServiceResult(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addServiceResult", this.data(param));
    },
    /**
     * 添加修改意见
     * @param param
     */
    addResultIdea(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addResultIdea", this.data(param));
    },
    /**
     * 服务成果审核通过
     * @param param
     */
    saveServiceResult(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveServiceResult", this.data(param));
    },
    /**
     * 邀请专家
     * @param param
     */
    addExamineOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addExamineOrder", this.data(param));
    },
    /**
     * 专家接单/拒单
     * @param param
     */
    examineOrderTaking(param) {
        return axiosUtils.post(store.state.devApiUrl + "/examineOrderTaking", this.data(param));
    },
    /**
     * 添加专家意见
     * @param param
     */
    addExpertIdea(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addExpertIdea", this.data(param));
    },
    /**
     * 获取专家下单列表
     * @param param
     */
    examineOrderList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/examineOrderList", this.data(param));
    },
    /**
     * 获取专家接单列表
     * @param param
     */
    examineOrderTakingList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/examineOrderTakingList", this.data(param));
    },
    /**
     * 修改专家意见
     * @param param
     */
    updateExpertIdea(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateExpertIdea", this.data(param));
    },
    /**
     * 取消专家订单
     * @param param
     */
    cancelExamineOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelExamineOrder", this.data(param));
    },
    /**
     * 支付专家订单
     * @param param
     */
    payExamineOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/payExamineOrder", this.data(param));
    },

    sendEmailCode(param) {
        return axiosUtils.post(store.state.devApiUrl + "/sendRegisterEmail", this.data(param));
    },
    /**
     * 添加店铺的案例
     */
    addShopCase(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addShopCase", this.data(param));
    },
    /**
     * 获取店铺的案例列表
     */
    getShopCase(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopCase", this.data(param));
    },
    /**
     * 删除案例
     */
    deleteShopCase(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteShopCase", this.data(param));
    },
    /**
     * 删除服务
     */
    deleteShopService(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteShopService", this.data(param));
    },
    /**
     * 更新服务的状态
     */
    updateShopServiceStatus(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateShopServiceStatus", this.data(param));
    },
    /**
     * 删除产品
     */
    deleteShopGoods(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteShopGoods", this.data(param));
    },
    /**
     * 店铺的商品进行上架或者下架
     */
    updateShopGoodsStatus(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateShopGoodsStatus", this.data(param));
    },
    /**
     * 获取店铺的案例详情
     */
    getShopCaseInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopCaseInfo", this.data(param));
    },
    /**
     * 修改案例
     */
    updateShopCase(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateShopCase", this.data(param));
    },
    /**
     * 服务订单撤单
     */
    cancelServiceOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelServiceOrder", this.data(param));
    },
    /**
     * 删除保证金记录
     */
    delDepositOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delDepositOrder", this.data(param));
    },
    /**
     * 修改密码
     */
    saveMyPwd(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveMyPwd", this.data(param));
    },
    /**
     * 绑定手机号
     */
    bindPhone(param) {
        return axiosUtils.post(store.state.devApiUrl + "/bindPhone", this.data(param));
    },
    /**
     * 绑定账号
     */
    bindAccount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/bindAccount", this.data(param));
    },

    /**
     * 邮箱验证码
     */
    sendRegisterEmail(param) {
        return axiosUtils.post(store.state.devApiUrl + "/sendRegisterEmail", this.data(param));
    },
    /**
     * 通过服务订单号查看合同
     */
    getContractUrl(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getContractUrl", this.data(param));
    },
    /**
     * 用户忘记密码找回密码
     */
    forgetPwd(param) {
        return axiosUtils.post(store.state.devApiUrl + "/forgetPwd", this.data(param));
    },
    /**
     * 服务金缴纳后撤单
     */
    serviceOrderLaterCancel(param) {
        return axiosUtils.post(store.state.devApiUrl + "/serviceOrderLaterCancel", this.data(param));
    },
    /**
     * 委托方取消需求取消需求
     */
    cancelDemand(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelDemand", this.data(param));
    },
    /**
     * 获取服务订单日志
     */
    shopServiceOrderLog(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopServiceOrderLog", this.data(param));
    },
    /**
     * 选择公账支付还是私卡支付
     */
    selectPaymentType(param) {
        return axiosUtils.post(store.state.devApiUrl + "/selectPaymentType", this.data(param));
    },
    qqOauth(param) {
        return axiosUtils.post(store.state.devApiUrl + "/qqOauth", this.data(param));
    },
    wxOauth(param) {
        return axiosUtils.post(store.state.devApiUrl + "/wxOauth", this.data(param));
    },

    /**
     * 通过uuid获取用户的报名信息
     */
    getUserEnrollInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserEnrollInfo", this.data(param));
    },
    /**
     * 对店铺进行收藏/取消收藏
     */
    collectShop(param) {
        return axiosUtils.post(store.state.devApiUrl + "/collectShop", this.data(param));
    },
    getRecommendedArticles(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRecommendedArticles", this.data(param));

    },
    getRemindList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRemindList", this.data(param));

    },
    /**
     * 获取所有的商品
     */
    getAllShopGoods(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAllShopGoods", this.data(param));

    },
    /**
     *取消我报名的商务信息
     */
    cancelMyBusinessSignup(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelMyBusinessSignup", this.data(param));

    },
    /**
     *撤销我发布的商务信息
     */
    cancelMyBusinessInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelMyBusinessInfo", this.data(param));
    },
    /**
     * 处理申请的模板信息
     * */
    handleDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/handleDiyTemplate", this.data(param));
    },
    /**
     * 获取视频详情
     * */
    getVideosInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getVideosInfo", this.data(param));
    },
    /**
     * 获取附件详情
     * */
    getAnnexInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getAnnexInfo", this.data(param));
    },
    /**
     * 获取Room报名事件列表
     * */
    getRoomEnrollEventList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomEnrollEventList", this.data(param));
    },
    /**
     * 查询专业认证信息临时保存的数据
     * */
    getUserMajorAuthTemp(param){
        return axiosUtils.post(store.state.devApiUrl + "/getUserMajorAuthTemp", this.data(param));
    },

    /**
     * 查询用户专家认证临时保存的数据
     * */
    getUserExpertAuthTemp(param){
        return axiosUtils.post(store.state.devApiUrl + "/getUserExpertAuthTemp", this.data(param));
    },
    /**
     * 获取我的店铺
     * */
    getMyShop(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyShop", this.data(param));
    },
    /**
     * 获取所有的店铺
     * */
    getAllShop(param){
        return axiosUtils.post(store.state.devApiUrl + "/getAllShop", this.data(param));
    },
    /**
     * 绑定邮箱
     * */
    bindEmail(param){
        return axiosUtils.post(store.state.devApiUrl + "/bindEmail", this.data(param));
    },
    /**
     * 通过uuid获取店铺的信息
     * */
    getShopInfoByUuid(param){
        return axiosUtils.post(store.state.devApiUrl + "/getShopInfoByUuid", this.data(param));
    },
    /**
     * 更新用户的简介头像
     * */
    updateUserProfileHead(param){
        return axiosUtils.post(store.state.devApiUrl + "/updateUserProfileHead", this.data(param));
    },
    /**
     * 设置用户是否进行就业推荐
     * */
    setUserRecommendJob(param){
        return axiosUtils.post(store.state.devApiUrl + "/setUserRecommendJob", this.data(param));
    },
    /**
     * 获取在朋友圈中推荐的店铺
     * */
    getCircleRecommendShop(param){
        return axiosUtils.post(store.state.devApiUrl + "/getCircleRecommendShop", this.data(param));
    },
    /**
     * 获取我关注的店铺列表
     * */
    getMyCollectShop(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyCollectShop", this.data(param));
    },
    /**
     * 设置用户是否进行好友推荐
     * */
    setReferFriends(param){
        return axiosUtils.post(store.state.devApiUrl + "/setReferFriends", this.data(param));
    },
    /**
     * 获取我的自定义模板
     * */
    getMyDiyTemplate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyDiyTemplate", this.data(param));
    },
    // getCircleBanner(param) {
    //     return axiosUtils.post(store.state.devApiUrl + "/getFriendCircleBanner", this.data(param));
    // }
    /**
     * 事件可分享列表
     * */
    canInviteUserlist(param) {
        return axiosUtils.post(store.state.devApiUrl + "/canInviteUserlist", this.data(param));
    },
    /**
     * 修改报名事件
     * */
    editEnroll(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editEnroll", this.data(param));
    },
    /**
     * 获取机构中的部门列表
     * */
    getOrgDepartmentList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getOrgDepartmentList", this.data(param));
    },
    /**
     * 在部门中添加一个用户记录
     * */
    addUserToDepartment(param){
        return axiosUtils.post(store.state.devApiUrl + "/addUserToDepartment", this.data(param));
    },
    /**
     * 添加机构部门
     * */
    addOrgDepartment(param){
        return axiosUtils.post(store.state.devApiUrl + "/addOrgDepartment", this.data(param));
    },
    wxLoginCallback(param) {
        return axiosUtils.post(store.state.devApiUrl + "/wxLoginCallback", this.data(param));
    },

    wxBindCallback(param) {
        return axiosUtils.post(store.state.devApiUrl + "/wxBindCallback", this.data(param));
    },

    qqCallback(param) {
        return axiosUtils.post(store.state.devApiUrl + "/qqCallback", this.data(param));
    },
    qqBindCallback(param) {
        return axiosUtils.post(store.state.devApiUrl + "/qqBindCallback", this.data(param));
    },
    /**
     *新增收货地址
     */
    addAddress(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addAddress", this.data(param));
    },
    /**
     *获取收货地址列表
     */
    getAddressList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAddressList", this.data(param));
    },
    /**
     *修改收货地址
     */
    updateAddress(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateAddress", this.data(param));
    },
    /**
     *设置默认地址
     */
    setDefaultAddress(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setDefaultAddress", this.data(param));
    },
    /**
     *删除收获地址
     */
    deleteAddress(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteAddress", this.data(param));
    },
    /**
     * room模板发布
     * */
    addRoomTemplateContent(param){
        return axiosUtils.post(store.state.devApiUrl + "/addRoomTemplateContent", this.data(param));
    },
    /**
     * 获取room中发布的菜单的内容信息
     * */
    getRoomContentInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomContentInfo", this.data(param));
    },
    addDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/addDiyTemplate", this.data(param));
    },
    /**
     * room内容编辑
     * */
    editTemplateContent(param){
        return axiosUtils.post(store.state.devApiUrl + "/editTemplateContent", this.data(param));
    },
    /**
     * 创建或修改文件夹
     * */
    createRoomFolder(param){
        return axiosUtils.post(store.state.devApiUrl + "/createRoomFolder", this.data(param));
    },
    /**
     * 添加rooms到folder
     * */
    AddRoomsInFolder(param){
        return axiosUtils.post(store.state.devApiUrl + "/AddRoomsInFolder", this.data(param));
    },
    /**
     * 获取文件夹的详情
     * */
    getFolderInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getFolderInfo", this.data(param));
    },
    /**
     * 删除文件夹记录
     * */
    delFolderRecord(param){
        return axiosUtils.post(store.state.devApiUrl + "/delFolderRecord", this.data(param));
    },
    /**
     * 获取room最新动态
     * */
    getRecentContent(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRecentContent", this.data(param));
    },
    /**
     * 获取room最新动态
     * */
    getInstitutionContacts(param){
        return axiosUtils.post(store.state.devApiUrl + "/getInstitutionContacts", this.data(param));
    },
    /**
     * 添加一个审批流
     * */
    addExamine(param){
        return axiosUtils.post(store.state.devApiUrl + "/addExamine", this.data(param));
    },
    /**
     * 获取审批流
     * */
    getMyExamine(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyExamine", this.data(param));
    },
    /**
     * 设置room管理员
     * */
    setRoomAdmin(param){
        return axiosUtils.post(store.state.devApiUrl + "/setRoomAdmin", this.data(param));
    },
    /**
     * 取消room的管理员
     * */
    cancelRoomAdmin(param){
        return axiosUtils.post(store.state.devApiUrl + "/cancelRoomAdmin", this.data(param));
    },
    /**
     * 设置room的口令
     * */
    setRoomPassword(param){
        return axiosUtils.post(store.state.devApiUrl + "/setRoomPassword", this.data(param));
    },
    /**
     * 使用口令加入room
     * */
    passwordJoinRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/passwordJoinRoom", this.data(param));
    },
    /**
     * 发起人进行下一步
     * */
    examineNext(param){
        return axiosUtils.post(store.state.devApiUrl + "/examineNext", this.data(param));
    },
    /**
     * 审批人员进行审批提交
     * */
    examineSubmitResults(param){
        return axiosUtils.post(store.state.devApiUrl + "/examineSubmitResults", this.data(param));
    },

    makeFaceTime(param){
        return axiosUtils.post(store.state.devApiUrl + "/makeFaceTime", this.data(param));
    },
    /**
     * 获取我的好友分组列表
     * */
    getMyFriendGroupList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyFriendGroupList", this.data(param));
    },
    /**
     * 机构账号和管理员查看子账号的列表
     * */
    getSubAccountList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getSubAccountList", this.data(param));
    },
    /**
     * 删除room中的内容
     * */
    delRoomContent(param){
        return axiosUtils.post(store.state.devApiUrl + "/delRoomContent", this.data(param));
    },
    /**
     * 移除一个room成员
     * */
    removeRoomUser(param){
        return axiosUtils.post(store.state.devApiUrl + "/removeRoomUser", this.data(param));
    },
    /**
     * 邀请用户加入room
     * */
    inviteJoinRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/inviteJoinRoom", this.data(param));
    },
    /**
     * 获取room通知的未读数量
     * */
    getRoomNotice(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomNotice", this.data(param));
    },
    /**
     * 新增报名事件
     * */
    addEnrollEvent(param){
        return axiosUtils.post(store.state.devApiUrl + "/addEnrollEvent", this.data(param));
    },
    /**
     * 获取报名详情
     * */
    getEnrollInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getEnrollInfo", this.data(param));
    },
    /**
     * 报名
     * */
    toEnroll(param){
        return axiosUtils.post(store.state.devApiUrl + "/toEnroll", this.data(param));
    },
    /**
     * 获取报名事件列表
     * */
    getEnrollEventList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomEnrollEventList", this.data(param));
    },
    /**
     * 获取我的报名事件
     * */
    getMyEnrollEventList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyEnrollEventList", this.data(param));
    },
    /**
     * 获取事件报名记录
     * */
    getEnrollRecords(param){
        return axiosUtils.post(store.state.devApiUrl + "/getEnrollRecords", this.data(param));
    },
    /**
     * 获取当前id下的所有内容
     * */
    getRoomFolderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomFolderList", this.data(param));
    },
    /**
     * 共享我的模板到room中
     * */
    shareTemplateToRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/shareTemplateToRoom", this.data(param));
    },
    /**
     * 获取room中的模板信息列表
     * */
    getRoomTemplateList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomTemplateList", this.data(param));
    },
    /**
     * 通过id获取room的文档模板
     * */
    getRoomTemplateInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomTemplateInfo", this.data(param));
    },
    /**
     * 同意/拒绝用户共享的room的模板
     * */
    agreeRoomTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/agreeRoomTemplate", this.data(param));
    },
    /**
     * 获取room通知的未读列表
     * */
    getMyRoomNoticeList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getMyRoomNoticeList", this.data(param));
    },
    /**
     *
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addRoomsInFolder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addRoomsInFolder", this.data(param));
    },
    /**
     * 获取全部分类中文件夹及room列表
     * @returns {Promise<AxiosResponse<any>>}
     */
    getFolderAndRoomList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getFolderAndRoomList", this.data(param));
    },
    /**
     * 删除文件夹中的room
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    delFolderRoom(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delFolderRoom", this.data(param));
    },
    /**
     * 编辑审批流内容
     * */
    editExamine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editExamine", this.data(param));
    },
    /**
     * 获取我是房主及管理员管理的room中用户分享的room模板
     * */
    getSharingRoomTemplateList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getSharingRoomTemplateList", this.data(param));
    },
    /**
     * 设置开放room的隐藏和显示
     * */
    setOpenRoomHide(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setOpenRoomHide", this.data(param));
    },
    /**
     * 获取room中的红包问答
     * */
    getRoomQandA(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomQandA", this.data(param));
    },
    /**
     * 设置room的消息为已读
     * */
    redEnvelopesQuestion(param) {
        return axiosUtils.post(store.state.devApiUrl + "/redEnvelopesQuestion", this.data(param));
    },
    /**
     * 获取热门红包问答
     * */
    getHotRedEnvelopesQuestionList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getHotRedEnvelopesQuestionList", this.data(param));
    },
    /**
     * 邀请好友报名事件
     * */
    inviteUsersToEnroll(param) {
        return axiosUtils.post(store.state.devApiUrl + "/inviteUsersToEnroll", this.data(param));
    },
    /**
     * 获取一个用户(好友)得模板列表
     * */
    getMyFriendDiyTemplate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyFriendDiyTemplate", this.data(param));
    },
    /**
     * 删除我得diy模板
     * */
    delMyDiyTemplate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delMyDiyTemplate", this.data(param));
    },
    /**
     * 请求分享一个模板给我
     * */
    requestShareDiyTemplate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/requestShareDiyTemplate", this.data(param));
    },

    /**
     * 主动分享模板给别人
     * */
    activeShareDiyTemplate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/activeShareDiyTemplate", this.data(param));
    },
    /**
     * 获取room默认菜单
     * */
    getRoomDefaultMenu(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomDefaultMenu", this.data(param));
    },

    /**
     * 查看机构账号所有关联的各种room
     * */
    associateTheRoomList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/associateTheRoomList", this.data(param));
    },
    /**
     * 获取私信列表
     * */
    getUserMsgList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserMsgList", this.data(param));
    },

    /**
     * 转发
     * */
    forward(param) {
        return axiosUtils.post(store.state.devApiUrl + "/forward", this.data(param));
    },
    /**
     * 检查有未支付的提问
     * */
    checkQaUnpaid(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkQaUnpaid", this.data(param));
    },
    /**
     * 编辑模板id
     * */
    editDiyTemplate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editDiyTemplate", this.data(param));
    },
    /**
     * 获取我管理的room中申请关联room的请求
     * */
    getRoomRelevanceList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomRelevanceList", this.data(param));
    },
    /**
     * 处理room申请关联的请求
     * */
    processRoomRelevanceRequests(param) {
        return axiosUtils.post(store.state.devApiUrl + "/processRoomRelevanceRequests", this.data(param));
    },
    /**
     * room中的管理员及房主进行room的菜单分享
     * */
    roomShareMenu(param) {
        return axiosUtils.post(store.state.devApiUrl + "/roomShareMenu", this.data(param));
    },
    /**
     * 获取城市的列表
     * */
    getCityList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCityList", this.data(param));
    },
    /**
     * 获取机构room的子room的列表
     * */
    getRoomSubList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomSubList", this.data(param));
    },
    /**
     * 解除room的关联
     * */
    cancelRoomRelevance(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelRoomRelevance", this.data(param));
    },
    /**
     * 设置room的通知消息为已读通过id
     * */
    setRoomIsRead(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setRoomIsRead", this.data(param));
    },
    /**
     * 获取订单日志
     * */
    getExpertOrderLogs(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertOrderLogs", this.data(param));
    },
    /**
     * 订单留言
     * */
    leaveMessage(param) {
        return axiosUtils.post(store.state.devApiUrl + "/leaveMessage", this.data(param));
    },
    /**
     * 获取订单留言
     * */
    getOrderMessage(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getOrderMessage", this.data(param));
    },
    /**
     * 订单评价
     * */
    evaluateExpertOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/evaluateExpertOrder", this.data(param));
    },
    /**
     * 获取订单评价
     * */
    getExpertOrderEvaluate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertOrderEvaluate", this.data(param));
    },
    /**
     * 获取专家评价
     */
    getExpertEvaluateList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertEvaluateList", this.data(param));
    },
    /**
     * 获取room中成员的店铺中的商品
     */
    getRoomShopGoodsList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomShopGoodsList", this.data(param));
    },
    /**
     * 获取room中成员的店铺中的服务
     */
    getRoomShopServiceList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomShopServiceList", this.data(param));
    },
    /**
     * 发布付费文档
     */
    addDoc(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addDoc", this.data(param));
    },

    /**
     * 获取付费文档
     */
    getDocList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getDocList", this.data(param));
    },
    /**
     * 下载付费文档
     */
    downloadDoc(param){
        return axiosUtils.post(store.state.devApiUrl + "/downloadDoc", this.data(param));
    },
    /**
     * 获取付费文档详情
     */
    getDocDetail(param){
        return axiosUtils.post(store.state.devApiUrl + "/getDocDetail", this.data(param));
    },
    /**
     * 添加room的模板
     */
    addRoomDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/addRoomDiyTemplate", this.data(param));
    },
    /**
     * 删除room中的模板
     */
    deleteRoomDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/deleteRoomDiyTemplate", this.data(param));
    },
    /**
     * 编辑room的模板
     */
    editRoomDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/editRoomDiyTemplate", this.data(param));
    },
    /**
     * 分享room中的模板到其他room
     */
    shareRoomDiyTemplate(param){
        return axiosUtils.post(store.state.devApiUrl + "/shareRoomDiyTemplate", this.data(param));
    },
    /**
     * 获取我所在room中的各种分类的事件
     */
    getRoomEventList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomEventList", this.data(param));
    },
    /**
     * 获取由子room分享过来的菜单
     */
    getSubRoomShareMenuList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getSubRoomShareMenuList", this.data(param));
    },
    /**
     * 设置分享的菜单在room中显示
     */
    setSubRoomShareMenuShow(param){
        return axiosUtils.post(store.state.devApiUrl + "/setSubRoomShareMenuShow", this.data(param));
    },
    /**
     * 获取Room下的所有任务序列
     */
    getTaskSequenceList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTaskSequenceList", this.data(param));
    },
    /**
     * 获取任务序列详情
     */
    getTaskSequenceInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTaskSequenceInfo", this.data(param));
    },
    /**
     * 获取room中的shop列表
     */
    getRoomShopList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomShopList", this.data(param));
    },

    /**
     * 获取room专家话题
     */
    getRoomExpertTopicList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomExpertTopicList", this.data(param));
    },
    /**
     * 获取产品信息
     */
    getShopGoodsInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getShopGoodsInfo", this.data(param));
    },
    getStsAuth(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getStsAuth", this.data(param));
    },
    getFileInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getFileInfo", this.data(param));
    },
    ossNotify(param) {
        return axiosUtils.post(store.state.devApiUrl + "/fileUploadCallback", this.data(param));
    },
    /**
     *新增发票信息
     */
    addInvoice(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addInvoice", this.data(param));
    },
    /**
     *修改发票信息
     */
    updateInvoice(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateInvoice", this.data(param));
    },
    /**
     *删除发票信息
     */
    deleteInvoice(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteInvoice", this.data(param));
    },
    /**
     *获取发票地址列表
     */
    getInvoiceList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getInvoiceList", this.data(param));
    },
    /**
     *设置默认地址
     */
    setInvoiceDefault(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setInvoiceDefault", this.data(param));
    },
    /**
     *立即购买
     */
    buyNowGoods(param) {
        return axiosUtils.post(store.state.devApiUrl + "/buyNowGoods", this.data(param));
    },
    /**
     *加入购物车
     */
    addGoodsCart(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addGoodsCart", this.data(param));
    },
    /**
     *购物车列表
     */
    goodsCartList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/goodsCartList", this.data(param));
    },
    /**
     *移除/删除/清空购物车
     */
    removeGoodsCart(param) {
        return axiosUtils.post(store.state.devApiUrl + "/removeGoodsCart", this.data(param));
    },
    /**
     *购物车下单
     */
    goodsCartOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/goodsCartOrder", this.data(param));
    },
    /**
     *获取店铺订单列表
     */
    getShopOrderList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopOrderList", this.data(param));
    },
    /**
     *商家修改产品订单部分数据
     */
    sellerUpdateGoodsOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/sellerUpdateGoodsOrder", this.data(param));
    },
    /**
     *买家签收/确认收货
     */
    confirmReceipt(param) {
        return axiosUtils.post(store.state.devApiUrl + "/confirmReceipt", this.data(param));
    },
    /**
     *买家申请售后(退款/退货/换货)
     */
    goodsRefund(param) {
        return axiosUtils.post(store.state.devApiUrl + "/goodsRefund", this.data(param));
    },
    /**
     *商家或买家取消售后申请
     */
    cancelRefund(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelRefund", this.data(param));
    },
    /**
     *商家处理退款
     */
    shopRefundHandle(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopRefundHandle", this.data(param));
    },
    /**
     *商家确认收到退货商品
     */
    shopReceiveGoods(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopReceiveGoods", this.data(param));
    },
    /**
     *商家处理退货
     */
    shopReturnGoods(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopReturnGoods", this.data(param));
    },
    /**
     *买家修改产品订单部分数据
     */
    buyeUpdateGoodsOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/buyeUpdateGoodsOrder", this.data(param));
    },
    /**
     *购物车结算页面商品信息
     */
    goodsCartSettlement(param) {
        return axiosUtils.post(store.state.devApiUrl + "/goodsCartSettlement", this.data(param));
    },
    /**
     *获取我的草稿箱的草稿列表
     */
    getArticleDraftList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getArticleDraftList", this.data(param));
    },
    /**
     *删除草稿箱的内容
     */
    deleteArticleDraft(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteArticleDraft", this.data(param));
    },
    /**
     *商品订单详情
     */
    shopGoodsInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopGoodsInfo", this.data(param));
    },
    /**
     *立即购买结算页面信息
     */
    buyNowGoodsSettlement(param) {
        return axiosUtils.post(store.state.devApiUrl + "/buyNowGoodsSettlement", this.data(param));
    },
    /**
     *删除订单
     */
    delShopGoodsOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delShopGoodsOrder", this.data(param));
    },
    /**
     *取消订单
     */
    cancelOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelOrder", this.data(param));
    },
    /**
     *商品订单详情
     */
    shopGoodsOrderInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopGoodsOrderInfo", this.data(param));
    },
    /**
     *获取结算页面信息
     */
    buyNowGoodsInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/buyNowGoodsInfo", this.data(param));
    },
    /**
     *检查商品库存
     */
    checkGoodsGoodstStock(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkGoodsGoodstStock", this.data(param));
    },
    /**
     *商品结算页数量加减
     */
    shopGoodsAddAndSubtract(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopGoodsAddAndSubtract", this.data(param));
    },
    /**
     *退款/退货/换货 原因
     */
    goodsRefundReason(param) {
        return axiosUtils.post(store.state.devApiUrl + "/goodsRefundReason", this.data(param));
    },
    payKnowledge(param) {
        return axiosUtils.post(store.state.devApiUrl + "/payKnowledge", this.data(param));
    },
    /**
     *订单继续支付
     */
    shopGoodsResumePay(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopGoodsResumePay", this.data(param));
    },
    /**
     *删除文章的评论
     */
    delComment(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delComment", this.data(param));
    },
    getNoticeList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getNoticeList", this.data(param));
    },
    getRealNoticeNoReadNum(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRealNoticeNoReadNum", this.data(param));
    },
    isReadId(param) {
        return axiosUtils.post(store.state.devApiUrl + "/isReadId", this.data(param));
    },
    getSpaceContent(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getSpaceContent", this.data(param));
    }
    ,
    followAccount(param){
        return axiosUtils.post(store.state.devApiUrl + "/followAccount", this.data(param));
    },
    /**
     * 商家同意/拒绝换货
     */
    shopExchangeGoods(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shopExchangeGoods", this.data(param));
    },
    /**
     * 立即购买数量加减
     */
    buyNowGoodsAddAndSubtract(param) {
        return axiosUtils.post(store.state.devApiUrl + "/buyNowGoodsAddAndSubtract", this.data(param));
    },
    isOnline(param){
        return axiosUtils.post(store.state.devApiUrl + "/isOnline", this.data(param));
    },
    hasOneOnline(param){
        return axiosUtils.post(store.state.devApiUrl + "/hasOneOnline", this.data(param));
    },
    pushMsg(param){
        return axiosUtils.post(store.state.devApiUrl + "/pushMsg", this.data(param));
    },
    exitVideoCallRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/exitVideoCallRoom", this.data(param));
    },
    removeUserVideoCallRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/removeUserVideoCallRoom", this.data(param));
    },
    inviteUserToVideoCallRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/inviteUserToVideoCallRoom", this.data(param));
    },

    /**
     * 编辑部门机构
     * */
    editOrgDepartment(param){
        return axiosUtils.post(store.state.devApiUrl + "/editOrgDepartment", this.data(param));
    },
    /**
     * 解散一个部门
     * */
    delOrgDepartment(param){
        return axiosUtils.post(store.state.devApiUrl + "/delOrgDepartment", this.data(param));
    },
    /**
     * 从部门中通过uuid移除一个成员
     * */
    delUserFromDepartment(param){
        return axiosUtils.post(store.state.devApiUrl + "/delUserFromDepartment", this.data(param));
    },
    /**
     * 获取一个部门中的成员
     * */
    getDepartmentUserList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getDepartmentUserList", this.data(param));
    },
    getDepartmentRemainderUserList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getDepartmentRemainderUserList", this.data(param));
    },
    /**
     * 用户专业认证的学历信息
     * */
    setEduSchoolInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/setEduSchoolInfo", this.data(param));
    },
    /**
     * 用户专业认证中除了学历之外的其他的数据
     * */
    setOtherMajorInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/setOtherMajorInfo", this.data(param));
    },
    /**
     * 设置专家信息中除了（专家简述，专著论文）的其他信息
     * */
    setExpertInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/setExpertInfo", this.data(param));
    },
    /**
     * 设置专家信息中的专著论文
     * */
    setExpertPaper(param){
        return axiosUtils.post(store.state.devApiUrl + "/setExpertPaper", this.data(param));
    },
    /**
     * 设置专家信息中专家简述
     * */
    setExpertIntroduction(param){
        return axiosUtils.post(store.state.devApiUrl + "/setExpertIntroduction", this.data(param));
    },
    /**
     * 获取推荐大V
     * */
    getRecommendBigV(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRecommendBigV", this.data(param));
    },

    /**
     * 获取room中当前内容的评论
     */
    getRoomContentReply(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomContentReply", this.data(param));
    },
    /**
     * 添加room内容的评论
     */
    addRoomContentReply(param){
        return axiosUtils.post(store.state.devApiUrl + "/addRoomContentReply", this.data(param));
    },
    /**
     * 获取采购序列产品订单列表
     */
    getTaskOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTaskOrderList", this.data(param));
    },
    /**
     * 获取Room产品订单列表
     */
    getRoomGoodsOrderList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomGoodsOrderList", this.data(param));
    },

    /**
     * 获取任务详情
     */
    getTaskInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTaskInfo", this.data(param));
    },
    /**
     * 添加任务成果
     */
    addTaskResult(param){
        return axiosUtils.post(store.state.devApiUrl + "/addTaskResult", this.data(param));
    },
    /**
     * 专家添加任务成果的建议
     */
    addTaskExpertResult(param){
        return axiosUtils.post(store.state.devApiUrl + "/addTaskExpertResult", this.data(param));
    },
    /**
     * 任务发布人确认任务
     */
    confirmTask(param){
        return axiosUtils.post(store.state.devApiUrl + "/confirmTask", this.data(param));
    },
    /**
     * 获取采购订单购物车产品
     */
    getTaskOrderGoodsList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getTaskOrderGoodsList", this.data(param));
    },
    /**
     *删除任务序列
     */
    delTaskSequence(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delTaskSequence", this.data(param));
    },
    /**
     *删除任务
     */
    delTask(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delTask", this.data(param));
    },
    /**
     *编辑任务序列
     */
    editTaskSequence(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editTaskSequence", this.data(param));
    },
    /**
     *Room产品订单转化为采购订单
     */
    goodsAndserviceToPurchaseOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/goodsAndserviceToPurchaseOrder", this.data(param));
    },
    /**
     * 获取能转化的序列列表
     */
    getGoodsAndserviceToTaskList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getGoodsAndserviceToTaskList", this.data(param));
    },

    getCalendarEventList(param){
        return axiosUtils.post(store.state.devApiUrl + "/getCalendarEventList", this.data(param));
    },
    addCalendarEvent(param){
        return axiosUtils.post(store.state.devApiUrl + "/addCalendarEvent", this.data(param));
    },
    saveCalendarEvent(param){
        return axiosUtils.post(store.state.devApiUrl + "/saveCalendarEvent", this.data(param));
    },
    deleteCalendarEvent(param){
        return axiosUtils.post(store.state.devApiUrl + "/deleteCalendarEvent", this.data(param));
    },

    /**
     * 获取系统的未读消息
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSystemNoReadNum(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getSystemNoReadNum", this.data(param));
    },
    /**
     * 给一个人发私信
     * @param param
     */
    sendMsg(param) {
        return axiosUtils.post(store.state.devApiUrl + "/sendMsg", this.data(param));
    },

    /**
     * 选择对公对私支付(周)
     */
    chooseExpertPayType(param) {
        return axiosUtils.post(store.state.devApiUrl + "/chooseExpertPayType", this.data(param));
    },
    /**
     * 查询企业未支付的订单
     */
    checkCorpOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkCorpOrder", this.data(param));
    },
    /**
     * 创建企业分账对象申请(延时分账)
     */
    corpPenny(param) {
        return axiosUtils.post(store.state.devApiUrl + "/corpPenny", this.data(param));
    },
    /**
     * 文件夹重命名
     */
    editRoomFolderName(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editRoomFolderName", this.data(param));
    },
    /**
     * room检索
     */
    roomSearch(param) {
        return axiosUtils.post(store.state.devApiUrl + "/roomSearch", this.data(param));
    },
    /**
     * 知识仓库检索
     */
    knowledgeSearch(param) {
        return axiosUtils.post(store.state.devApiUrl + "/knowledgeSearch", this.data(param));
    },

    circleSearch(param) {
        return axiosUtils.post(store.state.devApiUrl + "/circleSearch", this.data(param));
    },

    questionSearch(param) {
        return axiosUtils.post(store.state.devApiUrl + "/questionSearch", this.data(param));
    },

    newsSearch(param) {
        return axiosUtils.post(store.state.devApiUrl + "/newsSearch", this.data(param));
    },
    /**
     * 收藏/取消收藏一个专家
     */
    collectionExpert(param) {
        return axiosUtils.post(store.state.devApiUrl + "/collectionExpert", this.data(param));
    },
    /**
     * 获取专家的收藏列表
     */
    getExpertCollectionList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertCollectionList", this.data(param));
    },

    getNearbyCalendarEventList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getNearbyCalendarEventList", this.data(param));
    },
    getTodayCalendarEventList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getTodayCalendarEventList", this.data(param));
    },
    leaveLiveRoom(param) {
        return axiosUtils.post(store.state.devApiUrl + "/leaveLiveRoom", this.data(param));
    },
    createLiveRoom(param) {
        return axiosUtils.post(store.state.devApiUrl + "/createLiveRoom", this.data(param));
    },
    joinLiveRoom(param) {
        return axiosUtils.post(store.state.devApiUrl + "/joinLiveRoom", this.data(param));
    },
    sendLiveMsg(param) {
        return axiosUtils.post(store.state.devApiUrl + "/sendLiveMsg", this.data(param));
    },
    getLiveRoomInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getLiveRoomInfo", this.data(param));
    },
    /**
     * 微信分享
     */
    getShareContent(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShareContent", this.data(param));
    },

    /**
     *知识仓库我的收藏
     */
    getKwMyCollection(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getKwMyCollection", this.data(param));
    },
    /**
     * 知识仓库我的上传
     */
    getKwMyUpload(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getKwMyUpload", this.data(param));
    },
    getRoomLive(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomLive", this.data(param));
    },
    getExpertOrderList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertOrderList", this.data(param));
    },
    /**
     * 获取服务的More列表
     */
    getServiceList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getServiceList", this.data(param));
    },
    /**
     * 获取产品的More列表
     */
    getGoodsList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getGoodsList", this.data(param));
    },
    /**
     * 获取案例的More列表
     */
    getCaseList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCaseList", this.data(param));
    },
    /**
     * 获取服务/商品评价列表
     */
    getShopAndServiceEvaluateList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopAndServiceEvaluateList", this.data(param));
    },

    /**
     * 添加服务/商品评价
     */
    addShopAndServiceEvaluate(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addShopAndServiceEvaluate", this.data(param));
    },
    /**
     * 搜索一个用户
     * @param param
     */
    searchStranger(param) {
        return axiosUtils.post(store.state.devApiUrl + "/searchStranger", this.data(param));
    },
    checkShopPhone(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkShopPhone", this.data(param));
    },
    /**
     * 拒绝专家咨询订单
     */
    refuseExpertOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/refuseExpertOrder", this.data(param));
    },
    /**
     * 获取草稿箱详情
     */
    getArticleDraftInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getArticleDraftInfo", this.data(param));
    },
    /**
     * 设置/取消子账号的管理员
     * @param param
     */
    setSubAccountAdmin(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setSubAccountAdmin", this.data(param));
    },
    getRoomSubAccountList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomSubAccountList", this.data(param));
    },
    /**
     * 发布方发布对成果的意见
     */
    addTaskResultAsk(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addTaskResultAsk", this.data(param));
    },
    /**
     * 发布方删除成果的意见
     */
    delTaskResultAsk(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delTaskResultAsk", this.data(param));
    },
    /**
     * 编辑任务
     */
    editTask(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editTask", this.data(param));
    },
    /**
     * 修改用户的地址
     * @param param
     */
    setUserAddress(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setUserAddress", this.data(param));
    },
    /**
     * 更新用户登录时间
     * @param param
     */
    updateLoginTime(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateLoginTime", this.data(param));
    },
    /**
     * 获取首页订阅
     */
    getSubscribe(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getSubscribe", this.data(param));
    },
    /**
     * 获取More日历事件列表
     */
    recordSubscription(param) {
        return axiosUtils.post(store.state.devApiUrl + "/recordSubscription", this.data(param));
    },
    /**
     * 编辑头条文章
     */
    editHeadLine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editHeadLine", this.data(param));
    },
    /**
     * 删除头条文章
     */
    delHeadLine(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delHeadLine", this.data(param));
    },
    /**
     * 编辑回复
     */
    editComment(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editComment", this.data(param));
    },
    /**
     * 修改日历事件
     */
    updateCalendarEvent(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateCalendarEvent", this.data(param));
    },
    /**
     * 获取More日历事件列表
     */
    getCalendarEventMoreList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCalendarEventMoreList", this.data(param));
    },
    /**
     * 获取room中的口令接口，只能获取到自己创建的
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getRoomPassword(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomPassword", this.data(param));
    },
    /**
     * 删除一个我的room
     * @param param
     */
    delRoom(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delRoom", this.data(param));
    },
    /**
     * 专家连线的咨询发送私信
     * @param param
     */
    consultExpert(param) {
        return axiosUtils.post(store.state.devApiUrl + "/consultExpert", this.data(param));
    },
    /**
     * 查找room内的es搜索
     * @param param
     */
    roomContentSearch(param) {
        return axiosUtils.post(store.state.devApiUrl + "/roomContentSearch", this.data(param));
    },
    /**
     * 获取More日历事件列表
     */
    addShopDrafts(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addShopDrafts", this.data(param));
    },
    /**
     * 获取创建商铺参数
     */
    getShopDrafts(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShopDrafts", this.data(param));
    },
    /**
     * 机构绑卡确认
     */
    corpBankCardConfirm(param) {
        return axiosUtils.post(store.state.devApiUrl + "/corpBankCardConfirm", this.data(param));
    },
    /**
     * 获取我购买的知识仓库列表
     */
    getMyPayKnowledgeList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyPayKnowledgeList", this.data(param));
    },
    /**
     * 更新用户的推荐好友的分类/room广场的分类
     */
    updateUserRecommendType(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateUserRecommendType", this.data(param));
    },
    /**
     * 获取用户多账户信息
     * @param param
     */
    getLoginCodeList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getLoginCodeList", this.data(param));
    },
    /**
     * 效验修改密码验证码
     * @param param
     */
    checkCodePwd(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkCodePwd", this.data(param));
    },
    /**
     * 商务信息筛选
     */
    getBusinessInfoByType(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getBusinessInfoByType", this.data(param));
    },
    /**
     * 获取我购买的知识仓库
     */
    getMyPayKnowledge(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyPayKnowledge", this.data(param));
    },
    /**
     * 问答提问搜索接口
     */
    qaSearch(param) {
        return axiosUtils.post(store.state.devApiUrl + "/qaSearch", this.data(param));
    },
    /**
     * 获取文件柜文件
     */
    getMyFileCabinet(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyFileCabinet", this.data(param));
    },
    /**
     * 添加文件柜文件
     */
    addFileCabinet(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addFileCabinet", this.data(param));
    },
    /**
     * 删除文件柜文件
     */
    delFileCabinet(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delFileCabinet", this.data(param));
    },
    /**
     * 更新用户的推荐好友的分类/room广场的分类
     * @param param
     */
    getUserRecommendType(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserRecommendType", this.data(param));
    },
    /**
     * 钱包流水
     */
    walletFlow(param) {
        return axiosUtils.post(store.state.devApiUrl + "/walletFlow", this.data(param));
    },
    /**
     * 收益流水
     */
    revenueFlow(param) {
        return axiosUtils.post(store.state.devApiUrl + "/revenueFlow", this.data(param));
    },
    /**
     * 支出流水
     */
    expenditureFlow(param) {
        return axiosUtils.post(store.state.devApiUrl + "/expenditureFlow", this.data(param));
    },
    /**
     * 取现流水
     */
    cashFlow(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cashFlow", this.data(param));
    },
    /**
     * 获取帮助/规则的内容
     */
    getHelpInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getHelpInfo", this.data(param));
    },
    /**
     * 帮助中心
     */
    getHelpCenter(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getHelpCenter", this.data(param));
    },
    /**
     * 通知公告
     */
    getAnnouncementList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAnnouncementList", this.data(param));
    },
    /**
     * 检查room中是否有同名的room
     * @param param
     */
    checkRoom(param) {
        return axiosUtils.post(store.state.devApiUrl + "/checkRoom", this.data(param));
    },
    /**
     * 专家咨询订单申请退款
     */
    applyRefund(param) {
        return axiosUtils.post(store.state.devApiUrl + "/applyRefund", this.data(param));
    },
    /**
     * 专家咨询订单退款
     */
    getIndexHelpList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getIndexHelpList", this.data(param));
    },
    /**
     * 获取支付产品名称
     */
    getPayProductName(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getPayProductName", this.data(param));
    },
    /**
     * 取消收藏
     */
    cancelCollection(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelCollection", this.data(param));
    },

    /**
     * 获取脱敏的好友列表
     */
    getWalletcFriendList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getWalletcFriendList", this.data(param));
    },
    /**
     * 获取脱敏的好友列表
     */
    getvalidationRealName(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getvalidationRealName", this.data(param));
    },
    /**
     * 设置个人信息
     * @param param
     */
    setMyInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setMyInfo", this.data(param));
    },
    /**
     * 查询我的档案
     * @param param
     */
    getMyArchives(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyArchives", this.data(param));
    },
    /**
     * 设置我的教育经历
     * @param param
     */
    setSchoolInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setSchoolInfo", this.data(param));
    },
    /**
     * 提交审核状态
     * @param param
     */
    saveExamineStatus(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveExamineStatus", this.data(param));
    },
    /**
     * 查询结算账户余额对象
     */
    getAccountBalance(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAccountBalance", this.data(param));
    },
    /**
     * 获取钱包用户信息
     */
    getUserWalletInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getUserWalletInfo", this.data(param));
    },
    /**
     * 添加/修改预支付订单
     */
    addAndSavePrePayOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addAndSavePrePayOrder", this.data(param));
    },
    /**
     * 获取预支付订单
     */
    getPrePayOrder(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getPrePayOrder", this.data(param));
    },
    /**
     * 钱包转账人列表
     */
    getRecentTransferList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRecentTransferList", this.data(param));
    },
    /**
     * 获取绑卡的完整手机号
     */
    getWalletCardPhone(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getWalletCardPhone", this.data(param));
    },
    /**
     * 获取绑定的对公账户详情
     */
    getCorpBanCardInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCorpBanCardInfo", this.data(param));
    },
    /**
     * 查询个人空间的信息
     * @param param
     */
    getSpaceInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getSpaceInfo", this.data(param));
    },
    /**
     * 设置我的工作经历
     * @param param
     */
    setWorkInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setWorkInfo", this.data(param));
    },
    /**
     * 设置我的项目经历
     * @param param
     */
    setProjectInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setProjectInfo", this.data(param));
    },
    /**
     * 设置我的荣誉
     * @param param
     */
    setHonorInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setHonorInfo", this.data(param));
    },
    /**
     * 设置其他佐证材料
     * @param param
     */
    setOtherInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setOtherInfo", this.data(param));
    },
    /**
     * 设置我的专业认证->职称证书
     * @param param
     */
    setPositionalTitles(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setPositionalTitles", this.data(param));
    },
    /**
     * 设置我的专业认证->职业资格证书
     * @param param
     */
    setOccupation(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setOccupation", this.data(param));
    },
    /**
     * 设置我的专业认证->其他类别证书
     * @param param
     */
    setOtherCert(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setOtherCert", this.data(param));
    },
    /**
     * 删除教育经历
     * @param param
     */
    deleteSchoolInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteSchoolInfo", this.data(param));
    },
    /**
     * 删除专业认证的信息
     * @param param
     */
    deletePositionalInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deletePositionalInfo", this.data(param));
    },
    /**
     * 提交教育的认证
     * @param param
     */
    submitEducationAudit(param) {
        return axiosUtils.post(store.state.devApiUrl + "/submitEducationAudit", this.data(param));
    },
    /**
     * 提交专业认证
     * @param param
     */
    submitMajorAudit(param) {
        return axiosUtils.post(store.state.devApiUrl + "/submitMajorAudit", this.data(param));
    },
    /**
     * 设置用户的昵称/简介/标签
     * @param param
     */
    saveUserBasicInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveUserBasicInfo", this.data(param));
    },
    /**
     * 分享名片给对方
     * @param param
     */
    shareVisitingCard(param) {
        return axiosUtils.post(store.state.devApiUrl + "/shareVisitingCard", this.data(param));
    },
    /**
     * 设置机构资质证书
     * @param param
     */
    setQualification(param) {
        return axiosUtils.post(store.state.devApiUrl + "/setQualification", this.data(param));
    },
    /**
     * 取消报名
     * @param param
     */
    cancelEnroll(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelEnroll", this.data(param));
    },
    /**
     * 删除问答的回复
     */
    deleteQuestionReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteQuestionReply", this.data(param));
    },
    /**
     * 添加/修改预支付订单
     */
    getAllRepInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getAllRepInfo", this.data(param));
    },

    /**
     * 问答点赞
     */
    addQuestionLike(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addQuestionLike", this.data(param));
    },
    /**
     * 保存社会头衔
     */
    saveExpertTitle(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveExpertTitle", this.data(param));
    },
    /**
     * 保存专家专家自述
     */
    saveExpertContent(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveExpertContent", this.data(param));
    },
    /**
     *设置专家专著
     */
    saveExpertMonograph(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveExpertMonograph", this.data(param));
    },
    /**
     * 设置论文
     */
    saveExpertPaper(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveExpertPaper", this.data(param));
    },
    /**
     * 设置其他情况
     */
    saveExpertOther(param) {
        return axiosUtils.post(store.state.devApiUrl + "/saveExpertOther", this.data(param));
    },
    /**
     * 专家提交审核
     */
    submitExpert(param) {
        return axiosUtils.post(store.state.devApiUrl + "/submitExpert", this.data(param));
    },
    /**
     * 查询我的专家临时编辑信息
     */
    getExpertTempInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getExpertTempInfo", this.data(param));
    },
    /**
     * 支付订单列表
     */
    getPayOrderList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getPayOrderList", this.data(param));
    },

    /**
     * 删除活动
     */
    deleteActivity(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteActivity", this.data(param));
    },
    /**
     * 取消活动
     */
    cancelActivity(param) {
        return axiosUtils.post(store.state.devApiUrl + "/cancelActivity", this.data(param));
    },
    /**
     * 删除社会头衔
     */
    delExpertTitle(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delExpertTitle", this.data(param));
    },
    /**
     * 删除论文
     */
    delExpertPaper(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delExpertPaper", this.data(param));
    },
    /**
     * 删除专家专著
     */
    delExpertMonograph(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delExpertMonograph", this.data(param));
    },
    /**
     * 获取我的粉丝
     */
    getFansList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getFansList", this.data(param));
    },
    /**
     * 获取我的关注列表
     */
    getFollowList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getFollowList", this.data(param));
    },
    /**
     * 删除room内容的评论
     */
    delRoomContentReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delRoomContentReply", this.data(param));
    },
    /**
     * 编辑标签
     */
    editCollectionLabel(param) {
        return axiosUtils.post(store.state.devApiUrl + "/editCollectionLabel", this.data(param));
    },
    /**
     * 获取问答分账明细
     * @param param
     */
    getBonusDetail(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getBonusDetail", this.data(param));
    },
    /**
     * 删除红包问答提问
     */
    deleteQuestion(param) {
        return axiosUtils.post(store.state.devApiUrl + "/deleteQuestion", this.data(param));
    },
    /**
     * 效验首购会员价格
     */
    validationVipPrice(param) {
        return axiosUtils.post(store.state.devApiUrl + "/validationVipPrice", this.data(param));
    },
    /**
     * 效验首购空间价格
     */
    validationSpacePrice(param) {
        return axiosUtils.post(store.state.devApiUrl + "/validationSpacePrice", this.data(param));
    },

    /**
     * 修改预支付订单MemberId
     */
    savePerOrderMemberId(param) {
        return axiosUtils.post(store.state.devApiUrl + "/savePerOrderMemberId", this.data(param));
    },
    /**
     * 获取我的专家话题
     */
    getMyExpertTopic(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyExpertTopic", this.data(param));
    },
    /**
     *获取专家中邀请专家进入room的room列表
     */
    getMyRoomListAndOtherUser(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMyRoomListAndOtherUser", this.data(param));
    },
    preservationReply(param) {
        return axiosUtils.post(store.state.devApiUrl + "/preservationReply", this.data(param));
    },
    /**
     * 获取知识仓库菜单内容
     */
    getRoomMenuKwList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getRoomMenuKwList", this.data(param));
    },
    /**
     * 获取扫码文件
     */
    getCodeFiles(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCodeFiles", this.data(param));
    },
}

export default newApi
