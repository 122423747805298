<template>
	<div class="bg_shadow fade auth">
		<div class="center_phone" style="position:relative; top: 6%;" v-if="user.userType === 1">
			<div style="padding-top: 10px">
				<div class="head" style="display: flex;">
					<div class="title" style="color: #2970FF;text-align: center;width: 100%;">{{ title }}</div>
					<i class="el-icon-close op sc hand" style="color:#666666;font-size:22px;position:relative;top: 10px;right: 10px" @click="onCloseAuth"></i>
          </div>
			</div>
			<div class="content">
				<div class="body-auth">
					<div v-if="type === 'realName'">
						<div style="font-size: 16px; margin-bottom: 16px">
							完善身份信息，体验更多服务
						</div>
						<div style="color: #999999;">实名认证——有助于建立完善可靠的互联网信用基础。
							认证成功后开放百工宝基础功能。
						</div>
					</div>
					<div v-if="type === 'major' || type === 'edu' || type === 'career'">
						<div style="font-size: 16px; margin-bottom: 16px">
							专业社交，快速组建上下游关系链
						</div>
						<div style="color: #999999;">专业认证——对用户专业程度进行验证赋予专业标记。
							认证成功后开放进阶版百工宝功能。
						</div>
					</div>
					<div v-if="type === 'expert'">
						<div style="font-size: 16px; margin-bottom: 16px">
							成为专家，有偿为他人答疑解惑传授经验
						</div>
						<div style="color: #999999;">专家认证——需要在专家专业领域内有所建树的人才。
							认证成功后开放专家空间功能。
						</div>
					</div>
					<div style="display: flex; justify-content: center; margin-top: 20px">
						<div class="btn hand op" @click="toAuth">去认证</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="center_phone" style="position:relative;height: 280px; top: 6%;background-position-y: 20px" v-if="user.userType === 2">
			<div>
				<i class="el-icon-close op sc hand" style="color:#666666;font-size:22px;position:absolute;right:10px;top:10px;" @click="onCloseAuth"></i>
			</div>
			<div class="content">
				<div class="body-auth" style="padding-top: 80px">
					<div>
						<div style="font-size: 16px; margin-bottom: 16px">
							官方认证，助您赢得市场信赖
						</div>
						<div style="color: #999999;">机构认证——认证有助于提高机构的公信力和竞争力。
							认证成功后开放机构空间、机构子账号功能。
						</div>
					</div>
					<div style="display: flex; justify-content: center; margin-top: 20px">
						<div class="btn hand op" @click="toAuth">去认证</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
	name: 'AuthWindow',
	computed: {
		...mapGetters(['getShowLoginPopup', 'getShowAuthPopup']),
		user() {
			return this.$store.state.user;
		},
		is_login() {
			return this.$store.state.is_login
		},
		type() {
			return this.getShowAuthPopup;
		},
	},
	data() {
		return {
			title: '实名认证',
		}
	},
	
	mounted() {
		if (this.type === 'major') {
			this.title = "专业认证"
		} else if (this.type === 'edu') {
			this.title = "教育认证"
		} else if (this.type === 'career') {
			this.title = "职业认证"
		} else if (this.type === 'expert') {
			this.title = "专家认证"
		}
	},
	methods: {
		...mapMutations(['setShowAuthPopup']),
		onCloseAuth() {
			this.setShowAuthPopup(null);
		},
		// 认证跳转
		toAuth() {
			switch (this.type) {
				case 'realName':
					this.utils.a('/auth');
					break;
				case 'major':
				case 'edu':
				case 'career':
					this.utils.a('/archives');
					break;
				case 'expert':
					this.utils.a('expert/become');
					break;
			}
			this.onCloseAuth();
		},
	}
};
</script>
<style scoped>
.bg_shadow {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1213;
	background-color: rgba(0, 0, 0, .6);
	top: 0;
	left: 0;
}

.center_phone {
	background: #fff;
	border-radius: 10px;
	width: 420px;
	height: 300px;
	position: absolute;
	background-image: url("../../../assets/imgs/index/authBody.png"), url("../../../assets/imgs/index/authBack.png");
	background-position: center; /* 水平和垂直都居中对齐 */
	background-repeat: no-repeat; /* 不重复平铺背景图像 */
	margin: 10% auto;
}

.auth .content {
	padding-top: 26px;
	width: 100%;
	height: 100%;
}

.auth .head {
	width: 96%;
	height: 36px;
	line-height: 40px;
	padding: 2px 0;
	margin: 0 10px;
	background-color: #FFFFFF;
	box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
}

.auth .title {
	font-size: 16px;
	line-height: 40px;
	font-weight: 600;
}

.auth .body-auth {
	padding: 60px 40px 20px 40px;
	text-align: center;
}

.auth .btn {
	width: 100px;
	padding: 6px 0;
	text-align: center;
	color: #FFFFFF;
	font-size: 16px;
	background: #2E73FF;
	border-radius: 5px 5px 5px 5px;
}
</style>
