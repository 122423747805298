<template>
	<div v-show="props.compType === 'space'">
		<!-- <el-form-item label="字段名">
		  <el-input class="input" v-model="props"></el-input>
		</el-form-item> -->
		<el-form-item label="ID">
			<el-tooltip class="item" effect="dark" content="请注意,ID的修改可能会导致该组件相关事件失效！"
						placement="left">
				<el-input class="input" v-model="props.id"></el-input>
			</el-tooltip>
		</el-form-item>


		<el-form-item label="空白占位高度">
			<el-input-number v-model="props.heightPx" :step="10"></el-input-number>
		</el-form-item>



	</div>
</template>
<script>
/**
 * input的配置项
 */
export default {
	name: "buttonConfig",
	props: {
		props: {}
	},
	components: {},
	data() {
		return {}
	},
	methods: {

	},
	mounted() {
	}
}
</script>
