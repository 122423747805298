/**
 * 警告
 */
export let simpleImage = {
    id: '',
    _id: '',
    url: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png',
    compType: 'simpleImage',
    ele: 'simpleImage',
    compName: '普通图片',
    compIcon: 'simpleImage',
    //展示表单的模式
    viewType: 'component',
    config: true,

    form: true, //是否表单组件
    // 控件左侧label内容
    showLabel: false,
    labelWidth: '0',
    label: '图片',
    span: 24,
    value: 'formDesigner',
    lineColor: '#000',
    background: '#fff',
    width: 5,
    height: 30,
    fontSize: 12,
    displayValue: true,
    showInView:true
}
