import Vue from 'vue'; // 引入VUE核心库
import Vuex from 'vuex'// 引入VUEX核心库  版本号在3.6.2以下的vuex适用于vue2
import createPersistedState from 'vuex-persistedstate';
import newApi from '../utils/newApi';



/**
 * vuex 是一个对数据进行集中式管理的工具
 * 1.数据传值繁琐问题
 * 2.实现了数据的共享
 * 我在组件 a 中用到这个方法，我在组件 b 中也用到这个方法，那么就可以把这个方法统一交给 vuex去完成。如果没有vuex，那我的组件a和b都要单独来设置一个方法 比如判断全局的登录状态
 * 由五部构成
 * 1. state：数据状态-------------------------------------  仓库当中的商品
 * 2. mutations：函数，同步操作。更改state----------------   工人。用来操作商品
 * 3. actions:函数，异步操作。通过调用mutations来更改数据---  老板，发送命令
 * 4. getters:计算属性
 * 5. modules：模块化。
* */

Vue.use(Vuex); // 告诉 vue “使用” vuex

const store = new Vuex.Store({  // 创建了一个VUEX仓库。

    // 数据状态
    state: {
        //userPermissions: [], // 用户权限:登陆'login', 实名'realName',专业'major',专家'expert',职业'career',学历'edu'
        showLoginPopup: false, // 控制登录弹窗显示
        showAuthPopup: null,//控制认证弹窗显示
        pendingRoute: null, // 待导航的路径
        systemCount: 0,
        msgCount: 0,
        noticeCount: 0,
        is_login:0,

        devApiUrl:"https://service.baigongbao.com/Api",

        //url是统一配置的前端地址，主要用于微信/qq登录回调
        bgbUrl:"https://www.baigongbao.com/",
        ossUrl : "https://oss.baigongbao.com/",//oss上传回调使用
        mobileUrl : "https://mobile.baigongbao.com/", //移动端地址
        upload_info:[],
        GuideData:{
            active:false,
            stepList:[]
        },
        videoCall:{
            status:0, //0隐藏 1通话中 2等待接听
            roomId:"",
            id: '',
            createTime:0,
            userList:[]
        },
        user:{
            nickName:"未登录",
            bgColor:"666",
            headIco:"https://baigongbao.oss-cn-beijing.aliyuncs.com/img/head.png",
            status:"1",
        },
        subList:[],
        router:window.location.pathname,
        headerShow:false,
        roomShow:false,
        like:"",
        // 支付参数
        payParams:{
            type:1,  //type = 1支付 2实时转账 3延时分账  必填
            memberId:"",  //商户下的用户id 唯一 必填
            collectionUuid:"",  //收款方的uuid
            orderNo:"",  //订单号
            payType:0,  //支付类型
            payAmt:0,  //交易金额，必须大于0 必填
            originalAmount:0, //原始金额
            remark:"", //备注(转账用)
        }
    },

    // 更改数据状态。通过mutations来更改数据状态是唯一的正途。是一个函数，函数的名字即是mutations的名字。建议大写。
    mutations: {
        setLike(state,data){
            state.like = data;
        },
        setGuideData(state,data){
            state.GuideData.active = data.active;
            state.GuideData.stepList = data.stepList;
        },
        setHeaderShow(state,data){
            state.headerShow = data;
        },
        setRoomShow(state,data){
            state.roomShow = data;
        },
        closeVideoCall(state){
            console.log("closeVideoCall");
            state.videoCall.status = 0;
            state.videoCall.roomId = "";
            state.videoCall.createTime = 0;
            state.videoCall.userList = [];
        },
        setVideoCall(state,data){
            if (data.status){
                state.videoCall.status = data.status
            }
            if (data.roomId){
                state.videoCall.roomId = data.roomId
            }
            if (data.id){
                state.videoCall.id = data.id
            }
            if (data.createTime){
                state.videoCall.createTime = data.createTime
            }
            if (data.userList){
                state.videoCall.userList = data.userList
            }
        },
        setCropper(state,data){
            state.cropper.img = data.img;
            state.cropper.width = data.width;
            state.cropper.height = data.height;
            state.cropper.active = data.active;
        },
        //登录以后刷新用户信息
        get_user_info(state,CallBack){
            var that = this;
            if(localStorage.token != '' && typeof localStorage.token != 'undefined'){
                newApi.getUserInfo({}).then((res)=>{
                    state.user = res.data;
                    state.is_login = 1;
                    if (typeof(CallBack) == "function") {
                        CallBack(res.data);
                    }
                })
            }else {
                state.is_login = 0;
                state.user={}
                window.localStorage.clear();
            }
        },
        login_out(state){
            if(localStorage.token == ""){
                state.is_login = 0;
                state.user={}
                window.localStorage.clear();
            }
        },
        //upload_info 存储App.vue首页进度条所需参数
        set_upload_info(state,ui){
            var has = 0;
            for (var i = state.upload_info.length - 1; i >= 0; i--) {
                if (state.upload_info[i].name == ui.name) {
                    has = 1;
                    state.upload_info[i].percent = ui.percent;
                    if (ui.percent == 100) {
                        state.upload_info.splice(i,1);
                        return;
                    }
                }
            }
            if (has == 0 && ui.percent != 100) {
                state.upload_info.push(ui);
            }
        },
        setShowLoginPopup(state, show) {
            state.showLoginPopup = show;
        },
        setPendingRoute(state, route) {
            state.pendingRoute = route;
        },
        setShowAuthPopup(state, show) {
            state.showAuthPopup = show;
        },
    },

    // 行为。异步操作。通过该操作可以获得新的数据，得到数据以后可以通过调用mutations来更改数据状态。
    actions: {
    
    },

    //模块化
    modules: {      },

    //计算属性
    getters:{
        getShowLoginPopup: state => state.showLoginPopup,
        getPendingRoute: state => state.pendingRoute,
        getShowAuthPopup: state => state.showAuthPopup,
    },
    plugins: [createPersistedState({
        storage: window.localStorage, // 或者 localStorage
        paths: ['user','is_login'] // 只持久化user状态
    })]


})
export default store;
