<template>
  <div class="center_login" style="position:relative;">
    <div style="padding: 20px">
      <div style="display: flex;">
        <div style="font-size: 18px; font-weight: 600; width: 80%;">该手机号下绑定了多个账号，请手动选择后进行登录</div>
      </div>
      <div style="margin-top: 30px">
        <div :class="{'select-border': selectLogin === index}" v-for="(item, index) in userOptions" :key="index" class="card hand">
          <div style="display: flex; justify-content: space-between; width: 100%; font-size: 16px; font-weight: 600; line-height: 30px">
            <div style="display: flex; width: 70%">
              <div style="width: 45%">
                <el-radio v-model="selectLogin" :label="index" :disabled="item.isDisabled" @change="changeSelect(index)">{{item.accoun}}</el-radio>
              </div>
              <div style="display: flex; width: 55%">
                <img :src="item.headIco" style="width: 30px; height: 30px; margin-right: 10px; border-radius: 50%">
                <div v-if="item.isDisabled == false">{{item.nickName}}</div>
                <div v-if="item.isDisabled == true">{{item.nickName}}<span style="color: red;">({{item.describe}})</span></div>
              </div>
            </div>

            <div style="display: flex;">
              <div class="btn" style="color: #FF9800; border: 2px #FF9800 solid; margin-right: 10px" v-if="index === 0">上次登录</div>
              <div class="btn" style="color: #4CAF50; border: 2px #4CAF50 solid" v-if="item.userType ===1"><i class="el-icon-user-solid"></i>个人账号</div>
              <div class="btn" style="color: #2E57EE; border: 2px #2E57EE solid" v-if="item.userType ===2"><i class="el-icon-s-shop"></i>企业账号</div>
              <div class="btn" style="color: #409EFF; border: 2px #409EFF solid" v-if="item.userType ===3">机构子账号</div>
            </div>
          </div>
          <div class="triangle" v-if="selectLogin === index">
            <i class="el-icon-check tr-icon"></i>
          </div>
        </div>
      </div>
      <div class="hand op" @click="toLogin" style="margin: 0 auto; margin-top:20px;left: 25%; font-size: 18px; border-radius: 5px; font-weight: 600; text-align: center; background-color: #2E57EE; color: #FFFFFF; padding: 6px 0; width: 400px">进入百工宝</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSelection.vue",
  props:{
    userOptions:{
      type:Array,
      default(){
        return []
      }
    },
    onClose:{
      type:Function,
      default(){
        return function (){}
      }
    }
  },
    computed:{
        user(){
            return this.$store.state.user;
        },
    },
  data(){
    return{
      selectLogin:0,
    }
  },
  mounted() {
      for (let i = 0; i < this.userOptions.length; i++) {
          if (this.userOptions[i].uuid == this.user.uuid) {
              this.selectLogin = i;
          }
      }
  },
  methods:{
      // 切换选中
      changeSelect(index){
          let that = this;
          that.selectLogin = index
      },
        toLogin(){
          let that = this
          localStorage.token = '';
          localStorage.uuid = '';
          this.$store.commit("login_out");
          that.utils.sus("登录成功");
          localStorage.token = that.userOptions[that.selectLogin].token;
          localStorage.uuid = that.userOptions[that.selectLogin].uuid;
          that.$store.commit("get_user_info");
          that.newApi.updateLoginTime({uuid: that.userOptions[that.selectLogin].uuid})
          that.onClose();
          that.$router.push({ path: '/' })
        },
  }
}
</script>

<style scoped>
.center_login{
  background:#fff;
  border-radius:10px;
  width:800px;
  height: auto;
  position:absolute;
}
.card {
  width: 94%;
  height: 30px;
  display: flex;
  padding: 10px 20px;
  margin-bottom: 10px;
  position: relative;
  background-color: #F6F6F6;
}
.card .triangle{
  position:absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 14px solid #fff;
  border-bottom-color: #2970FF;
  border-right-color: #2970FF;
  border-top-color:transparent ;
  border-left-color: transparent;
}

.card .tr-icon {
  color: #FFFFFF;
  position:absolute;
  font-weight: 600;
}

.card .btn {
  padding: 2px 10px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 20px;
}
</style>
