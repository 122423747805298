<template>
    <div style="width: 100%">
        <div v-if="user.userType == 1">
          <div class="center_phone">
            <div style="text-align: center;">
              <div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">认证实名信息</div>
              <div style="color: #999999; padding: 0 48px">根据国家相关法律规定，您必须经过实名认证后
                方可发布公共性信息。另外您可以完整体验
                朋友圈、room、shop和钱包的功能</div>
            </div>
            <div style="padding: 20px 30px 30px 30px; max-height: 600px; overflow: auto">
              <div v-if="!qrShow">
                <el-form :model="nameForm" :rules="nameRules" ref="resumeForm">
                  <div>
                    <div class="info-title">
                      真实姓名
                    </div>
                    <el-form-item prop="realName">
                      <el-input :disabled="first === true" class="input" placeholder="请输入您的真实姓名" v-model="nameForm.realName" maxlength="20"></el-input>
                    </el-form-item>
                  </div>
                  <div style="margin-top: 20px">
                    <div class="info-title">
                      身份证号
                    </div>
                    <el-form-item prop="idCardNo">
                      <el-input
                          :disabled="first === true"
                          :show-word-limit="true" class="input"
                          placeholder="请输入正确的18位身份证号码"
                          v-model="nameForm.idCardNo" maxlength="18"></el-input>
                    </el-form-item>
                  </div>
                </el-form>
                <div style="display: flex; justify-content: end;">
                  <el-button type="primary" class="save-btn" @click="updateInfo">下一步</el-button>
                </div>
              </div>
              <div v-if="qrShow">
                <div v-if="qrShow" style="text-align: center">
                  <vue-qr
                      :text="qr"
                      :size="250"
                      :logoScale="0.2">
                  </vue-qr>
                </div>
                <div style="display: flex; justify-content: end;">
                  <el-button type="primary" class="save-btn" @click="onClose">完成</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  v-if="user.userType == 2">
          <div class="center_phone">
            <div style="text-align: center;">
              <div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">企业认证实名信息</div>
              <div style="color: #999999; padding: 0 48px">根据国家相关法律规定，您必须经过实名认证后
                方可发布公共性信息。另外您可以完整体验
                room、shop和钱包的功能</div>
            </div>
            <div v-if="qrShow === false" style="padding: 20px 30px 30px 30px; max-height: 600px; overflow: auto">
              <el-form :model="organForm" :rules="organRules" ref="organForm">
                <div>
                  <div class="info-title">
                    法人身份证号
                  </div>
                  <el-input type="textarea"
                            resize="none"
                            :rows="1"
                            v-model="organForm.idCardNo"
                            maxlength="18"
                            :show-word-limit="true"
                            :disabled="first === true"
                            style="margin-right: 16px; line-height: 22px; width: 300px">
                  </el-input>
                </div>

                <div style="margin-top: 20px">
                  <div class="info-title">
                    上传企业营业执照
                  </div>
                  <el-upload accept="image/*" class="upload-demo" action="" :show-file-list="false" drag :http-request="($event)=>uploadImg($event, 'codeImg')" :on-remove="$event => removeImg($event, 'codeImg')">
                    <img v-if="organForm.url" width="100%" height="338px" :src="organForm.url"/>
                    <i class="el-icon-plus"></i>
                    <div style="font-size: 16px; color: #999999">点击或拖动上传图片</div>
                  </el-upload>
                </div>
              </el-form>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <el-button type="primary" class="save-btn" @click="updateOrganInfo">下一步</el-button>
              </div>
            </div>
            <div v-if="qrShow === true" style="padding: 20px 30px 30px 30px; max-height: 600px; overflow: auto">
              <div v-if="qrShow" style="text-align: center">
                <vue-qr
                    :text="qr"
                    :size="250"
                    :logoScale="0.2">
                </vue-qr>
              </div>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <el-button type="primary" class="save-btn" @click="onClose">完成</el-button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },
        watch:{
            user: {
                handler: 'onUserChange',
                deep: true // 如果user是一个对象，并且你想监视嵌套属性，设置deep为true
            },
        },
        data() {
            return {
              // 认证
              qr:'',
              first: false,
              qrShow:false,
              faceStatus: {},
              nameForm: {
                realName: '',
                idCardNo: '',
                userType: 1
              },
              nameRules: {
                realName: [
                  { required: true, message: '请输入真实姓名', trigger: 'blur' },
                  { pattern: /^[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}$/, message: '请输入真实姓名', trigger: 'blur'},
                ],
                idCardNo: [
                  { required: true, message: '请输入正确身份证号码', trigger: 'blur' },
                  { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码', trigger: 'blur'},
                  { min: 18, max: 18, message: '身份证号码格式错误', trigger: 'blur'}
                ],
              },
              organForm: {
                idCardNo: '',
                url: '',
                qualifications: [],
                certificate: []
              },
              organRules: {
                idCardNo: [
                  { required: true, message: '请输入正确的身份证号码', trigger: 'blur' },
                  { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码', trigger: 'blur'},
                  { min: 18, max: 18, message: '身份证号码格式错误', trigger: 'blur'}
                ]

              },
                srcList:[],
            };
        },
      mounted: function () {
        this.$EventBus.$on('faceData', (data) => {
          this.faceStatus = data;
          if (this.faceStatus.code == '200') {
            this.utils.sus(this.faceStatus.msg);
            this.$router.push('/');
            this.onClose();
            this.qrShow = false
          } else {
            this.utils.sus(this.faceStatus.msg);
            this.qrShow = false
          }
        })
      },
      methods: {
        onClose() {},

        // 上传图片
        uploadImg(params, e) {
          var that = this;
          var file = params.file;
          const isLt5M = file.size / 1024 / 1024 < 20;
          if (isLt5M) {
            if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
              that.utils.upload(file, function (url) {
                if (!url) {
                  return false;
                }
                if (e === 'codeImg') {
                  that.organForm.url = url.url
                }
              })
            } else {
              this.$message.error('只能上传图片类型!')
            }

          } else {
            this.$message.error('上传文件大小不能超过 20MB!')
          }
        },

        // 移除图片
        removeImg($event, e) {
          if (e === 'codeImg') {
            this.organForm.url = ''
          }
        },

        /**
         * 人脸识别初始化
         * @constructor
         */
        initFaceVerify() {
          var that = this;
          var MetaInfo = window.getMetaInfo();
          // console.log(MetaInfo);
          that.newApi.getInitFaceVerify({
            "MetaInfo": JSON.stringify(MetaInfo),
            "realName": that.nameForm.realName,
            "idCardNo": that.nameForm.idCardNo,
            "token": localStorage.getItem("token")
          }).then(res => {
            // if (this.videoCheck()){
            //     window.location.href = res.data.resultObject.certifyUrl
            // }else{
            this.qr = res.data.resultObject.certifyUrl;
            this.qrShow = true;
            // }
            // console.log(res);
          })
        },
        videoCheck() {
          var deviceList = [];
          navigator.mediaDevices.enumerateDevices().then(devices => {
            devices.forEach(device => {
              deviceList.push(device.kind);
            });
            if (deviceList.indexOf("videoinput") == "-1") {
              console.info("没有摄像头");
              return false;
            } else {
              console.info("有摄像头");
              return true;
            }
          })
              .catch(function (err) {
                alert(err.name + ": " + err.message);
              });
        },

        // 保存
        updateOrganInfo() {
          if (!this.organForm.url) {
            this.utils.err('请上传营业执照！');
            return
          }
          var param = {};
          param.idCardNo = this.organForm.idCardNo;
          param.url = this.organForm.url;
          param.MetaInfo = JSON.stringify(window.getMetaInfo());
          param.token = localStorage.getItem("token");
          this.$refs.organForm.validate((valid) => {
            if (valid) {
              this.newApi.agencyCertification(param).then(res => {
                if (res.isSuccess == 1) {
                  this.utils.sus('上传信息成功！');
                  this.qr = res.data.resultObject.certifyUrl;
                  this.first = true;
                  this.qrShow = true
                }

              })
            }
          })
        },

        // 上传
        updateInfo() {
          var that = this;
          that.$refs.resumeForm.validate((valid) => {
            if (valid) {
              that.newApi.userReadNameAuth({
                realName: that.nameForm.realName,
                idCardNo: that.nameForm.idCardNo,
                userType: 1
              }).then(res => {
                if (res.isSuccess === 1) {
                  that.utils.sus(res.data);
                  that.first = true;
                  that.initFaceVerify();
                } else {
                  that.utils.err(res.data)
                }
              })
            }
          })
        },

      }
    };
</script>
<style scoped>
*{
  font-size: 14px;
}
a{
  text-decoration:none;
  color:rgb(166, 166, 166);
}

.bg_shadow{
  width:100%;
  height:100%;
  position:fixed;
  z-index:1213;
  background-color:rgba(0,0,0,.6);
  top:0;left:0;
}
.center_phone {
  background:#fff;
  border-radius:10px;
  width:420px;
  margin:0 auto;
}

::-webkit-input-placeholder {
  color: #999999;;
  font-size: 20px;

}
/deep/  .el-checkbox__inner {
  width: 19px;
  height: 19px;
}
/deep/ .el-checkbox__label {
  font-size: 16px;
  height: 19px;
}


/**
   新css样式
   */
/deep/ .el-button {
  height:28px ;
  font-size: 12px;
}
/deep/ .el-button.is-round {
  padding: 5px 10px;
}
.save-btn {
  width: 76px;
  height: 38px;
  color: #fff;
  background-color: #1C7EFF;
  border-radius: 5px;
}
.info-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.upload-demo {
  vertical-align: middle;
}

.el-icon-plus {
  color: #2970FF;
  font-size: 44px;
  font-weight: 600;
  text-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin: 60px 0 14px 0;
}

/deep/ .el-upload-dragger {
  background-color: #FBFBFB;
  width: 350px !important;
  height: 250px !important;
}

/deep/ .type-input .el-input-group__prepend {
  border-top: none; /* 去除上边框 */
  border-left: none; /* 去除左边框 */
  border-right: none; /* 去除右边框 */
  border-bottom:1px solid #DCDFE6;
  background-color:#FFFFFF;
  padding: 0 11px;
}
/deep/ .type-input .el-input__inner {
  border-top: none; /* 去除上边框 */
  border-left: none; /* 去除左边框 */
  border-right: none; /* 去除右边框 */

}
/deep/ .el-input__inner:focus{
  box-shadow: none; /* 去除选中时的边框阴影 */
  border-color: #c0c4cc; /* 重置边框颜色 */
}

/deep/ .type-input .el-input-group__append {
  border-top: none; /* 去除上边框 */
  border-left: none; /* 去除左边框 */
  border-right: none; /* 去除右边框 */
  border-bottom:1px solid #DCDFE6;
  background-color: #FFFFFF;
  color:#1C7EFF ;
  font-weight: bold;
  cursor: pointer;
}
/deep/ .el-button--primary {
  background-color: #2E57EE;
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background: #FFFFFF;
  color: #1C7EFF;
}
</style>
