<template>
	<div v-show="props.compType === 'simpleImage'">
		<!-- <el-form-item label="字段名">
		  <el-input class="input" v-model="props"></el-input>
		</el-form-item> -->
		<el-form-item label="ID">
			<el-tooltip class="item" effect="dark" content="请注意,ID的修改可能会导致该组件相关事件失效！"
						placement="left">
				<el-input class="input" v-model="props.id"></el-input>
			</el-tooltip>
		</el-form-item>

		<el-form-item label="图片地址">
			<el-input class="input" v-model="props.url"></el-input>
		</el-form-item>

		<el-upload ref="upload" accept="image/*" class="upload-demo" action="" drag :http-request="uploadImg" :limit='1'>
			<i class="el-icon-upload"></i>
			<div>将图片拖到此处，或<em>点击上传</em></div>
		</el-upload>


		<el-form-item label="显示标签">
			<el-switch v-model="props.showLabel" @change="handlerShowLabel"></el-switch>
		</el-form-item>
		<el-form-item label="标签文字" v-show="props.showLabel">
			<el-input class="input" v-model="props.label"></el-input>
		</el-form-item>
		<el-form-item label="标签长度" v-show="props.showLabel">
			<el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
		</el-form-item>
		<el-form-item label="占用列数" v-show="props.span">
			<el-input-number v-model="props.span" :min="1" :max="24"></el-input-number>
		</el-form-item>

		<el-form-item label="图片宽度">
			<el-input-number v-model="props.widthPx" :step="10"></el-input-number>
		</el-form-item>

		<el-form-item label="图片高度">
			<el-input-number v-model="props.heightPx" :step="10"></el-input-number>
		</el-form-item>

		<el-form-item label="图片位置">
			<el-radio-group v-model="props.textAlign">
				<el-radio-button label="left">居左</el-radio-button>
				<el-radio-button label="center">居中</el-radio-button>
				<el-radio-button label="right">局右</el-radio-button>
			</el-radio-group>
		</el-form-item>

		<el-divider>发布后显示的选项</el-divider>
		<el-form-item label="发布后显示">
			<el-switch v-model="props.showInView" @change="handlerShowLabel"></el-switch>
		</el-form-item>


		<!--暂不启用-->
		<!-- <el-form-item label="条码宽度">
		  <el-input-number v-model="props.width"  :min="1" :max="10" :step="1"></el-input-number>
		</el-form-item> -->

	</div>
</template>
<script>
/**
 * input的配置项
 */
export default {
	name: "buttonConfig",
	props: {
		props: {}
	},
	components: {},
	data() {
		return {}
	},
	methods: {
		handlerShowLabel(val) {
			if (val) {
				this.props.labelWidth = 80;
			} else {
				this.props.labelWidth = 0;
			}
		},
		handlerChangeBarCode(val) {
			if (val == null) {
				this.props.lineColor = '#000';
			}
		},
		handlerChangeBackground(val) {
			if (val == null) {
				this.props.background = '#fff';
			}
		},
		uploadImg(params) {
			var that = this;
			var file = params.file;
			const isLt5M = file.size / 1024 / 1024 < 8;
			if (isLt5M) {
				if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
					that.utils.upload(file, function (url) {
						if (!url){
							return false;
						}
						that.props.url = url.url
					})
				} else {
					this.$message.error('只能上传图片类型!')
				}

			} else {
				this.$message.error('上传文件大小不能超过 8MB!')
			}
		}
	},
	mounted() {
	}
}
</script>
