<template>
  <div class="edit_container" :class="{warn_edit_container:warnTextLength}">
        <quill-editor
            v-model="content"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
            >
        </quill-editor>
      <el-upload
              action=""
              v-show="false"
              class="avatar-uploader"
              :show-file-list="false"
              :http-request="onUploadHandler"
      >
      </el-upload>
      <div class="text_number_tips" :class="{warn_text_number_tips:warnTextLength}">{{currentLength}}/{{maxTextLength}}</div>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
    components:{
        quillEditor
    },
    props:{
        value:{
            type:String,
            default:''
        },
        max:{
            type:Number,
            default:0
        },
        validateMaxText:{
            type:Boolean,
            default:false
        },
        id:{
            type:String,
            default:''
        }
    },
    data() {
        return {
            fileUpload: [],
            content: this.value,
            currentLength:0,
            defaultMaxLength:20000,
            editorOption: {
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            ['blockquote', 'code-block'], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            [{ direction: 'rtl' }], // 文本方向
                            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            // [{ font: ['songti'] }], // 字体种类
                            [{ align: [] }], // 对齐方式
                            ['clean'], // 清除文本格式
                            ['image', 'video'] // 链接、图片、视频
                        ],
                        handlers: {
                            'image': function (value) {
                                if (value) { // value === true
                                    document.querySelector('.avatar-uploader input').click();
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    }
                },
                placeholder: '请输入正文',
            }
        }
    },
    methods: {

        onUploadHandler(e) {
            var that = this;
            this.utils.upload(e.file, function (url) {
	            if (!url){
		            return false;
	            }
                let quill = that.$refs.myQuillEditor.quill;
                let length = quill.getSelection().index;
                // 插入图片
                quill.insertEmbed(length, 'image', url);
                // 调整光标到最后
                quill.setSelection(length + 1)
            })
        },

        onEditorBlur(){
        
        }, // 失去焦点事件
        onEditorFocus(){
        }, // 获得焦点事件
        onEditorChange(v){
            this.currentLength = v.text.length-1;
            this.$emit('input',this.content);
        }, // 内容改变事件
    },
    computed: {
        editor() {
            return this.$refs.myQuillEditor.quill;
        },
        maxTextLength(){
            let len = this.defaultMaxLength;
            if(this.max>=1){
                len = this.max;
            }
            return len;
        },
        warnTextLength(){
            const warn =this.validateMaxText&&this.currentLength>this.maxTextLength;
            return warn;
        }
    },
    watch:{
        value(newVal){
            this.content = newVal;
        },
        
    }

}
</script>

<style>
.ql-editor{
    height:400px;
    font-size: 16px;
}
.edit_container .text_number_tips{
    float:right;
    margin-top:-30px;
    padding-right:10px;
}
.warn_edit_container{
    border: solid 1px #F56C6C;
}
.warn_text_number_tips{
    color:#F56C6C;
}
</style>
