import { render, staticRenderFns } from "./proJect.vue?vue&type=template&id=3e27af70&scoped=true"
import script from "./proJect.vue?vue&type=script&lang=js"
export * from "./proJect.vue?vue&type=script&lang=js"
import style0 from "./proJect.vue?vue&type=style&index=0&id=3e27af70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e27af70",
  null
  
)

export default component.exports